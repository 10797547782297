import { ApolloError, useQuery } from '@apollo/client';

import { PRACTICE_EXAM_QUERY } from 'data';
import { Assessments_assessment } from 'types';

interface useGetPracticeExamType {
  practiceExam: Assessments_assessment;
  loading: boolean;
  error?: ApolloError;
}

// TODO: Refactor with GQL CodeGen
const useGetPracticeExam = ({ practiceExamId }: { practiceExamId: string | undefined }): useGetPracticeExamType => {
  const { data, loading, error } = useQuery(PRACTICE_EXAM_QUERY, {
    variables: {
      practiceExamId
    }
  });
  return {
    practiceExam: data?.Assessments_assessment || {},
    loading,
    error
  };
};

export { useGetPracticeExam };

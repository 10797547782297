import { ApolloError, OperationVariables, QueryLazyOptions, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';

import { CUSTOM_LEARNING_PATH_QUERY } from 'data';
import { NEW_PATH_ID } from 'constant';

interface UseGetCustomLearningPath {
  getCustomLearningPath: (options?: QueryLazyOptions<OperationVariables> | undefined) => void;
  data: any;
  loading: boolean;
  error?: ApolloError;
}

// TODO: Refactor with GQL CodeGen
const useGetCustomLearningPath = ({ id }: { id: string | undefined }): UseGetCustomLearningPath => {
  const [getCustomLearningPath, { data, loading, error }] = useLazyQuery(CUSTOM_LEARNING_PATH_QUERY);
  useEffect(() => {
    if (id && id !== NEW_PATH_ID) {
      getCustomLearningPath({
        variables: { id }
      });
    }
  }, [id, getCustomLearningPath]);

  return {
    getCustomLearningPath,
    data: data?.customLearningPath,
    loading,
    error
  };
};

export { useGetCustomLearningPath, CUSTOM_LEARNING_PATH_QUERY };

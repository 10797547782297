import styled from 'styled-components';
import { Flexbox } from '@a-cloud-guru/rainbow-ui';

import { RulerLine } from 'components/common';
import { PathMetadata, PathContent } from 'components/path-panel';

const PathPanel: React.FC = () => {
  return (
    <PanelContainer>
      <PathMetadata />
      <PathDivider />
      <PathContent />
    </PanelContainer>
  );
};

const PanelContainer = styled(Flexbox)`
  flex: 1;
  flex-direction: column;
  max-width: 100%;
`;

const PathDivider = styled(RulerLine)`
  margin-bottom: 20px;
`;

export { PathPanel };

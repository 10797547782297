import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { InfiniteHitsProvided, StateResultsProvided } from 'react-instantsearch-core';
import styled from 'styled-components';
import { useIsComponentIdInPath } from 'hooks';
import { SearchResult } from './SearchResult';
type InfiniteScrollProps = Pick<InfiniteHitsProvided, 'refineNext' | 'hasMore' | 'hits'>;
type StateResultsProps = Pick<StateResultsProvided, 'searchState' | 'searching'>;
type SearchResultsProps = InfiniteScrollProps & StateResultsProps;
const useInfiniteScroll = ({ hasMore, refineNext }: Pick<InfiniteScrollProps, 'refineNext' | 'hasMore'>) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasMore) {
      refineNext();
    }
    // ignore the dependency on `refineNext` because the reference to this
    // function changes too frequently causing the hook to be fired
    // when not expected
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, hasMore]);

  return ref;
};

const SearchResults: React.FC<SearchResultsProps> = ({ hits, searchState, searching, hasMore, refineNext }) => {
  const isIdInPath = useIsComponentIdInPath();
  const sentinelRef = useInfiniteScroll({ hasMore, refineNext });
  const { query } = searchState;

  if (!query || (searching && !hits.length)) {
    return null;
  }

  return (
    <>
      {hits.map((hit, index) => (
        <SearchResult index={index} key={hit.objectID} hit={hit} isInPath={isIdInPath(hit.objectID)} />
      ))}
      {searching && <LoadingContaner>Loading...</LoadingContaner>}
      <div ref={sentinelRef} />
    </>
  );
};

const LoadingContaner = styled.div`
  padding: ${({ theme }) => theme.space.s4};
  font-size: 14px;
  text-align: center;
`;

export { SearchResults };

import { useSplitTreatment } from 'splitio-react';

const useSplit = (splitName: string): boolean | undefined => {
  const treatment = useSplitTreatment(splitName);

  if (treatment === undefined) {
    return;
  }

  return treatment === 'on';
};

export { useSplit };

const padTime = (time: number) => {
  return time < 10 ? '0' + time : time;
};

const pluralise = (count: number, term: string) => {
  return count === 1 ? `${count} ${term}` : `${count} ${term}s`;
};

// HourNonPadded | HourPadded | MinutesPadded | Verbose
type TimeFormat = 'h:mm:ss' | 'hh:mm:ss' | 'mm:ss' | 'v';

const secondsToTime = (inputSeconds: string | number | undefined, format: TimeFormat = 'hh:mm:ss'): string => {
  if (typeof inputSeconds !== 'number' || inputSeconds <= 0) {
    return '00:00';
  }

  const supportedFormats = {
    v: () => {
      // v for verbose
      const hours = Math.floor(inputSeconds / 3600),
        minutes = Math.floor((inputSeconds % 3600) / 60),
        seconds = Math.floor(inputSeconds % 60);

      return [hours >= 1 ? pluralise(hours, 'hour') : null, pluralise(minutes, 'minute'), pluralise(seconds, 'second')]
        .filter(Boolean)
        .join(', ');
    },
    'h:mm:ss': () => {
      // no hour left pad
      const hours = Math.floor(inputSeconds / 3600),
        minutes = Math.floor((inputSeconds % 3600) / 60),
        seconds = Math.floor(inputSeconds % 60);

      const hoursDisplay = hours > 0 ? hours + ':' : '';

      return hoursDisplay + padTime(minutes) + ':' + padTime(seconds);
    },
    'hh:mm:ss': () => {
      const hours = Math.floor(inputSeconds / 3600),
        minutes = Math.floor((inputSeconds % 3600) / 60),
        seconds = Math.floor(inputSeconds % 60);

      const hoursDisplay = hours > 0 ? padTime(hours) + ':' : '';

      return hoursDisplay + padTime(minutes) + ':' + padTime(seconds);
    },
    'mm:ss': () => {
      const minutes = Math.floor(inputSeconds / 60),
        seconds = Math.floor(inputSeconds % 60);

      return padTime(minutes) + ':' + padTime(seconds);
    }
  };

  return supportedFormats[format]();
};

export { secondsToTime };
export type { TimeFormat };

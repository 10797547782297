import styled from 'styled-components';
import { Checkbox, colours } from '@a-cloud-guru/rainbow-ui';

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox {
    width: 14px;
    height: 14px;
  }
  .ant-checkbox span.ant-checkbox-inner {
    width: 100%;
    height: 100%;
  }
  .ant-checkbox span.ant-checkbox-inner::after {
    left: 24.5%;
    width: 4.5px;
    height: 8px;
  }
  .ant-checkbox-indeterminate span.ant-checkbox-inner::after {
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
  }
  .ant-checkbox-checked::after {
    border: 1.28571px solid ${colours.blue};
  }
  padding-right: 10px;
`;

export { StyledCheckbox };

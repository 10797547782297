import { usePathContext } from 'context/PathContext';

const useTotalDuration = (): number => {
  const {
    state: { path, errorPath, loadingPath }
  } = usePathContext();

  if (errorPath || loadingPath) return 0;

  const totalDuration = path?.sections.reduce(
    (total, section) =>
      total +
      section.components.reduce((totalSection, component) => {
        if (component.type === 'course' || component.type === 'clp_course_extract') {
          return totalSection + Number(component.computedDuration);
        }
        return totalSection;
      }, 0),
    0
  );

  return totalDuration;
};

export { useTotalDuration };

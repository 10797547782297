import gql from 'graphql-tag';

const CUSTOM_LEARNING_PATH_CREATE = gql`
  mutation customLearningPathCreate($input: CreateCustomLearningPathInput!) {
    customLearningPathCreate(input: $input) {
      id
      title
      description
      sections {
        components {
          id
          type
          ... on CustomLearningPathACGCourseComponent {
            title
            numberOfLessons
            computedDuration
            artworkUrl
          }
          ... on CustomLearningPathCourseExtractComponent {
            title
          }
        }
      }
      updatedAt
      publishedAt
    }
  }
`;

const CUSTOM_LEARNING_PATH_SAVE = gql`
  mutation customLearningPathSave($id: ID!, $input: CreateCustomLearningPathInput!) {
    customLearningPathSave(id: $id, input: $input) {
      id
      title
      description
      sections {
        components {
          id
          type
          ... on CustomLearningPathACGCourseComponent {
            title
            numberOfLessons
            computedDuration
            artworkUrl
          }
        }
      }
      updatedAt
      publishedAt
    }
  }
`;

const CUSTOM_LEARNING_PATH_PUBLISH = gql`
  mutation customLearningPathPublish($id: ID!) {
    customLearningPathPublish(id: $id) {
      id
      title
      description
      tileUrl
      updatedAt
      publishedAt
    }
  }
`;

const DELETE_CUSTOM_LEARNING_PATH_MUTATION = gql`
  mutation customLearningPathDelete($id: ID!) {
    customLearningPathDelete(id: $id) {
      id
    }
  }
`;

const BATCH_ASSIGN_USERS_TO_PATH = gql`
  mutation organisationBatchAssignUsersToPath($input: BatchAssignLearningPathInput!) {
    organisationBatchAssignUsersToPath(input: $input)
  }
`;

export {
  CUSTOM_LEARNING_PATH_CREATE,
  CUSTOM_LEARNING_PATH_SAVE,
  CUSTOM_LEARNING_PATH_PUBLISH,
  DELETE_CUSTOM_LEARNING_PATH_MUTATION,
  BATCH_ASSIGN_USERS_TO_PATH
};

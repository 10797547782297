import gql from 'graphql-tag';

const LAB_QUERY = gql`
  query PathBuilder_lab($labId: String!) {
    LabKeep_getLab(uid: $labId) {
      id
      name
      contentTags {
        contentId
        tags {
          assignedTags {
            categoryName
            tagNames
          }
        }
      }
      difficulty
      averageCompletionTime
      description
      tasks {
        id
        name
        explanation
        disabled
      }
    }
  }
`;

export { LAB_QUERY };

import styled from 'styled-components';
import { ReactComponent as BannerIconSvg } from './circle-info.svg';
import { useTempBannerContext } from './TemporaryBannerContext';

// This TemporaryBannerCLP should drive traffic
// to the Custom Learning Path functionality
// I'll remove it when the experiment is complete
// - Mr. Sayer

const TemporaryBannerIcon: React.FC = () => {
  const { bannerToggle, setBannerToggle } = useTempBannerContext();
  const toggleTrue = () => setBannerToggle(true);

  if (!bannerToggle) {
    return <BannerIcon onClick={toggleTrue} />;
  } else {
    return null;
  }
};

const BannerIcon = styled(BannerIconSvg)`
  width: 20px;
  height: 20px;
`;

export { TemporaryBannerIcon };

import React, { useCallback } from 'react';
import { colours, message } from '@a-cloud-guru/rainbow-ui';
import remarkGfm from 'remark-gfm';
import { LabComponent, LabKeep_Lab } from 'types';
import { Skeleton, SKILL_LEVEL_ICONS } from 'components/common';
import { PROVIDER_LOOKUP } from '../../constant';
import { ReactComponent as ProviderSvg } from 'static/images/svg-icons/icon-provider.svg';
import { ReactComponent as DurationIcon } from 'static/images/svg-icons/icon-duration.svg';
import { ADD_ITEM_TO_PATH, DELETE_ITEM_FROM_PATH, usePathContext } from 'context/PathContext';
import { trace } from 'services/analytics';
import { useIsComponentIdInPath } from 'hooks';
import {
  MetaDataContainer,
  AddRemoveComponentButton,
  StyledDescription,
  MetaDataList,
  MetaDataListItem,
  SummaryText
} from './DetailsContainer';

const MetadataSkeletons = () => (
  <MetaDataList>
    <Skeleton mr="s3" />
    <Skeleton width="100px" mr="s3" />
    <Skeleton width="80px" mr="s3" />
  </MetaDataList>
);

interface LabMetaDataProps {
  loading: boolean;
  lab: LabKeep_Lab;
}

const LabMetaData: React.FC<LabMetaDataProps> = ({ loading, lab }) => {
  const { id: labId, contentTags, averageCompletionTime, description, difficulty } = lab;
  const {
    state: {
      path: { id: pathId }
    },
    dispatch
  } = usePathContext();

  const labType = 'ACG_HANDS_ON_LAB';
  const { tags = [] } = contentTags || {};
  const tag = tags.find((tag) => tag.assignedTags);
  const providerFromTags = tag
    ? tag.assignedTags.find((tag) => tag.categoryName.toLowerCase() === 'vendor')?.tagNames[0]
    : '';
  const provider = providerFromTags ? PROVIDER_LOOKUP[providerFromTags.toLowerCase()] : null;
  const isComponentIdInPath = useIsComponentIdInPath();
  const isInPath = !!labId && isComponentIdInPath(labId);

  const addToPathHandler = useCallback(() => {
    if (lab && Object.keys(lab).length > 0) {
      const newItem: LabComponent = {
        id: labId,
        type: labType,
        lab
      };

      trace.track('Item added to Path', { pathId, labId, componentType: labType });

      dispatch({
        type: ADD_ITEM_TO_PATH,
        newItem: newItem,
        isSaved: false
      });
    } else {
      message.error('Unable to add item to path');
    }
  }, [pathId, lab, labId, dispatch]);

  const deleteFromPathHandler = useCallback(() => {
    if (lab) {
      trace.track('Item removed from Path', { pathId, labId, componentType: labType });
      dispatch({
        type: DELETE_ITEM_FROM_PATH,
        componentId: labId,
        componentType: labType,
        isSaved: false
      });
    }
  }, [pathId, lab, labId, dispatch]);

  const MetadataTiles = () => (
    <MetaDataList>
      {provider && (
        <MetaDataListItem iconComponent={<ProviderSvg />} description="Provider">
          {provider}
        </MetaDataListItem>
      )}
      {difficulty && (
        <MetaDataListItem iconComponent={SKILL_LEVEL_ICONS[difficulty.toLowerCase()]} description="Skill level">
          <StyledDescription>{difficulty}</StyledDescription>
        </MetaDataListItem>
      )}
      {averageCompletionTime && (
        <MetaDataListItem iconComponent={<DurationIcon />} description="Average completion time">
          {averageCompletionTime}
        </MetaDataListItem>
      )}
    </MetaDataList>
  );
  return (
    <MetaDataContainer>
      {loading ? (
        <Skeleton height="36px" width="140px" bgColor={colours.darkGrey400} />
      ) : lab ? (
        <AddRemoveComponentButton
          componentType="Lab"
          isInPath={isInPath}
          addToPathHandler={addToPathHandler}
          deleteFromPathHandler={deleteFromPathHandler}
        />
      ) : null}
      {loading ? <MetadataSkeletons /> : <MetadataTiles />}
      {loading ? (
        <>
          <Skeleton width="100%" bgColor={colours.darkGrey400} mb="s3" />
          <Skeleton width="30%" bgColor={colours.darkGrey400} mb="s4" />
        </>
      ) : (
        <SummaryText linkTarget="_blank" remarkPlugins={[remarkGfm]}>
          {description}
        </SummaryText>
      )}
    </MetaDataContainer>
  );
};

export { LabMetaData };

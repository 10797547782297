import { makeAuthService } from '@a-cloud-guru/auth0-wrapper';
import urlJoin from 'url-join';
import { getConfig } from 'config';

const { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_CUSTOM_DOMAIN, AUTH0_API_AUDIENCE, ACG_BUSINESS_URL } = getConfig();

export const authService = makeAuthService({
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_CUSTOM_DOMAIN,
  AUTH0_API_AUDIENCE,
  DEFAULT_REDIRECT_URI: urlJoin(ACG_BUSINESS_URL, 'login', 'callback')
});

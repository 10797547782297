import styled from 'styled-components';

const Heading2 = styled.h2`
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  margin: 0;
`;

const Heading3 = styled.h3`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: block;
  margin: 0;
`;

const Heading4 = styled.h4`
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin: 0;
`;

export { Heading2, Heading3, Heading4 };

import { useCallback, useState } from 'react';
import { isNil } from 'lodash';
import { makeLocalStorage } from 'utils/localStorage';
import { LocalStorageConfig } from 'types/common';
interface StateCacheProps {
    token: string;
    organisationId: string;
    userId: string;
}

interface StateCache {
    state: StateCacheProps;
    setStateCache: (state?: StateCacheProps) => void;
}

const VERSION = 'V1';

// this hook is copied from /frontends/learn/src/hooks/use-cached-state.js
const useCachedState = (cacheKey: string, config: LocalStorageConfig): StateCache => {
    const createLocalStorage = useCallback(() => makeLocalStorage(config), [config]);
    const localStorage = createLocalStorage();
    const versionedCacheKey = `${cacheKey}_${VERSION}`;
    const cachedState = localStorage.get(versionedCacheKey);

    const [state, setState] = useState(!isNil(cachedState) ? cachedState : null);
    const setStateCache = (state?: StateCacheProps) => {
        setState(state);
        localStorage.set(versionedCacheKey, state);
    };
    return { state, setStateCache };
};

export { useCachedState };

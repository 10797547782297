import React, { useRef, useEffect } from 'react';
import { Modal } from '@a-cloud-guru/rainbow-ui';
import { ModalFuncProps } from 'antd/lib/modal/Modal';

type ConfirmModalProps = ModalFuncProps & {
  children: React.ReactElement;
  onClick?: () => void;
  loading?: boolean;
  data?: string | Array<unknown> | Record<string, unknown>;
};

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  children,
  onClick,
  loading,
  data,
  title,
  content,
  ...modalProps
}) => {
  const confirmModalRef = useRef<ReturnType<typeof Modal.confirm> | null>(null);

  useEffect(() => {
    if (confirmModalRef.current) {
      confirmModalRef.current.update({
        title,
        content,
        ...modalProps
      });
    }
  }, [title, content, data, loading, modalProps]);

  return React.cloneElement(children, {
    onClick: () => {
      onClick && onClick();

      confirmModalRef.current = Modal.confirm({
        title,
        content,
        ...modalProps
      });
    }
  });
};

export { ConfirmModal };

import React from 'react';
import styled from 'styled-components';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { Skeleton } from 'components/common';
import { Assessments_assessment } from 'types';
import { CallOut } from './CallOut';
import { ReactComponent as DocumentIcon } from 'static/images/svg-icons/documentIcon.svg';
import { ReactComponent as GraphIcon } from 'static/images/svg-icons/graph-icon.svg';

interface PracticeExamCallOutProps {
  practiceExam: Assessments_assessment;
  loading: boolean;
}
const QUESTION_COUNT_MULTIPLIER = 3;
const PracticeExamCallOut: React.FC<PracticeExamCallOutProps> = ({ practiceExam, loading }) => {
  const { numberOfQuestions, totalQuestionsCount } = practiceExam;
  const showQuestionCountCallOut = numberOfQuestions * QUESTION_COUNT_MULTIPLIER < totalQuestionsCount; //logic to show question pool - copied from learn @ /frontends/practice-exam/src/pages/Start/Start.js
  const bodyText =
    !loading && !showQuestionCountCallOut ? (
      <span>
        This exam contains {numberOfQuestions} questions. Each attempt is randomly generated. Learners can retake the
        exam to gain confidence.
      </span>
    ) : (
      'Each attempt is randomly generated. Learners can retake the exam to gain confidence.'
    );
  return (
    <>
      {loading ? (
        <Skeleton width="100%" bgColor={colours.darkGrey400} my="s2" />
      ) : (
        <CallOutBlockWrapper>
          {practiceExam && showQuestionCountCallOut && (
            <CallOut
              title="Question pool"
              bodyText={
                <span>
                  Each attempt pulls {numberOfQuestions} questions from a pool of{' '}
                  <strong>{totalQuestionsCount} unique questions</strong>.
                </span>
              }
              icon={<DocumentIcon />}
            />
          )}
          {practiceExam && (
            <StyledCallOut
              title="Unlimited attempts"
              bodyText={bodyText}
              icon={<GraphIcon />}
              showQuestionCountCallOut={showQuestionCountCallOut}
            />
          )}
        </CallOutBlockWrapper>
      )}
    </>
  );
};

const CallOutBlockWrapper = styled.div`
  display: flex;
`;

const StyledCallOut = styled(CallOut)`
  margin-left: ${(props) => (props.showQuestionCountCallOut ? '20px' : '0px')};
  width: ${(props) => (props.showQuestionCountCallOut ? '50%' : '100%')};
`;

export { PracticeExamCallOut };

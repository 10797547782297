import { getConfig } from 'config';

const { CLOUDINARY_ACCOUNT, CLOUDINARY_BASE_URL } = getConfig();

const attributeMap = {
  width: {
    name: 'w',
    defaultValue: null
  },
  height: {
    name: 'h',
    defaultValue: null
  },
  aspectRatio: {
    name: 'ar',
    defaultValue: null
  },
  quality: {
    name: 'q',
    defaultValue: 'auto'
  },
  focus: {
    name: 'g',
    defaultValue: null
  },
  crop: {
    name: 'c',
    defaultValue: null
  },
  format: {
    name: 'f',
    defaultValue: null
  },
  x: {
    name: 'x',
    defaultValue: null
  },
  y: {
    name: 'y',
    defaultValue: null
  }
};

type KeyOfAttrMap = keyof typeof attributeMap;
type MakeBuildOptimizedSrcProps = Partial<Record<KeyOfAttrMap, string>>;

const isValidKey = (key: string): key is KeyOfAttrMap => {
  return key in attributeMap;
};

const makeBuildOptimizedSrc = (options: MakeBuildOptimizedSrcProps = {}) => (src: string): string => {
  const transforms = Object.keys(attributeMap)
    .reduce((accum: Array<string>, key) => {
      if (isValidKey(key)) {
        const value = options[key] || attributeMap[key].defaultValue;
        if (value) accum.push(`${attributeMap[key].name}_${value}`);
      }

      return accum;
    }, [])
    .join(',');

  if (!src) {
    return src;
  }

  return [`${CLOUDINARY_BASE_URL}/${CLOUDINARY_ACCOUNT}/image/fetch`]
    .concat(transforms)
    .concat(encodeURIComponent(src))
    .join('/');
};

interface ImageOptimizerChildrenProps {
  buildOptimizedSrc: (src: string) => string;
}

interface ImageOptimizerProps extends MakeBuildOptimizedSrcProps {
  children: ({ buildOptimizedSrc }: ImageOptimizerChildrenProps) => React.ReactElement;
}

const ImageOptimizer: React.FC<ImageOptimizerProps> = ({ children, ...options }) => {
  const buildOptimizedSrc = makeBuildOptimizedSrc(options);

  return children({ buildOptimizedSrc });
};

const buildOptimizedSrc = makeBuildOptimizedSrc();

export { ImageOptimizer, buildOptimizedSrc, makeBuildOptimizedSrc };

import styled from 'styled-components';
import { Highlight } from 'react-instantsearch-dom';
import type { RefinementListProvided } from 'react-instantsearch-core';
import { ALGOLIA_LEARN_TYPE_UI_MAP } from 'constant';
import { StyledCheckbox } from 'components/common/StyledCheckbox';
import { useSearchQueryContext } from 'context/SearchQueryContext';

type RefinementListProvidedProps = Pick<RefinementListProvided, 'items' | 'isFromSearch' | 'refine'>;

const RefinementList: React.FC<RefinementListProvidedProps> = ({ items, isFromSearch, refine }) => {
  const { setFilterList, filterList } = useSearchQueryContext();
  const delay = 500;

  items.sort((a, b) => {
    const filterA = ALGOLIA_LEARN_TYPE_UI_MAP[a.label.toUpperCase()];
    const filterB = ALGOLIA_LEARN_TYPE_UI_MAP[b.label.toUpperCase()];
    if (filterA > filterB) {
      return 1;
    }
    if (filterA < filterB) {
      return -1;
    }
    return 0;
  });

  return (
    <FilterList>
      {items.map((item) => (
        <div key={item.label}>
          <StyledCheckbox
            data-testid={`${item.objectID}-checkbox`}
            checked={filterList.findIndex((filter) => filter === item.label) !== -1}
            onClick={(event) => {
              event.preventDefault();

              window.setTimeout(() => refine(item.value), delay);
              if (item.isRefined) {
                setFilterList(filterList.filter((filterItem) => filterItem !== item.label));
              } else {
                setFilterList([...filterList, item.label]);
              }
            }}
          >
            {isFromSearch ? <Highlight attribute="label" hit={item} /> : ALGOLIA_LEARN_TYPE_UI_MAP[item.label]}
          </StyledCheckbox>
        </div>
      ))}
    </FilterList>
  );
};

const FilterList = styled.div`
  display: flex;
  margin: 10px 15px;
`;

export { RefinementList };

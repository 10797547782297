import { useEffect } from 'react';
import { authService } from 'services/auth';
import { Loading } from 'components/common';

const LoginCallbackPage: React.FC = () => {
  useEffect(() => {
    async function redirect() {
      const { state } = await authService.loginCallback();

      // AuthService package we are using type-hints this as `object` which is no longer recommended and doesn't work for dynamic object keys.
      // Therefore, we are forcing the type of this to be a Record<'redirect_to', string>
      const decodedState = authService.decodeState(state) as Record<'redirect_to', string> | undefined;
      let isMaliciousUrl = false;
      if(decodedState?.redirect_to){
        try {
          const urlObj = new URL(decodedState.redirect_to);
          if (urlObj.protocol !== 'http:' && urlObj.protocol !== 'https:') {
            isMaliciousUrl = true;
          }
          if (urlObj.href.trim().match(/(javascript:|data:|script)/g)?.length) {
            isMaliciousUrl = true;
          }
        } catch (e) {
          isMaliciousUrl = true;
        }
      }
      let authRedirectToUrl = '/path-builder';
      if (decodedState?.redirect_to && !isMaliciousUrl) {
        authRedirectToUrl = decodedState.redirect_to;
      }

      window.location.replace(authRedirectToUrl);
    }

    redirect();
  }, []);

  return <Loading />;
};

export { LoginCallbackPage };

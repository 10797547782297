import { useState, useRef, useEffect, FocusEvent, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { Button, Box, Input, Tooltip, Flexbox, colours } from '@a-cloud-guru/rainbow-ui';

import { ReactComponent as PencilAltSvg } from 'static/images/svg-icons/pencil-alt.svg';
import { MAX_PATH_NAME_LENGTH } from 'constant';
import { UPDATE_PATH_TITLE, usePathContext } from 'context/PathContext';

const initialValue = 'Untitled Path';

const focusTextInput = (event: Input | null) => event?.focus();

const Title: React.FC = () => {
  const prevTitleRef = useRef<string | undefined>();
  const {
    state: { path, loadingPath },
    dispatch
  } = usePathContext();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string | undefined>(path?.title);
  useEffect(() => {
    const title = path?.title;
    if (title !== initialValue) {
      setValue(title);
    }
    if (prevTitleRef.current !== title) {
      prevTitleRef.current = title;
    }
  }, [loadingPath, setValue, path]);

  const onBlurHandler = (e: FocusEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>) => {
    const title = e.currentTarget.value;
    setIsEditing(false);

    const hasChanged = prevTitleRef.current !== title;
    const isUserInput = title !== initialValue;

    if (path && title && isUserInput && hasChanged) {
      prevTitleRef.current = title;

      dispatch({
        type: UPDATE_PATH_TITLE,
        title: title,
        isSaved: false
      });
    }
  };

  const titleValue = path.title;
  const tooltipTitle = `${value ? value.length : 0} of ${MAX_PATH_NAME_LENGTH} characters`;

  return isEditing ? (
    <Tooltip trigger={'focus'} title={tooltipTitle} placement="bottomRight">
      <Input
        placeholder={initialValue}
        value={value}
        onChange={(e) => {
          const input = e.target.value;
          setValue(input.substring(0, MAX_PATH_NAME_LENGTH));
        }}
        onBlur={onBlurHandler}
        onPressEnter={onBlurHandler}
        ref={focusTextInput}
        data-cy="pb-path-title-input"
      />
    </Tooltip>
  ) : (
    <TitleContent data-cy="pb-title-edit-buttton" type="link" block onClick={() => setIsEditing(true)}>
      <TitleStatic data-cy="pb-path-title-text">
        <TitleWrapper>
          {loadingPath ? 'Loading...' : titleValue}
          <PencilAltSvg role="img" height="1rem" title="Edit path title" />
        </TitleWrapper>
      </TitleStatic>
    </TitleContent>
  );
};

const TitleWrapper = styled(Box)`
  white-space: break-spaces;
`;

const TitleStatic = styled(Flexbox)`
  word-wrap: anywhere;
  justify-content: flex-start;
  text-align: left;
  font-family: 'GT Walsheim';
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colours.navy700};
  white-space: normal;
`;

const TitleContent = styled(Button)`
  line-height: 28.8px;
  size: 24px;
  font-weight: 700;
  color: ${colours.navy700};

  &&& {
    transition: none;
    border: 0;
    padding: 0;
    color: unset;
    :hover {
      color: unset;
    }
  }
  svg {
    margin-top: 7px;
    flex-shrink: 0;
    margin-left: 5px;
  }
  :hover svg {
    filter: invert(37%) sepia(95%) saturate(602%) hue-rotate(188deg) brightness(106%) contrast(100%);
  }
`;

export { Title };

import { FetchResult, useMutation } from '@apollo/client';

import { CUSTOM_LEARNING_PATH_PUBLISH } from 'data';

// TODO: Refactor with GQL CodeGen
const usePublishPath = (): ((id: string) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>) => {
  const [publishPath] = useMutation(CUSTOM_LEARNING_PATH_PUBLISH);

  return (id: string) =>
    publishPath({
      variables: { id }
    });
};

export { usePublishPath };

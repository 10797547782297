import { colours, Button, breakpoints } from '@a-cloud-guru/rainbow-ui';
import { useEffect } from 'react';
import styled from 'styled-components';
import { trace } from 'services/analytics';
import { ReactComponent as CloseSvg } from './close.svg';
import { ReactComponent as SignImage } from './SignImage.svg';
import { useTempBannerContext } from './TemporaryBannerContext';
import { useProfile } from 'hooks/use-profile';

// This TemporaryBannerCLP should drive traffic
// to the Custom Learning Path functionality
// I'll remove it when the experiment is complete
// - Mr. Sayer

const TemporaryBannerCLP: React.FC = () => {
  const { bannerToggle, setBannerToggle } = useTempBannerContext();
  const toggleFalse = () => setBannerToggle(false);
  const traceClick = () => trace.track('create_page_clp_modal_clicked', {});

  const { userIdentity } = useProfile();

  const userId = userIdentity && userIdentity.id;

  const identify = (window as any)?.pendo?.identify;

  useEffect(() => {
    if (identify && userId && userIdentity) {
      identify({
        visitor: {
          id: userIdentity.userId,
          ...userIdentity
        }
      });
    }
  }, [identify, userId, userIdentity]);

  if (bannerToggle) {
    return (
      <TemporaryBanner>
        <Info>
          <Headline>First time creating a Custom Learning Path?</Headline>
          <SubText>
            Pick and choose courses for your students to study that are tailored to your specific needs, and watch your
            students progress through the path!
          </SubText>
          <BannerButtons>
            <TourButton data-pendo="CLP-create-tour" onClick={traceClick}>
              Give me a quick tour
            </TourButton>
            <Dismiss onClick={toggleFalse}>Dismiss</Dismiss>
          </BannerButtons>
        </Info>
        <Image>
          <StyledImage />
        </Image>
        <CloseContainer onClick={toggleFalse}>
          <CloseSvg />
        </CloseContainer>
      </TemporaryBanner>
    );
  } else {
    return null;
  }
};

// These are temporary styles for the banner experiment
const TemporaryBanner = styled.div`
  width: 100%;
  min-height: 196px;
  background-color: ${colours.white};
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  box-shadow: 0px 4px 4px rgba(231, 236, 247, 0.2);
  border-radius: 4px;
  border: 1px solid #d7e0f1;
`;

const Image = styled.div`
  display: flex;
  align-items: flex-end;

  @media (max-width: ${breakpoints.xl}) {
    display: none;
  }
`;

const StyledImage = styled(SignImage)`
  height: 197px;
  border-radius: 4px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const Headline = styled.h3`
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: ${colours.indigo700};
  margin: 31px 10px 16px 20px;
`;

const SubText = styled.h3`
  font-size: 14px;
  line-height: 19px;
  color: #4a5d85;
  font-weight: 200;
  max-width: 590px;
  margin: 0px 20px 0px 20px;
`;

const BannerButtons = styled.div`
  display: flex;
  margin: 16px 10px 10px 20px;

  @media (max-width: ${breakpoints.xl}) {
    margin: 16px 10px 30px 20px;
  }
`;

const TourButton = styled(Button)`
  &&& {
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${colours.white};
    background: ${colours.blue700};
    border-radius: 3px;
  }
`;

const Dismiss = styled.a`
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.35px;
  color: ${colours.blue800};
  margin-left: 10px;
  margin-bottom: 7px;
`;

const CloseContainer = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  right: 28px;
  top: 28px;
  background: ${colours.white};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoints.xl}) {
    background: none;
  }
`;

// end temporary styles

export { TemporaryBannerCLP };

import styled from 'styled-components';

import { Tag, colours, Flexbox, Box } from '@a-cloud-guru/rainbow-ui';
import { getConfig } from 'config';
import { trace } from 'services/analytics';
import { v4 as uuidv4 } from 'uuid';
import { formatVendorList, secondsToTime } from 'utils';
import { contentTypes, tagDisplayMap } from '../constants';
import { ReactComponent as ExternalLink } from 'static/images/svg-icons/external-link.svg';
import {
  CourseCLPComponent as ComponentType,
  CourseComponent as CourseComponentType,
  CourseComponentContent,
  CourseOverview
} from 'types';
import { COURSE, CLP_COURSE_EXTRACT } from 'constant';
import { useIsComponentIdInPath } from 'hooks';
import { StyledCheckbox } from 'components/common';
import {
  usePathContext,
  ADD_ITEM_TO_PATH,
  ADD_COMPONENT,
  REMOVE_COMPONENT,
  CONVERT_TO_COURSE_EXTRACT,
  CONVERT_TO_COURSE
} from 'context/PathContext';

const { ACG_DOMAIN, ACG_LEARN_URL } = getConfig();

type CourseComponentProps = CourseComponentType & {
  dataCy: string;
  courseOverview: CourseOverview;
  sectionUrl: string;
  selected: boolean;
};

const CourseComponent: React.FC<CourseComponentProps> = ({
  dataCy,
  title,
  courseOverview,
  sectionUrl,
  content,
  selected = false,
  ...component
}) => {
  const {
    state: { selectedItem, path }
  } = usePathContext();
  const isComponentIdInPath = useIsComponentIdInPath();
  const { id: courseId, url: courseUrl } = courseOverview;
  const isCourseIdInPath = courseId ? isComponentIdInPath(courseId) : false;
  const { type, duration = 0, name: quizTitle, quizType } = content || {};
  const { url: componentUrl, id: componentId } = component;
  const { dispatch } = usePathContext();

  const generateLinkUrl = () => {
    const { labId, type } = content || {};

    if (type === 'hands-on-lab') {
      return `${ACG_LEARN_URL}/course/${courseUrl}/learn/${sectionUrl}/${componentUrl}/lab/${labId}`;
    }

    if (type === 'video') {
      return `${ACG_LEARN_URL}/course/${courseUrl}/learn/${sectionUrl}/${componentUrl}/watch`;
    }

    return `${ACG_DOMAIN}/course/${courseUrl}/learn/${sectionUrl}/${componentUrl}/watch`;
  };

  const handleCheck = () => {
    if (!isCourseIdInPath) {
      const courseExtractId = uuidv4();
      const courseType = CLP_COURSE_EXTRACT;
      const newItem: ComponentType = {
        id: courseExtractId,
        type: courseType,
        title: courseOverview.title,
        numberOfLessons: courseOverview.numberOfLessons,
        computedDuration: duration,
        artworkUrl: courseOverview.artworkUrl,
        skillLevels: courseOverview.skillLevels ?? [],
        vendors: [formatVendorList(courseOverview.vendors)],
        courseId,
        extractComponentIds: [componentId]
      };
      trace.track('Item added to Path', { pathId: path.id, courseExtractId, courseId, courseType });
      dispatch({
        type: ADD_ITEM_TO_PATH,
        isSaved: false,
        newItem
      });
      return;
    }
    const action = selected ? REMOVE_COMPONENT : ADD_COMPONENT;
    let newCourseExtractId = null;
    if (action === 'REMOVE_COMPONENT' && selectedItem?.itemType === 'course') {
      newCourseExtractId = uuidv4();
      trace.track('Course converted to course components', {
        pathId: path.id,
        courseExtractId: newCourseExtractId,
        courseId,
        courseType: CLP_COURSE_EXTRACT
      });
      dispatch({
        type: CONVERT_TO_COURSE_EXTRACT,
        courseExtractId: newCourseExtractId,
        courseOverview
      });
    } else if (action === 'REMOVE_COMPONENT' && selectedItem?.itemType === 'clp_course_extract') {
      const currentComponent = path.sections[0].components.find(
        (component) => component.id === selectedItem.itemId
      ) as ComponentType;
      const numberOfCourseExtractComponents = currentComponent?.extractComponentIds?.length;
      if (numberOfCourseExtractComponents === 1) {
        trace.track('Item removed from Path', {
          pathId: path.id,
          courseExtractId: selectedItem.itemId,
          courseId: courseOverview.id,
          courseType: CLP_COURSE_EXTRACT
        });
      }
    } else if (action === 'ADD_COMPONENT' && selectedItem?.itemType === 'clp_course_extract') {
      const currentComponent = path.sections[0].components.find(
        (component) => component.id === selectedItem.itemId
      ) as ComponentType;
      if (
        currentComponent?.extractComponentIds &&
        courseOverview.numberOfLessons === currentComponent?.extractComponentIds.length + 1
      ) {
        trace.track('Course components converted to course', {
          pathId: path.id,
          courseExtractId: selectedItem.itemId,
          courseId,
          courseType: COURSE
        });
        dispatch({
          type: CONVERT_TO_COURSE,
          totalCourseDuration: courseOverview.computedDuration
        });
        return;
      }
    }
    dispatch({
      type: action,
      courseExtractId: newCourseExtractId ?? selectedItem?.itemId ?? '',
      componentId,
      duration: duration ?? 0
    });
  };

  return (
    <Component>
      {content && (
        <InnerContainer>
          <Left>
            <StyledCheckbox
              data-cy={dataCy}
              data-testid={`${content.id}-selected`}
              checked={selected}
              onClick={handleCheck}
            />
            <ComponentTag quizType={quizType} type={type} />
            <a href={generateLinkUrl()} target="_blank" rel="noreferrer">
              <Title>
                <TitleText>{quizTitle || title}</TitleText>
                <ExternalLinkIcon role="img" />
              </Title>
            </a>
          </Left>
          <Right>
            <ComponentDuration duration={duration} type={type} />
          </Right>
        </InnerContainer>
      )}
    </Component>
  );
};

type ComponentTagProps = Pick<CourseComponentContent, 'type' | 'quizType'>;
const ComponentTag: React.FC<ComponentTagProps> = ({ type, quizType }) => {
  const contentType = quizType === contentTypes.PRACTICE_EXAM ? contentTypes.PRACTICE_EXAM : type;

  if (!tagDisplayMap[contentType]) {
    return null;
  }

  return <ContentTag>{tagDisplayMap[contentType]}</ContentTag>;
};

type ComponentDurationProps = Pick<CourseComponentContent, 'type' | 'duration'>;
const ComponentDuration: React.FC<ComponentDurationProps> = ({ type, duration }) => {
  const hasDurationContent = [
    contentTypes.COURSE_VIDEO,
    contentTypes.HANDS_ON_LAB,
    contentTypes.QUIZ,
    contentTypes.EPISODE
  ].includes(type);

  if (!hasDurationContent) {
    return null;
  }

  return <Duration>{secondsToTime(duration, 'h:mm:ss') || '00:00'}</Duration>;
};

const Component = styled(Box)`
  padding: ${({ theme }) => theme.space.s2} ${({ theme }) => theme.space.s2} 9px;
`;

const InnerContainer = styled(Flexbox)`
  align-items: center;
  justify-content: space-between;
`;

const Title = styled(Box)`
  color: ${colours.navy900};
  line-height: 20px;
  font-size: 14px;
  :hover {
    text-decoration: underline;
  }
`;

const TitleText = styled.span`
  margin-right: ${({ theme }) => theme.space.s2};
`;

const Left = styled(Flexbox)`
  align-items: center;
`;

const Right = styled(Flexbox)`
  align-items: center;
  margin-left: ${({ theme }) => theme.space.s2};
`;

const Duration = styled(Box)`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  opacity: ${(props) => (props.disabled ? 0 : 1)};
  color: ${colours.darkGrey400};
`;

const ExternalLinkIcon = styled(ExternalLink)`
  height: 16px;
  width: 16px;
  path {
    fill: ${colours.darkGrey400};
  }
  vertical-align: text-bottom;
`;

const ContentTag = styled(Tag)`
  &&& {
    color: ${colours.darkGrey900};
    background-color: ${colours.lightGrey800};
    font-weight: semiBold;
    text-transform: uppercase;
    pointer-events: none;
    border: none;
  }
`;

export { CourseComponent };

import { ApolloError, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import { Team } from 'types';

const TEAMS_QUERY = gql`
  query TEAMS_teams {
    teams {
      id
      name
    }
  }
`;

// TODO: Fix typings using GQL codegen
interface TeamsQuery {
  data: Array<Team>;
  loading: boolean;
  error?: ApolloError;
}

const useTeamsQuery = (): TeamsQuery => {
  const { data, loading, error } = useQuery(TEAMS_QUERY);

  const teams: Array<Team> = data && data.teams ? data.teams : [];

  return {
    data: teams,
    loading,
    error
  };
};

export { useTeamsQuery, TEAMS_QUERY };

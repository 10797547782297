import { useMemo } from 'react';

import { usePathContext } from 'context/PathContext';
import { CLPComponent } from 'types/learning-path/Component';

const getId = (component: CLPComponent): string => {
  if (component.type === 'clp_course_extract' && component.courseId) {
    return component.courseId;
  }
  return component.id;
};

const useIsComponentIdInPath = (): ((id: string) => boolean) => {
  const {
    state: { path }
  } = usePathContext();

  const pathIds = useMemo(() => {
    const ids = new Set();

    path?.sections.forEach((section) => {
      return section?.components.forEach((component) => {
        const id = getId(component);
        // Exams can have the same id as courses so prefix with EXAM_ (Algolia objectId for exams is EXAM_${id})
        const idParsed = component.type === 'PRACTICE_EXAM' ? 'EXAM_' + id : id;
        ids.add(idParsed);
      });
    });
    return ids;
  }, [path]);

  return (id: string) => pathIds.has(id);
};

export { useIsComponentIdInPath, getId };

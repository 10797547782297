import { Flexbox, colours, Box, breakpoints, Button, Tooltip } from '@a-cloud-guru/rainbow-ui';
import styled from 'styled-components';
import { Heading2 } from 'components/typography';
import ReactMarkdown from 'react-markdown';
import { MetadataTile } from 'components/common';
import { ReactComponent as ExternalLinkSvg } from 'static/images/svg-icons/external-link.svg';

// Reusable components for the details panel

const DetailsContainer = styled(Flexbox)`
  flex-direction: column;
  padding: ${({ theme }) => theme.space.s5} 50px;
`;

const HeadingTitle = styled(Box)`
  color: ${colours.navy900};
  font-family: 'GT Walsheim';
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  margin: ${({ theme }) => theme.space.s5} 0;
`;

const CloseButton = styled(Button)`
  &&& {
    padding: 0;
    border: none;
    display: flex;
  }
`;
const MastHeadContainer = styled(Box)`
  padding: ${({ theme }) => theme.space.s8};
  background: ${({ desktopSrc, backgroundColour }) => `url(${desktopSrc}) center no-repeat, ${backgroundColour}`};
  @media (max-width: ${breakpoints.md}) {
    background: ${({ mobileSrc, backgroundColour }) => `url(${mobileSrc}) center no-repeat, ${backgroundColour}`};
  }
  transition: all 0.1s ease-in-out;

  :hover {
    cursor: pointer;

    ${CloseButton} {
      transform: translateX(10px);
    }
  }
`;

const TitleContainer = styled(Box)`
  display: flex;
  margin: ${({ theme }) => theme.space.s5} 0;
`;

const Title = styled(Heading2)`
  font-family: 'GT Walsheim';
  color: ${colours.white};
  width: 70%;
  margin: auto 0;
`;

const MetaDataContainer = styled(Box)`
  padding-bottom: 6px;
`;

const AddRemoveComponent = styled(Button)`
  display: flex;
  font-weight: 600;
  align-items: center;
`;

type AddRemoveComponentButtonProps = {
  componentType: 'Lab' | 'Exam';
  isInPath: boolean;
  addToPathHandler: () => void;
  deleteFromPathHandler: () => void;
  disabled?: boolean;
  disabledMessage?: string;
};

const AddRemoveComponentButton: React.FC<AddRemoveComponentButtonProps> = ({
  componentType,
  isInPath,
  addToPathHandler,
  deleteFromPathHandler,
  disabled,
  disabledMessage
}) => {
  if (isInPath) {
    return (
      <AddRemoveComponent onClick={deleteFromPathHandler} type="default">
        Remove {componentType}
      </AddRemoveComponent>
    );
  }
  if (disabled) {
    return (
      <Tooltip title={disabledMessage}>
        <span>
          <AddRemoveComponent onClick={addToPathHandler} type="primary" disabled>
            Add {componentType}
          </AddRemoveComponent>
        </span>
      </Tooltip>
    );
  }
  return (
    <AddRemoveComponent onClick={addToPathHandler} type="primary">
      Add {componentType}
    </AddRemoveComponent>
  );
};

const StyledDescription = styled.span`
  text-transform: capitalize;
`;

const MetaDataList = styled(Flexbox)`
  align-items: center;
  color: ${colours.navy900};
  padding: ${({ theme }) => theme.space.s5} 0;
`;

const MetaDataListItem = styled(MetadataTile)`
  color: ${colours.navy900};
`;

const SummaryText = styled(ReactMarkdown)`
  font-size: 14px;
  line-height: 21px;
  color: ${colours.navy900};
`;

const ExternalLinkContainer = styled(Box)`
  display: flex;
  margin: ${({ theme }) => `${theme.space.s5} 0`};
`;

const ExternalLink = styled.a`
  color: ${colours.navy900};
  line-height: 20px;
  font-size: 14px;
  :hover {
    color: inherit;
  }
`;

const ExternalLinkText = styled.span`
  margin-right: ${({ theme }) => theme.space.s1};
  text-decoration: underline;
`;

const ExternalLinkIcon = styled(ExternalLinkSvg)`
  height: 16px;
  width: 16px;
  path {
    fill: ${colours.darkGrey400};
  }
`;
export {
  DetailsContainer,
  HeadingTitle,
  CloseButton,
  MastHeadContainer,
  TitleContainer,
  Title,
  MetaDataContainer,
  AddRemoveComponentButton,
  StyledDescription,
  MetaDataList,
  MetaDataListItem,
  SummaryText,
  ExternalLinkContainer,
  ExternalLink,
  ExternalLinkText,
  ExternalLinkIcon
};

import styled from 'styled-components';
import { SearchBoxProvided } from 'react-instantsearch-core';

import { colours, Box, Flexbox, easing, breakpoints } from '@a-cloud-guru/rainbow-ui';
import { Image } from 'components/common/image/Image';
import { useSearchQueryContext } from 'context/SearchQueryContext';
import { quickSearchItems } from 'data/quickSearchItems';
import { InfiniteScrollProps } from 'types/common';

interface QuickSearchSectionProps {
  onSelected: (queryString: string) => void;
}

const QuickSearchSection: React.FC<QuickSearchSectionProps> = ({ onSelected }) => {
  return (
    <Box>
      <Title>Browse by cloud technology</Title>
      <Container data-cy="pb-cloud-provider-blocks">
        {quickSearchItems.map((item, index) => {
          const { title, primaryImageUrl, secondaryImageUrl, queryText } = item;

          return (
            <Card key={index} tabIndex="0" onClick={() => onSelected(queryText)}>
              <Top>
                <Image src={primaryImageUrl} height="36" alt={title} />
                <TileTitle>{title}</TileTitle>
              </Top>
              <Instructors>
                <ImageContainer width={null}>
                  <Image src={secondaryImageUrl} alt={title + ' instructors'} />
                </ImageContainer>
              </Instructors>
            </Card>
          );
        })}
      </Container>
    </Box>
  );
};

type SearchBoxProps = Pick<SearchBoxProvided, 'refine' | 'currentRefinement'>;
type QuickSearchProps = SearchBoxProps & InfiniteScrollProps;

const QuickSearch: React.FC<QuickSearchProps> = ({ currentRefinement, hits = [], refine }) => {
  const { setSearchQuery } = useSearchQueryContext();

  const onSelected = (queryText: string) => {
    setSearchQuery(queryText);
    refine(queryText);
  };

  return !currentRefinement || hits.length === 0 ? (
    <QuickSearchWrapper>
      <QuickSearchSection onSelected={onSelected} />
    </QuickSearchWrapper>
  ) : null;
};

export { QuickSearch };

const QuickSearchWrapper = styled(Box)`
  margin-top: ${({ theme }) => theme.space.s8};
  max-width: 80em;
`;

const Title = styled(Box)`
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
  margin-bottom: ${({ theme }) => theme.space.s4};
  color: ${colours.navy900};
`;

const TileTitle = styled.h4`
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  text-align: center;
  color: ${colours.navy900};
`;

const Container = styled(Flexbox)`
  margin-top: ${({ theme }) => theme.space.s8};
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.xl}) {
    flex-direction: row;
  }

  &:hover > div {
    opacity: 0.65;
  }

  &:hover > div:hover {
    opacity: 1;
  }
`;

const ImageContainer = styled(Box)`
  background: transparent;
  height: 80px;

  @media screen and (min-width: ${breakpoints.xl}) {
    height: 110px;
  }
`;

const Card = styled(Flexbox)`
  align-items: center;
  transition: 0.15s opacity ${easing.default};
  border: 1px solid #d9d9d9;
  overflow: hidden;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  margin: 2px 0;
  background-color: ${colours.white};

  @media screen and (min-width: ${breakpoints.xl}) {
    flex-direction: column;
    margin: 0 2px;
    div:last-of-type {
      margin-top: auto;
    }
  }
`;

const Top = styled(Flexbox)`
  align-items: center;
  justify-content: space-between;
  margin-left: ${({ theme }) => theme.space.s4};
  width: 50%;

  @media screen and (min-width: ${breakpoints.xl}) {
    flex-direction: column;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    margin-left: 0;
    margin-top: ${({ theme }) => theme.space.s5};
    width: 100%;
  }

  img {
    width: auto;
    object-fit: contain;
  }
`;

const Instructors = styled(Flexbox)`
  height: 68px;
  justify-content: center;

  @media screen and (min-width: ${breakpoints.xl}) {
    height: 100%;
  }

  img {
    height: 100%;
    width: auto;
    max-width: 100%;
    border-radius: 0 5px 5px 0;

    @media screen and (min-width: ${breakpoints.xl}) {
      border-radius: 5px;
      object-fit: none;
      max-width: initial;
      width: 100%;
    }
  }
`;

import { useLazyQuery, gql } from '@apollo/client';
import { useEffect } from 'react';
import { useCachedState } from 'hooks/use-cached-state';
import { useProfile } from 'hooks/use-profile';
// this hook is copied from /frontends/learn/src/components/search/hooks/use-algolia-secured-api-key.js
const GENERATE_SCOPED_TOKEN_QUERY = gql`
  query Learn_GlobalSearch_generateScopedToken {
    GlobalSearch_generateScopedToken {
      token
      validUntil
    }
  }
`;

// 7 days in seconds, minus 2 hours (7200 sec) for wiggle-room between token expiry & refetch
const CACHE_EXPIRY_LENGTH = 60 * 60 * 24 * 7 - 7200;

const useAlgoliaSecuredApiKey = (): string => {
  const {
    userIdentity, organisation, loading
  } = useProfile();

  const userId = userIdentity ? userIdentity.id : null;
  const organisationId = organisation ? organisation.id : null;
  const [getScopedToken, { data, called }] = useLazyQuery(GENERATE_SCOPED_TOKEN_QUERY);
  const { state: securedApiKey, setStateCache: setSecuredApiKey } = useCachedState('AlgoliaSearchUserToken', {
    ttl: CACHE_EXPIRY_LENGTH
  });

  useEffect(() => {
    // Delete old keys:
    localStorage.removeItem('AlgoliaSearchSecuredUserApiKey_V1');
    localStorage.removeItem('algoliaSearch_securedApiKey_V1');
  }, []);

  // If no key is currently cached, call for a new key
  useEffect(() => {
    if (!securedApiKey && !called && !loading && userId) {
      getScopedToken();
    }
  }, [called, getScopedToken, securedApiKey, userId, loading]);

  // If we get a new token, set it to the cache
  useEffect(() => {
    const token = data?.GlobalSearch_generateScopedToken.token;

    if (token && userId && organisationId) {
      setSecuredApiKey({ token, organisationId, userId });
    }
    // eslint-disable-next-line
  }, [data, organisationId, userId]);

  // Ensure token was set by current organisationId (in case of user change on browser)
  useEffect(() => {
    if (!securedApiKey) return;
    if (!userId) return;

    const isSameUserId = securedApiKey.userId === userId;
    const isSameOrg = securedApiKey.organisationId === organisationId;
    const isTokenForAuthUser = isSameUserId && isSameOrg;

    if (!isTokenForAuthUser) {
      setSecuredApiKey(undefined);
    }
  }, [securedApiKey, setSecuredApiKey, organisationId, userId]);

  return securedApiKey?.token;
};

export { useAlgoliaSecuredApiKey };

import React from 'react';
import Bugsnag, { Client as BugSnagClient, NotifiableError } from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react';

import { getConfig } from 'config';

const { BUGSNAG_API_KEY, BUILD_NUMBER, COMMIT_HASH, RELEASE_STAGE } = getConfig();
const APP_VERSION = `build-${BUILD_NUMBER || 0}-commit-${COMMIT_HASH || 0}`;
const REFRESH_TOKEN_ERROR = 'Unable to refresh token';

const IGNORED_ERRORS = new Set([
  'Network error: Failed to fetch',
  'Network request failed',
  'Not enough memory resources are available to complete this operation.', // Algolia IE11 error: https://stackoverflow.com/questions/49876081/ie11-not-enough-storage-is-available-to-complete-this-operation
  '<script> unable to load due to an `error` event on it', // Algolia error: browser is too old
  'Could not parse the incoming response as JSON, see err.more for details', // Algolia error: browser is too old
  "Failed to execute 'requestPictureInPicture' on 'HTMLVideoElement': Metadata for the video element are not loaded yet."
]);

const makeBugsnagService = (apiKey: string, releaseStage: string) => {
  let bugsnagClient: BugSnagClient | undefined;

  if (apiKey) {
    bugsnagClient = Bugsnag.createClient({
      apiKey: BUGSNAG_API_KEY,
      plugins: [new BugsnagPluginReact()],
      releaseStage,
      enabledReleaseStages: ['production', 'staging'],
      appVersion: APP_VERSION,
      onError: (event) => {
        if (typeof window === 'undefined') {
          return false;
        }

        const errMsg = event?.errors[0]?.errorMessage || '';

        if (IGNORED_ERRORS.has(errMsg) || errMsg.startsWith(REFRESH_TOKEN_ERROR)) {
          return false;
        }
      }
    });
  }

  return {
    ErrorBoundary: makeErrorBoundary(bugsnagClient),
    identifyUser: makeIdentifyUser(bugsnagClient),
    leaveBreadcrumb: makeLeaveBreadcrumb(bugsnagClient),
    notifyError: makeNotifyError(bugsnagClient)
  };
};

/** Bugsnag error boundary, captures all exceptions from child components. */
const makeErrorBoundary = (bugsnagClient?: BugSnagClient): React.FC | BugsnagErrorBoundary => {
  if (bugsnagClient) {
    const reactPlugin = bugsnagClient.getPlugin('react');

    if (reactPlugin) {
      return reactPlugin.createErrorBoundary(React);
    }
  }

  const NoBoundary: React.FC = ({ children }) => <div>{children}</div>;

  return NoBoundary;
};

/** Bugsnag identify user, puts userId into context for any exceptions. */
const makeIdentifyUser = (bugsnagClient?: BugSnagClient): ((userId: string) => void) => {
  return (userId) => {
    if (bugsnagClient) {
      bugsnagClient.setUser(userId);
    }
  };
};

/** Bugsnag notify error, notifies service of errors without exceptions. */
const makeNotifyError = (bugsnagClient?: BugSnagClient): ((error: NotifiableError) => void) => {
  return (error) => {
    console.error('BugSnag Notify:', error);
    if (bugsnagClient) {
      bugsnagClient.notify(error);
    }
  };
};

/** Bugsnag leave breadcrumb, log potentially useful events for bug tracking. */
const makeLeaveBreadcrumb = (
  bugsnagClient?: BugSnagClient
): ((event: string, meta: Record<string, unknown>) => void) => {
  return (event, meta = {}) => {
    if (bugsnagClient) {
      bugsnagClient.leaveBreadcrumb(event, meta);
    }
  };
};

export const bugsnagService = makeBugsnagService(BUGSNAG_API_KEY, RELEASE_STAGE);

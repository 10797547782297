import { CourseSection } from './CourseSection';
import { CourseOverview, CourseSection as CourseSectionType, SelectableCourseTypes } from 'types';

interface CourseSectionsProps {
  courseOverview: CourseOverview;
  courseType: SelectableCourseTypes;
  sections: Array<CourseSectionType>;
}

const CourseSections: React.FC<CourseSectionsProps> = ({ courseOverview, courseType, sections }) => {
  return (
    <>
      {sections.map((section) => (
        <CourseSection key={section.id} courseType={courseType} courseOverview={courseOverview} {...section} />
      ))}
    </>
  );
};

export { CourseSections };

import { ApolloError, useQuery } from '@apollo/client';

import { COURSE_OVERVIEW_QUERY } from 'data';

interface UseGetCourseOverview {
  courseOverview: any;
  loading: boolean;
  error?: ApolloError;
}

// TODO: Refactor with GQL CodeGen
const useGetCourseOverview = ({ courseId }: { courseId: string | undefined }): UseGetCourseOverview => {
  const { data, loading, error } = useQuery(COURSE_OVERVIEW_QUERY, {
    variables: {
      courseId
    },
    skip: !courseId
  });

  return {
    courseOverview: data?.courseOverview,
    loading,
    error
  };
};

export { useGetCourseOverview, COURSE_OVERVIEW_QUERY };

import styled from 'styled-components';
import { colours, Box } from '@a-cloud-guru/rainbow-ui';

type SkeletonProps = typeof Box & {
  width?: string;
  height?: string;
  bgColor?: string;
};

const Skeleton: React.FC<SkeletonProps> = ({ width, height, bgColor, ...restProps }) => {
  return <SkeletonBox bgColor={bgColor} height={height} width={width} {...restProps} />;
};

const SkeletonBox = styled(Box)`
  display: block;
  width: ${(props) => props.width || props.theme.space.s12};
  height: ${(props) => props.height || props.theme.space.s4};
  background-color: ${(props) => props.bgColor || colours.darkGrey100};
  border-radius: 3px;
  opacity: 0.5;
`;

export { Skeleton };

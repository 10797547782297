import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Card, Flexbox, colours, Button } from '@a-cloud-guru/rainbow-ui';
import { MetadataTile, SKILL_LEVEL_ICONS, SKILL_LEVEL_MAP } from 'components/common';
import { Heading3 } from 'components/typography';
import { ReactComponent as DurationIcon } from 'static/images/svg-icons/icon-duration.svg';
import { ReactComponent as FilterIcon } from 'static/images/svg-icons/filter.svg';
import { ReactComponent as UnlinkSvg } from 'static/images/svg-icons/unlink.svg';
import { ReactComponent as BinSvg } from 'static/images/svg-icons/bin.svg';
import { ReactComponent as DragHandle } from 'static/images/svg-icons/drag-handle-icon.svg';
import { getDurationDisplay, getCourseExtractLessonDisplay } from 'utils';
import { CourseCLPComponent } from 'types';
import { rgba } from 'polished';

interface CoursePathComponentProps {
  index: number;
  courseComponent: CourseCLPComponent;
  deleteItemFromPath: (componentId: string) => void;
  isDragging: boolean;
  isItemSelected: boolean;
  artworkUrl: string;
  bgColor: string;
  onClick: () => void;
}

const CoursePathComponent: React.FC<CoursePathComponentProps> = ({
  index,
  courseComponent: component,
  deleteItemFromPath,
  isDragging,
  isItemSelected,
  artworkUrl,
  bgColor,
  onClick
}) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const { title, computedDuration, numberOfLessons, skillLevels = [], type } = component;
  const duration = computedDuration !== null ? getDurationDisplay(computedDuration) : null;
  const mapSkillLevel = SKILL_LEVEL_MAP[skillLevels[0]?.toLowerCase()];
  const skillLevel = mapSkillLevel ? mapSkillLevel : skillLevels.length > 0 ? skillLevels[0] : '';
  const skillIcon = skillLevel.toLowerCase();

  const isCourseExtract = type === 'clp_course_extract' && 'extractComponentIds' in component;
  const numberOfSelectedLessons = component?.extractComponentIds?.length;
  const lessonsDisplay = getCourseExtractLessonDisplay(numberOfLessons, numberOfSelectedLessons);

  const handleMouseOver = () => setIsHovering(!isHovering);
  const handleMouseOut = () => setIsHovering(false);
  return (
    <CourseBox
      data-cy={`pb-path-component-${index}`}
      onClick={onClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <ComponentCard
        bodyStyle={{
          padding: '18px',
          background: `${isDragging || isItemSelected || isHovering ? colours.blue100 : ''}`,
          border: `${isDragging || isItemSelected || isHovering ? `1px solid ${colours.blue700}` : ''}`,
          borderRadius: '4px'
        }}
        hoverable
        $isDragging={isDragging}
      >
        <Flexbox mb="14px">
          <DragHandleBox
            data-testid={`drag-handle-${component.id}`}
            isDragging={isDragging}
            isHovering={isHovering}
            isItemSelected={isItemSelected}
          >
            <DragHandleWrapper>
              <DragHandle />
            </DragHandleWrapper>
          </DragHandleBox>
          <Box position="relative">
            <ImageContainer isSolid={false} bgColor={bgColor ?? colours.white} />
            <Artwork src={artworkUrl} alt={title} />
            {isCourseExtract && <UnlinkIcon />}
          </Box>
          <Flexbox ml="10px" justifyContent="space-between" width="80%">
            <Box>
              <PathComponentTitle>{title}</PathComponentTitle>
            </Box>
            <DeleteButton
              data-cy={`pb-path-component-${index}-cta-delete`}
              type="link"
              onClick={(event) => {
                event.stopPropagation();
                deleteItemFromPath(component.id);
              }}
            >
              <BinSvg role="img" title="Remove course from path" />
            </DeleteButton>
          </Flexbox>
        </Flexbox>
        <Flexbox alignItems="center">
          <Flexbox marginRight="auto">
            {skillLevel !== '' && (
              <MetadataTile iconComponent={SKILL_LEVEL_ICONS[skillIcon]} description="Skill level">
                <StyledSkillLevel>{skillLevel}</StyledSkillLevel>
              </MetadataTile>
            )}
          </Flexbox>
          <Flexbox>
            {isCourseExtract && (
              <MetadataTile
                dataCy={`pb-path-component-metadata-lessons-filter-${index}`}
                iconComponent={<FilterIcon />}
                description="Number of lessons"
              >
                {lessonsDisplay}
              </MetadataTile>
            )}
          </Flexbox>
          <Flexbox marginLeft="auto">
            <MetadataTile iconComponent={<DurationIcon />} description="Total duration">
              {duration}
            </MetadataTile>
          </Flexbox>
        </Flexbox>
      </ComponentCard>
    </CourseBox>
  );
};

type ComponentCardProps = {
  $isDragging: boolean;
};

const ComponentCard = styled(Card)<ComponentCardProps>`
  border-radius: 4px;
  box-shadow: ${($isDragging) => ($isDragging ? '0px 7px 15px rgba(7, 1, 82, 0.09)' : 'none')};
`;

interface styledImageContainerProps {
  bgColor: string;
  isSolid: boolean;
}
const ImageContainer = styled.div<styledImageContainerProps>`
  background-color: ${({ bgColor, isSolid }) => (bgColor && isSolid ? bgColor : rgba(bgColor, 0.15))};
  border-radius: 2px;
  width: 89px;
  height: 50px;
`;

const PathComponentTitle = styled(Heading3)`
  color: ${colours.navy900};
  font-weight: 700;
  line-height: 1.2em;
  margin-right: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const CourseBox = styled(Box)`
  margin-bottom: 10px;
`;

const Artwork = styled.img`
  width: 57px;
  height: 57px;
  border-radius: 2px;
  position: absolute;
  left: 0;
  right: 0;
  top: -3px;
  margin-left: auto;
  margin-right: auto;
`;

const UnlinkIcon = styled(UnlinkSvg)`
  position: absolute;
  left: 25%;
  top: 6px;
`;

const DeleteButton = styled(Button)`
  &&& {
    padding: 0;
    border: none;
    display: flex;
    transition: none;
  }
  :hover {
    * > path {
      fill: ${colours.red700};
    }
  }
`;

const StyledSkillLevel = styled.span`
  text-transform: capitalize;
`;

const DragHandleBox = styled(Box)`
  display: ${(props) => (props.isDragging || props.isHovering || props.isItemSelected ? 'block' : 'none')};
  z-index: 1;
  position: absolute;
  width: 30px;
  top: 0;
  bottom: 0;
  left: 0;
  background: rgba(208, 223, 255, 0.5);
  border-left: 1px solid ${colours.blue700};
  border-top: 1px solid ${colours.blue700};
  border-bottom: 1px solid ${colours.blue700};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;
const DragHandleWrapper = styled(Box)`
  margin-left: 10px;
  margin-top: 50px;
`;

export { CoursePathComponent };

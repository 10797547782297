import { createContext, useContext, useState } from 'react';

interface SearchQueryProps {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  filterList: string[];
  setFilterList: React.Dispatch<React.SetStateAction<Array<string>>>;
  isSearching: boolean;
  setIsSearching: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialState = {} as SearchQueryProps;
const SearchQueryContext = createContext<SearchQueryProps>(initialState);

const useSearchQueryContext = (): SearchQueryProps => useContext(SearchQueryContext);

const SearchQueryProvider: React.FC = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filterList, setFilterList] = useState<Array<string>>([]);
  const [isSearching, setIsSearching] = useState(false); //set when searching/filtering is in progress

  return (
    <SearchQueryContext.Provider
      value={{ searchQuery, setSearchQuery, filterList, setFilterList, isSearching, setIsSearching }}
    >
      {children}
    </SearchQueryContext.Provider>
  );
};

export { SearchQueryProvider, useSearchQueryContext };

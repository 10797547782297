import { useEffect } from 'react';
import { colours, message } from '@a-cloud-guru/rainbow-ui';

import { SelectableCourseTypes } from 'types';
import { useGetCourseOverview } from 'hooks';
import { RulerLine } from 'components/common';
import { DetailsContainer } from './DetailsContainer';
import { CourseMastHead } from './CourseMastHead';
import { CourseMetaData } from './CourseMetaData';
import { CourseBreakdown } from './course-breakdown/CourseBreakdown';

const errorMessage = 'Failed to fetch course details. Please try again.';

interface CourseDetailsProps {
  courseId?: string;
  courseType: SelectableCourseTypes;
}
const CourseDetails: React.FC<CourseDetailsProps> = ({ courseId, courseType }) => {
  const { courseOverview, error, loading } = useGetCourseOverview({ courseId });
  const hasError = !loading && (!courseOverview || Object.keys(courseOverview).length === 0 || !courseId || error);

  useEffect(() => {
    if (hasError) {
      message.error(errorMessage);
    }
  }, [hasError]);

  return (
    <>
      <CourseMastHead courseOverview={courseOverview} />
      <DetailsContainer>
        {hasError ? (
          <div>{errorMessage}</div>
        ) : (
          <>
            <CourseMetaData loading={loading} courseOverview={courseOverview} />
            <RulerLine colour={colours.navy200} />
            <CourseBreakdown loading={loading} courseOverview={courseOverview} courseType={courseType} />
          </>
        )}
      </DetailsContainer>
    </>
  );
};

export { CourseDetails };

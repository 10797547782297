import { AlgoliaUser } from 'types/user';

type roles = 'Student' | 'Admin' | 'Team Coordinator' | 'Admin Only';

const getUserRoleDescription = (user: AlgoliaUser): roles => {
  if (user.organisation_team_coordinator) {
    return 'Team Coordinator';
  }

  if (user.organisation_admin && user.organisation_admin_only) {
    return 'Admin Only';
  }

  if (user.organisation_admin && !user.organisation_admin_only) {
    return 'Admin';
  }

  return 'Student';
};

export { getUserRoleDescription };

export default {
  VERSION: process.env.REACT_APP_VERSION,
  ACG_ENV: process.env.REACT_APP_ACG_ENV,
  ALGOLIA_APP_ID: '2AQ0ZGWJ3U',
  CLOUDINARY_ACCOUNT: 'acloud-guru',
  CLOUDINARY_BASE_URL: 'https://res.cloudinary.com',
  BUGSNAG_API_KEY: '5338c7520c0ed10621dbb6ab919a6607',
  RELEASE_STAGE: 'dev',
  SPLIT_IO_API_KEY: 'n4qvnchl7dgkalisiojdta3cdmml511agda5'
};

import { getId } from '../hooks';
import { CustomLearningPath } from 'types';

const isComponentIdInPath = (path: CustomLearningPath, id: string): boolean => {
  const pathIds = path?.sections.reduce((accumulator: string[], section) => {
    const components = section?.components;
    const componentIds = components.map((component) => {
      const id = getId(component);
      // Exams can have the same id as courses so prefix with EXAM_ (Algolia objectId for exams is EXAM_${id})
      return component.type === 'PRACTICE_EXAM' ? 'EXAM_' + id : id;
    });
    return [...accumulator, ...componentIds];
  }, []);
  const pathIdsSet = new Set(pathIds);
  return pathIdsSet.has(id);
};

export { isComponentIdInPath };

import React, { useMemo } from 'react';
import styled from 'styled-components';

import { colours, Box, Flexbox } from '@a-cloud-guru/rainbow-ui';
import { trace } from 'services/analytics';
import { v4 as uuidv4 } from 'uuid';
import { formatVendorList, sectionDuration, secondsToTime } from 'utils';
import { Heading2 } from 'components/typography';
import { RulerLine } from 'components/common';
import {
  CourseCLPComponent,
  CourseComponent as CourseComponentType,
  CourseOverview,
  CourseSection as CourseSectionType,
  SelectableCourseTypes
} from 'types';
import { COURSE, CLP_COURSE_EXTRACT } from 'constant';
import { useIsComponentIdInPath } from 'hooks';
import { StyledCheckbox } from 'components/common';
import {
  ADD_ITEM_TO_PATH,
  ADD_CHAPTER_COMPONENTS,
  CONVERT_TO_COURSE,
  CONVERT_TO_COURSE_EXTRACT,
  REMOVE_CHAPTER_COMPONENTS,
  usePathContext
} from 'context/PathContext';
import { CourseComponent } from './CourseComponent';

type CourseSectionProps = CourseSectionType & {
  courseType: SelectableCourseTypes;
  courseOverview: CourseOverview;
};

const CourseSection: React.FC<CourseSectionProps> = ({
  sequence,
  title,
  components,
  url,
  courseType,
  courseOverview
}) => {
  const isComponentIdInPath = useIsComponentIdInPath();
  const { id: courseId } = courseOverview;
  const isCourseIdInPath = courseId ? isComponentIdInPath(courseId) : false;
  const {
    state: { selectedItem, path },
    dispatch
  } = usePathContext();

  const duration = sectionDuration(components || []);
  const courseComponents = useMemo(
    () => (path.sections.length > 0 ? ((path.sections[0].components as unknown) as CourseCLPComponent[]) : []),
    [path.sections]
  );
  const selectedItemsSet = useMemo(() => {
    const courseIndex = courseComponents.findIndex(
      (component: CourseCLPComponent) => selectedItem?.itemId === component.id
    );

    if (courseComponents && courseIndex >= 0) {
      const currentCourseCLPComponent = courseComponents[courseIndex] as CourseCLPComponent;
      return new Set<string>(currentCourseCLPComponent.extractComponentIds);
    } else {
      return new Set<string>(null);
    }
  }, [courseComponents, selectedItem?.itemId]);
  const isCourse = courseType === 'course';
  const sectionComponent = isCourse
    ? courseComponents.find((component) => component.id === courseId)
    : courseComponents.find((component) => component.courseId === courseId);

  const sectionExtractComponentIdsSet = new Set(sectionComponent?.extractComponentIds ?? []);
  const extractComponentIds = components.map((component) => component.id);
  const isEveryChapterLessonSelected =
    (selectedItem?.isInPath && isCourse) || extractComponentIds.every((id) => sectionExtractComponentIdsSet.has(id));
  const sectionComponentIdsSelected = extractComponentIds.filter((componentId) =>
    sectionExtractComponentIdsSet.has(componentId)
  );
  const isChapterPartiallySelected = !isEveryChapterLessonSelected && sectionComponentIdsSelected.length > 0;
  const componentsIdAndDuration = components.map((component) => ({
    id: component.id,
    duration: component.content && component.content.duration ? component.content.duration : 0
  }));
  const handleCheck = () => {
    if (!isCourseIdInPath) {
      const componentsDuration = components.reduce((totalDuration: number, component: CourseComponentType) => {
        const {
          content: { duration = 0 }
        } = component;
        return totalDuration + duration;
      }, 0);
      const courseExtractId = uuidv4();
      const courseType = CLP_COURSE_EXTRACT;
      const newItem: CourseCLPComponent = {
        id: courseExtractId,
        type: courseType,
        title: courseOverview.title,
        numberOfLessons: courseOverview.numberOfLessons,
        computedDuration: componentsDuration,
        artworkUrl: courseOverview.artworkUrl,
        skillLevels: courseOverview.skillLevels ?? [],
        vendors: [formatVendorList(courseOverview.vendors)],
        courseId,
        extractComponentIds
      };
      trace.track('Item added to Path', { pathId: path.id, courseExtractId, courseId, courseType });
      dispatch({
        type: ADD_ITEM_TO_PATH,
        isSaved: false,
        newItem
      });
      return;
    }
    const action = isEveryChapterLessonSelected ? REMOVE_CHAPTER_COMPONENTS : ADD_CHAPTER_COMPONENTS;
    let newCourseExtractId = null;
    if (action === 'REMOVE_CHAPTER_COMPONENTS' && selectedItem?.itemType === 'course') {
      newCourseExtractId = uuidv4();
      trace.track('Course converted to course components', {
        pathId: path.id,
        courseExtractId: newCourseExtractId,
        courseId,
        courseType: CLP_COURSE_EXTRACT
      });
      dispatch({
        type: CONVERT_TO_COURSE_EXTRACT,
        courseExtractId: newCourseExtractId,
        courseOverview
      });
    } else if (action === 'REMOVE_CHAPTER_COMPONENTS' && selectedItem?.itemType === 'clp_course_extract') {
      const currentComponent = path.sections[0].components.find(
        (component) => component.id === selectedItem.itemId
      ) as CourseCLPComponent;
      const numberOfCourseExtractComponents = currentComponent?.extractComponentIds?.length;
      if (numberOfCourseExtractComponents === components.length) {
        trace.track('Item removed from Path', {
          pathId: path.id,
          courseExtractId: selectedItem.itemId,
          courseId: courseOverview.id,
          courseType: CLP_COURSE_EXTRACT
        });
      }
    } else if (action === 'ADD_CHAPTER_COMPONENTS' && selectedItem?.itemType === 'clp_course_extract') {
      const currentComponent = courseComponents.find((component) => component.id === selectedItem.itemId);
      if (
        currentComponent?.extractComponentIds &&
        courseOverview.numberOfLessons === currentComponent?.extractComponentIds.length + extractComponentIds.length
      ) {
        trace.track('Course components converted to course', {
          pathId: path.id,
          courseExtractId: selectedItem.itemId,
          courseId,
          courseType: COURSE
        });
        dispatch({
          type: CONVERT_TO_COURSE,
          totalCourseDuration: courseOverview.computedDuration
        });
        return;
      }
    }
    dispatch({
      type: action,
      courseExtractId: newCourseExtractId ?? selectedItem?.itemId ?? '',
      extractComponentIds,
      componentsIdAndDuration
    });
  };
  return (
    <Section>
      <Chapter>CHAPTER {sequence + 1}</Chapter>
      <TitleRow>
        <Left>
          <StyledCheckbox
            data-cy={`pb-course-chapter-checkbox-${sequence}`}
            checked={isEveryChapterLessonSelected}
            onClick={handleCheck}
            indeterminate={!isCourse && isChapterPartiallySelected}
          />
          <Title>{title}</Title>
        </Left>
        <Right>
          <SectionDuration>{secondsToTime(duration)}</SectionDuration>
        </Right>
      </TitleRow>
      {components
        ? components.map((component, index) => {
            return (
              <React.Fragment key={component.id}>
                <CourseComponent
                  dataCy={`pb-course-component-checkbox-${sequence}_${index}`}
                  courseOverview={courseOverview}
                  sectionUrl={url}
                  {...component}
                  selected={selectedItem?.isInPath && isCourse ? true : selectedItemsSet.has(component.id)}
                />
                {index !== components.length - 1 ? <RulerLine colour={colours.navy200} /> : null}
              </React.Fragment>
            );
          })
        : null}
    </Section>
  );
};

const Section = styled(Box)`
  margin: 1em 0 1.5em;
  :last-child {
    margin-bottom: 0;
  }
`;

const Chapter = styled(Box)`
  font-size: 13px;
  line-height: 20px;
  color: ${colours.navy400};
  margin-bottom: 1px;
  letter-spacing: 0.05em;
  margin-left: ${({ theme }) => theme.space.s8};
`;

const Title = styled(Heading2)`
  color: ${colours.navy900};
  font-family: 'GT Walsheim';
`;

const TitleRow = styled(Flexbox)`
  align-items: center;
  justify-content: space-between;
  margin: 1px ${({ theme }) => theme.space.s2} ${({ theme }) => theme.space.s2};
`;

const Left = styled(Flexbox)`
  align-items: center;
`;

const Right = styled(Flexbox)`
  align-items: center;
  margin-left: ${({ theme }) => theme.space.s2};
`;

const SectionDuration = styled(Box)`
  color: ${colours.navy900};
  font-size: 20px;
  line-height: 34px;
  font-weight: 600;
`;

export { CourseSection };

import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { get } from 'lodash';
import gql from 'graphql-tag';

const GET_ALGOLIA_API_KEY_AND_ORG_ID = gql`
  query {
    searchApiKey {
      id
      scopedApiKey {
        apiKey
        validUntil
      }
    }
  }
`;

export const useAlgoliaApiKey = (): { scopedKey: string | undefined } => {
  const [execute, { data }] = useLazyQuery(GET_ALGOLIA_API_KEY_AND_ORG_ID);

  useEffect(() => {
    execute();
  }, [execute]);

  return {
    scopedKey: get(data, 'searchApiKey.scopedApiKey.apiKey')
  };
};

import React, { useCallback } from 'react';
import { colours, message } from '@a-cloud-guru/rainbow-ui';
import remarkGfm from 'remark-gfm';
import { ExamComponent, Assessment_PracticeExam, Assessments_assessment } from 'types';
import { Skeleton, SKILL_LEVEL_ICONS, SKILL_LEVEL_MAP } from 'components/common';
import { PROVIDER_LOOKUP } from '../../constant';
import { ReactComponent as ProviderSvg } from 'static/images/svg-icons/icon-provider.svg';
import { ReactComponent as DurationIcon } from 'static/images/svg-icons/icon-duration.svg';
import { ADD_ITEM_TO_PATH, DELETE_ITEM_FROM_PATH, usePathContext } from 'context/PathContext';
import { getDurationDisplay } from 'utils';
import { useIsComponentIdInPath } from 'hooks';
import { trace } from 'services/analytics';
import {
  MetaDataContainer,
  AddRemoveComponentButton,
  StyledDescription,
  MetaDataList,
  MetaDataListItem,
  SummaryText
} from './DetailsContainer';

const MetadataSkeletons = () => (
  <MetaDataList>
    <Skeleton mr="s3" />
    <Skeleton width="100px" mr="s3" />
    <Skeleton width="80px" mr="s3" />
  </MetaDataList>
);

interface PracticeExamMetaDataProps {
  loading: boolean;
  isBadExamId: boolean;
  badExamMessage: string;
  practiceExam: Assessments_assessment;
  cloudProviders: Array<string>;
}

const PracticeExamMetaData: React.FC<PracticeExamMetaDataProps> = ({
  loading,
  isBadExamId,
  badExamMessage,
  practiceExam,
  cloudProviders = []
}) => {
  const { id, title, skillLevel, timeLimitRaw, description } = practiceExam;
  const {
    state: {
      path: { id: pathId }
    },
    dispatch
  } = usePathContext();

  // Fallback to Apprentice similar to content-search service
  // https://github.com/ACloudGuru/school/blob/b97028a1d6a666acca4e438d615d75518d9e5e7e/backend/services/content-search/src/services/practice-exam/mappings/skill-level.ts#L2-L4

  const skillLevelLabel = skillLevel ? SKILL_LEVEL_MAP[skillLevel.toLowerCase()] : 'Apprentice';
  const computedDuration = timeLimitRaw ? getDurationDisplay(timeLimitRaw) : '';
  const provider = cloudProviders.length > 0 ? PROVIDER_LOOKUP[cloudProviders[0].toLowerCase()] : '';
  const cloudProvidersLookup = cloudProviders.map((provider) => PROVIDER_LOOKUP[provider.toLowerCase()]);
  const examType = 'PRACTICE_EXAM';
  const isComponentIdInPath = useIsComponentIdInPath();
  // Exams can have the same id as courses so prefix with EXAM_ (Algolia objectId for exams is EXAM_${id})
  const isInPath = !!id && isComponentIdInPath('EXAM_' + id);

  const addToPathHandler = useCallback(() => {
    const exam: Assessment_PracticeExam = {
      url: id,
      title,
      cloudProviders: cloudProvidersLookup,
      duration: computedDuration,
      skillLevels: [skillLevelLabel]
    };
    if (practiceExam && Object.keys(practiceExam).length > 0) {
      const newItem: ExamComponent = {
        id,
        type: examType,
        exam
      };

      trace.track('Item added to Path', { pathId, examId: id, componentType: examType });

      dispatch({
        type: ADD_ITEM_TO_PATH,
        newItem: newItem,
        isSaved: false
      });
    } else {
      message.error('Unable to add item to path');
    }
  }, [pathId, practiceExam, id, title, cloudProvidersLookup, computedDuration, skillLevelLabel, dispatch]);

  const deleteFromPathHandler = useCallback(() => {
    if (practiceExam) {
      trace.track('Item removed from Path', { pathId, examId: id, componentType: examType });
      dispatch({
        type: DELETE_ITEM_FROM_PATH,
        componentId: id,
        componentType: examType,
        isSaved: false
      });
    }
  }, [pathId, practiceExam, id, dispatch]);

  const MetadataTiles = () => (
    <MetaDataList>
      {provider && (
        <MetaDataListItem iconComponent={<ProviderSvg />} description="Provider">
          {provider}
        </MetaDataListItem>
      )}
      {skillLevelLabel && (
        <MetaDataListItem iconComponent={SKILL_LEVEL_ICONS[skillLevelLabel.toLowerCase()]} description="Skill level">
          <StyledDescription>{skillLevelLabel}</StyledDescription>
        </MetaDataListItem>
      )}
      {computedDuration && (
        <MetaDataListItem iconComponent={<DurationIcon />} description="Time limit">
          {computedDuration}
        </MetaDataListItem>
      )}
    </MetaDataList>
  );

  return (
    <MetaDataContainer>
      {loading ? (
        <Skeleton height="36px" width="140px" bgColor={colours.darkGrey400} />
      ) : practiceExam ? (
        <AddRemoveComponentButton
          componentType="Exam"
          isInPath={isInPath}
          addToPathHandler={addToPathHandler}
          deleteFromPathHandler={deleteFromPathHandler}
          disabled={isBadExamId}
          disabledMessage={badExamMessage}
        />
      ) : null}
      {loading ? <MetadataSkeletons /> : <MetadataTiles />}
      {loading ? (
        <>
          <Skeleton width="100%" bgColor={colours.darkGrey400} mb="s3" />
          <Skeleton width="30%" bgColor={colours.darkGrey400} mb="s4" />
        </>
      ) : (
        <SummaryText linkTarget="_blank" remarkPlugins={[remarkGfm]}>
          {description}
        </SummaryText>
      )}
    </MetaDataContainer>
  );
};

export { PracticeExamMetaData };

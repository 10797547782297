import { CourseComponent } from 'types';

const sectionDuration = (components: Array<CourseComponent>): number => {
  // Gets the duration (in seconds) of all the components in a section/chapter
  return components.reduce((accumulator, current) => {
    const firstVideoSourceDuration = current.content && current.content.duration;
    return accumulator + (firstVideoSourceDuration || 0);
  }, 0);
};

export { sectionDuration };

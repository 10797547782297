import styled from 'styled-components';
import { Spin, Box } from '@a-cloud-guru/rainbow-ui';

/*
Use interfaces to describe the shape of an object
*/
interface InnerComplexProp {
  dimensions: number;
}

export interface ComplexProp {
  width: number;
  height: number;
  isLoading: boolean;
  innerComplexProp: InnerComplexProp;
}

/*
When interfaces can't be used, use Type. For example when defining other types such as intersections, unions or tuples
*/

//intersections
type CombinedComplexProp = ComplexProp & {
  isVisible: boolean;
};

//unions
export declare type Size = 'default' | 'small' | 'large';

//tuples
type StringNumberPair = [string, number];

/**
 * It's a good habit to set prop attributes as readonly since we should never be mutating incoming props!
 */
interface SampleTypescriptComponentProps {
  /**
   * Basic types (aka primitive types): https://www.typescriptlang.org/docs/handbook/basic-types.html
   */
  readonly name: string;
  readonly age: number;
  readonly isActive: boolean;

  /**
   * firstName is optional which is denoted by the (?) but if it is passed in, it must be a string
   */
  readonly firstName?: string;

  /**
   * When the type of an attribute could be different, we can tell the compiler what it could be.
   * This forces us to decide which version of the 3 states we are interacting with when we use `createdAt` in our app and prevent 🐛.
   */
  readonly createdAt: string | number | Date;

  /**
   * You can pass in nested objects which can be explicitly laid out:
   */
  readonly inlineComplexProp: {
    id: string;
    sections: {
      dimensions: number;
    };
  };

  /**
   * Or a better approach is to extract the interface into small interfaces:
   */
  readonly extractedComplexProp: ComplexProp;
  readonly extractedCombinedProp: CombinedComplexProp;

  /**
   * Arrays are similar to objects and also has two flavors:
   */
  userIds: Array<string>; // BLT team has decided to go with this!
  orgIds: string[];

  /**
   * Arrays fall in the bucket of Generics (https://www.typescriptlang.org/docs/handbook/generics.html).
   * It simply means you can type-hint what kind of primitives or objects can be found in the array:
   * users: Array<User | undefined>;
   * mixedArray: Array<string | User | boolean>;
   */

  /**
   * Enums let you control the input values. This also comes in two flavors:
   *
   * 1) Inline:
   * readonly size: 'small' | 'large';
   *
   * 2) Extracted Enum:
   *  readonly size: Size;
   */
  readonly size: 'small' | 'large';
  readonly extractedEnumSize: Size;
}

interface sampleArgsObject {
  arg1: string;
  arg2: string;
  arg3: string;
}
// Destructure component props in arguments
const SampleTypescriptComponent: React.FC<SampleTypescriptComponentProps> = ({ isActive, size }) => {
  //exmaple usage of declaring a tuple type variable
  const tupleTypeExample: StringNumberPair = ['string', 0];

  const sampleObject: sampleArgsObject = { arg1: 'str1', arg2: 'str2', arg3: 'str3' };

  const sampleFunction = (arg1: string, arg2: string) => {
    console.log(`this is a sample function ${arg1} ${arg2}`);
  };

  //When passing in more than 2 arguments to a function, use an object with a defined interface
  const sampleFunction3Args = ({ arg1, arg2, arg3 }: sampleArgsObject) => {
    console.log(`this is a sample function with 3 arguments ${arg1} ${arg2} ${arg3}`);
  };
  return (
    <Container isActive={isActive} className="test">
      <Wrapper onClick={() => sampleFunction('str1', 'str2')}>
        <Spin size={size} />
      </Wrapper>
      <Wrapper onClick={() => sampleFunction3Args(sampleObject)}>
        <Box size={size} />
      </Wrapper>
      <Wrapper>{tupleTypeExample[0]}</Wrapper>
    </Container>
  );
};

export { SampleTypescriptComponent };

/**
 * You can pick attributes of another interface so if the parent type changes, then this cascades down.
 * I.e. if the SampleTypescriptComponent starts taking in isActive as a string instead of a boolean, then
 * we will start passing a strict-type of 'isActive: string' to the styled component as well which reflects how we are using it.
 * If the styled component is used in a way where only a boolean would work - then we get a compile time error! 👌
 */

type ContainerProps = Pick<SampleTypescriptComponentProps, 'isActive'>;

const Container = styled.div<ContainerProps>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.isActive === true ? props.theme.colors.danger : props.theme.colors.primary)};
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

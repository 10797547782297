import { PROVIDER_LOOKUP } from 'constant';
import { CourseVendor } from 'types';

const formatVendorList = (vendors: Array<CourseVendor>): string => {
  return vendors
    .map((vendor) => {
      return PROVIDER_LOOKUP[vendor.name] || vendor.name;
    })
    .join(', ');
};

export { formatVendorList };

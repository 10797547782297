import { useState, useCallback, useEffect } from 'react';
import Vibrant from 'node-vibrant';
import { camelCase } from 'lodash';

//copied from frontends/learn/src/utils//hooks
const usePalette = (image: string): any => {
  const [palette, setPalette] = useState('');

  const getImagePalette = useCallback(async (url) => {
    try {
      const response = await Vibrant.from(url).getPalette();

      const keys = Object.keys(response);
      const addPalette = (acc: any, paletteName: any) => ({
        ...acc,
        [camelCase(paletteName)]: response[paletteName] && response[paletteName]?.getHex()
      });

      const colourPalette = keys.reduce(addPalette, {});
      return colourPalette;
    } catch (e) {
      return {};
    }
  }, []);

  useEffect(() => {
    if (!image) {
      return;
    }
    getImagePalette(image).then((res) => setPalette(res));
  }, [image, getImagePalette]);

  return { getImagePalette: getImagePalette, palette: palette };
};

export { usePalette };

import React from 'react';
import { Box } from '@a-cloud-guru/rainbow-ui';

import { Title } from './Title';
import { Description } from './Description';

const PathMetadata: React.FC = () => {
  return (
    <Box pb="20px">
      <Title />
      <Description data-cy="pb-path-description" />
    </Box>
  );
};

export { PathMetadata };

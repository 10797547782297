import styled from 'styled-components';
import { SearchBoxProvided, StateResultsProvided } from 'react-instantsearch-core';

import { colours, Box, Icon } from '@a-cloud-guru/rainbow-ui';
import { InfiniteScrollProps } from 'types/common';

type SearchBoxProps = Pick<SearchBoxProvided, 'currentRefinement'>;
type StateResultsProps = Pick<StateResultsProvided, 'allSearchResults' | 'searching'>;
type TotalSearchResultsProps = SearchBoxProps & InfiniteScrollProps & StateResultsProps;

const TotalSearchResults: React.FC<TotalSearchResultsProps> = ({
  currentRefinement,
  hits = [],
  allSearchResults,
  searching
}) => {
  const totalHits = allSearchResults?.nbHits;
  return currentRefinement ? (
    <Box data-cy="pb-total-search-results">
      <SearchResultsIndicator>
        {totalHits === 0 && (
          <NoResult>
            <Title>No results found</Title>
            <Text>We could not find any results for &apos;{currentRefinement}&apos;</Text>
          </NoResult>
        )}
        <ResultsWrapper>
          {totalHits > 0 && (
            <Text>
              Showing {<strong>{hits.length}</strong>} of {<strong>{totalHits}</strong>} results
            </Text>
          )}
          {totalHits > 0 && searching && <Icon type="loading" style={{ marginLeft: '10px' }} />}
        </ResultsWrapper>
      </SearchResultsIndicator>
    </Box>
  ) : null;
};

const SearchResultsIndicator = styled(Box)`
  margin-top: ${({ theme }) => theme.space.s6};
`;

const NoResult = styled(Box)`
  padding-bottom: 20px;
  color: ${colours.navy900};
`;

const Title = styled(Box)`
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const Text = styled(Box)`
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
`;

const ResultsWrapper = styled(Box)`
  display: flex;
`;

export { TotalSearchResults };

import gql from 'graphql-tag';

const CUSTOM_LEARNING_PATH_QUERY = gql`
  query PathBuilder_customLearningPath($id: ID!) {
    customLearningPath(id: $id) {
      id
      title
      description
      sections {
        components {
          id
          type
          ... on CustomLearningPathACGCourseComponent {
            title
            numberOfLessons
            computedDuration
            artworkUrl
            vendors
            skillLevels
          }
          ... on CustomLearningPathCourseExtractComponent {
            courseId
            extractComponentIds
            title
            numberOfLessons
            computedDuration
            artworkUrl
            vendors
            skillLevels
          }
          ... on CustomLearningPathLabComponent {
            lab {
              id
              name
              type
              description
              difficulty
              averageCompletionTime
              contentTags {
                contentId
                tags {
                  assignedTags {
                    categoryName
                    tagNames
                  }
                }
              }
            }
          }
          ... on CustomLearningPathExamComponent {
            exam {
              url
              title
              skillLevels
              cloudProviders
              duration
            }
          }
        }
      }
      updatedAt
      publishedAt
      isReadOnly
    }
  }
`;

const NUMBER_OF_ORGANISATION_LEARNING_PATH_USERS = gql`
  query PathBuilder_numberOfOrganisationLearningPathUsers($learningPathId: ID!) {
    numberOfOrganisationLearningPathUsers(learningPathId: $learningPathId)
  }
`;

export { CUSTOM_LEARNING_PATH_QUERY, NUMBER_OF_ORGANISATION_LEARNING_PATH_USERS };

import { useState } from 'react';
import styled from 'styled-components';
import { Box, breakpoints, Button, colours, Flexbox, Tooltip, Menu, Dropdown } from '@a-cloud-guru/rainbow-ui';

import { ReactComponent as ChevronLeft } from 'static/images/svg-icons/chevron-left.svg';
import { ReactComponent as ExternalLink } from 'static/images/svg-icons/external-link.svg';
import { ReactComponent as LogoSvg } from 'static/images/svg/logo-icon-only.svg';
import { ReactComponent as CheckSvg } from 'static/images/svg-icons/check.svg';
import { ReactComponent as Ellipsis } from 'static/images/svg-icons/ellipsis.svg';
import { StyledSVG } from 'components/common';
import { trace } from 'services/analytics';
import { getConfig } from 'config/get-config';
import { usePathContext } from 'context/PathContext';

import { AssignUsersAction as AssignUsersModal } from './assign-users-action';
import { DeleteAction } from './DeleteAction';
import { HeaderAction } from './HeaderAction';
import { PublishModal } from './PublishModal';
import { UpdateModal } from './UpdateModal';
import { SaveButton } from './SaveButton';
import { TemporaryBannerIcon } from 'components/search-panel/TemporaryBanner/TemporaryIcon';
import { useSplit } from 'hooks';

const { ACG_LEARN_URL } = getConfig();

const Header: React.FC = () => {
  const {
    state: { path, isSaved, isNewPath }
  } = usePathContext();
  const pathId = path?.id;
  const pathTitle = path?.title;
  const isPublished = !!path?.publishedAt;
  const [isAssignUsersActionVisible, setIsAssignUsersActionVisible] = useState<boolean>(false);
  const [isPublishModalVisible, setIsPublishModalVisible] = useState<boolean>(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState<boolean>(false);
  const [isPublishTooltipVisible, setIsPublishTooltipVisible] = useState<boolean>(false);
  const showCLPBanner = useSplit('CLP_BANNER');

  const getPublishOrSaveUpdatesAction = () => {
    if (!isPublished) {
      return (
        <Tooltip placement="bottom" title="Save path before publishing" visible={isPublishTooltipVisible}>
          <Box
            onMouseEnter={() => {
              if (!isSaved) setIsPublishTooltipVisible(true);
            }}
            onMouseLeave={() => {
              if (!isSaved) setIsPublishTooltipVisible(false);
            }}
          >
            <HeaderAction
              type="primary"
              onClick={() => setIsPublishModalVisible(!isPublishModalVisible)}
              disabled={!isSaved}
            >
              Publish
            </HeaderAction>
          </Box>
        </Tooltip>
      );
    }
    if (!isSaved) {
      return (
        <SaveUpdatesAction ghost onClick={() => setIsUpdateModalVisible(!isUpdateModalVisible)}>
          Save updates
        </SaveUpdatesAction>
      );
    }
    return (
      <PublishedContainer>
        <StyledCheckSvg svgComponent={<CheckSvg />} width="20px" height="20px" title="Path published check" />
        Published
      </PublishedContainer>
    );
  };

  const secondaryActionsMenu = (
    <Menu>
      <Menu.Item>
        <SecondaryActionsMenuItemAnchor
          onClick={() => trace.track('Preview Path', { pathId })}
          target="_blank"
          href={`${ACG_LEARN_URL}/custom-learning-path/${pathId}`}
          title="Preview path in learn.acloud.guru"
        >
          Preview learner view
          <ExternalLinkSVG svgComponent={<ExternalLink />} height="16px" />
        </SecondaryActionsMenuItemAnchor>
      </Menu.Item>
      {isPublished && (
        <Menu.Item>
          <SecondaryActionsMenuItemAnchor
            onClick={() => setIsAssignUsersActionVisible(true)}
            title="Adding users to path"
          >
            Add users
          </SecondaryActionsMenuItemAnchor>
        </Menu.Item>
      )}
      <Menu.Item>
        <DeleteAction learningPathId={pathId} />
      </Menu.Item>
    </Menu>
  );

  return (
    <HeaderContainer>
      <LeftPanelContainer>
        <LogoContainer>
          <a href="/dashboard">
            <StyledLogoSvg title="Return to business.acloud.guru" />
          </a>
        </LogoContainer>
        {path && (
          <TitleContainer>
            <a href="/learning-paths">
              <ExitBuilder>
                <StyledChevron />
                Exit Path Builder
              </ExitBuilder>
            </a>
          </TitleContainer>
        )}
      </LeftPanelContainer>
      {pathId && (
        <RightPanelContainer>
          {showCLPBanner && <TemporaryBannerIcon />}
          {!isPublished && <SaveButton />}

          {!isNewPath && getPublishOrSaveUpdatesAction()}

          {!isNewPath && (
            <Dropdown overlay={secondaryActionsMenu} trigger={['click']}>
              <SecondaryActionsMenuButton ghost>
                <Ellipsis />
              </SecondaryActionsMenuButton>
            </Dropdown>
          )}
          <PublishModal
            isPublishModalVisible={isPublishModalVisible}
            hidePublishModal={() => setIsPublishModalVisible(false)}
            learningPathId={pathId}
            onPublishAndAddUsers={() => setIsAssignUsersActionVisible(true)}
          />
          <UpdateModal
            isUpdateModalVisible={isUpdateModalVisible}
            hideUpdateModal={() => setIsUpdateModalVisible(false)}
            learningPathId={pathId}
          />
          <AssignUsersModal
            pathId={pathId}
            pathType={'CUSTOM_LEARNING_PATH'}
            pathTitle={pathTitle}
            isAddUserModalVisible={isAssignUsersActionVisible}
            showAddUserModal={() => setIsAssignUsersActionVisible(true)}
            hideAddUserModal={() => setIsAssignUsersActionVisible(false)}
          />
        </RightPanelContainer>
      )}
    </HeaderContainer>
  );
};

const StyledChevron = styled(ChevronLeft)`
  position: relative;
  top: 0.125em;
`;

const LogoContainer = styled(Flexbox)`
  align-items: center;
`;

const ExitBuilder = styled(Box)`
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: ${colours.white};
  :hover {
    opacity: 0.5;
  }
`;

const StyledLogoSvg = styled(LogoSvg)`
  :hover {
    opacity: 0.5;
  }
`;

const LeftPanelContainer = styled(Flexbox)`
  margin-left: 20px;
  padding: 15px 0;
`;

const HeaderContainer = styled(Flexbox)`
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
`;

const ExternalLinkSVG = styled(StyledSVG)`
  margin-left: 4px;
  margin-bottom: 2px;
  vertical-align: middle;
  path:last-child {
    fill: ${colours.darkGrey700};
  }
`;

const StyledCheckSvg = styled(StyledSVG)`
  margin-right: 4px;
`;

const PublishedContainer = styled(Flexbox)`
  color: ${colours.indigo300};
  align-items: center;
`;

const SaveUpdatesAction = styled(Button)`
  &&& {
    border: 1px solid ${colours.darkGrey50};
    color: ${colours.darkGrey50};
  }
  :hover {
    background-color: ${colours.lightGrey500} !important;
  }
`;

const SecondaryActionsMenuButton = styled(Button)`
  &&&& {
    border: 1px solid transparent;
    max-height: 36px;
    padding: 6px;
    :hover {
      border: 1px solid ${colours.darkGrey50};
    }
  }
`;

const SecondaryActionsMenuItemAnchor = styled.a`
  && {
    color: ${colours.navy900};
  }
`;

const RightPanelContainer = styled(Flexbox)`
  margin-right: 20px;
  padding: 15px 0;
  white-space: nowrap;

  > * {
    margin: auto 3px;
  }
`;

const TitleContainer = styled(Flexbox)`
  margin-left: 11px;
  justify-content: center;
  flex-direction: column;

  @media (max-width: ${breakpoints.sm}) {
    display: none;
  }
`;

export { Header };

import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Progress } from 'antd';
import { chunk } from 'lodash';
import styled from 'styled-components';

import { BATCH_ASSIGN_USERS_TO_PATH, NUMBER_OF_ORGANISATION_LEARNING_PATH_USERS } from 'data';
import { AlgoliaUser } from 'types';
import { CrossIcon, CheckmarkIcon } from './ProgressIcons';

interface BatchAddUsersToPathProps {
  usersToAdd: Array<AlgoliaUser>;
  pathId: string;
  pathType: string;
  onUsersAdd?: (addedUsers: Array<AlgoliaUser>) => void;
  children: (props: { inProgress: boolean; addedUsers: Array<AlgoliaUser> }) => React.ReactElement;
}

interface NumberOfOrganisationLearningPath {
  numberOfOrganisationLearningPathUsers: number | null;
}

export const BatchAddUsersToPath: React.FC<BatchAddUsersToPathProps> = ({
  usersToAdd,
  pathId,
  pathType,
  onUsersAdd,
  children
}) => {
  const [addedUsers, setAddedUsers] = useState<Array<AlgoliaUser>>([]);
  const [inProgress, setInProgress] = useState(true);
  const [batchAssignUsersToPath] = useMutation(BATCH_ASSIGN_USERS_TO_PATH);

  useEffect(() => {
    const processBatch = async (batchOfUsers: Array<AlgoliaUser>) => {
      const userIds = batchOfUsers.map((user) => user.user_id);

      try {
        await batchAssignUsersToPath({
          variables: {
            input: {
              userIds,
              learningPathId: pathId,
              type: pathType
            }
          },
          update: (store, { data: { organisationBatchAssignUsersToPath } }) => {
            if (!organisationBatchAssignUsersToPath) {
              // GQL mutation has failed, do not update cache
              return;
            }

            // Get all cached paths that match our target GQL query
            const cache = store.readQuery<NumberOfOrganisationLearningPath>({
              query: NUMBER_OF_ORGANISATION_LEARNING_PATH_USERS,
              variables: { learningPathId: pathId }
            });
            if (!cache) {
              return;
            }
            const { numberOfOrganisationLearningPathUsers: cachedCount } = cache;
            store.writeQuery({
              query: NUMBER_OF_ORGANISATION_LEARNING_PATH_USERS,
              variables: { learningPathId: pathId },
              data: {
                numberOfOrganisationLearningPathUsers: (cachedCount ?? 0) + userIds.length
              }
            });
          }
        });

        setAddedUsers((currentUsers) => [...currentUsers, ...batchOfUsers]);
        if (onUsersAdd) onUsersAdd(batchOfUsers);
      } catch (e) {
        // Silence errors so the bulk process continues:
        console.error(e);
      }
    };

    const startBatchProcess = async () => {
      setInProgress(true);
      // Backend only supports 25 users at a time due to Dynamo batch insert limit:
      const batches = chunk(usersToAdd, 25);

      for (const batchOfUsers of batches) {
        if (batchOfUsers && batchOfUsers.length >= 1) {
          await processBatch(batchOfUsers);
        }
      }

      setInProgress(false);
    };

    startBatchProcess();
  }, [batchAssignUsersToPath, setAddedUsers, usersToAdd, pathId, pathType, onUsersAdd]);

  const progress = (addedUsers.length / usersToAdd.length) * 100;
  const failedUsers = usersToAdd.length - addedUsers.length;
  const failedPluralOrSingularLabel = failedUsers === 1 ? 'user' : 'users';
  const completedWithErrors = !inProgress && failedUsers > 0;
  const showProgressInfo = inProgress || addedUsers.length > 0;

  return (
    <ProgressContainer>
      <Progress
        type="circle"
        percent={progress}
        format={(percent) => {
          if (completedWithErrors) {
            return <CrossIcon />;
          }

          return percent === 100 ? <CheckmarkIcon /> : '';
        }}
        strokeColor={completedWithErrors ? '#D64B3F' : '#02E088'}
        status={completedWithErrors ? 'exception' : 'normal'}
      />
      {showProgressInfo && (
        <InfoTag>
          {usersToAdd.length === 1
            ? `${addedUsers.length} added to path`
            : `${addedUsers.length} of ${usersToAdd.length} added to path`}
        </InfoTag>
      )}
      {completedWithErrors && (
        <FailedTag>
          Failed to add {failedUsers} {failedPluralOrSingularLabel}. Retry below.
        </FailedTag>
      )}
      <Footer>{children({ inProgress, addedUsers })}</Footer>
    </ProgressContainer>
  );
};

const ProgressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;

  p {
    margin: 18px;
    font-size: 16px;
  }

  .anticon-close path {
    fill: #d64b3f;
  }
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  border-top: 1px solid #e2e2e2;
  padding-top: 18px;
`;

const InfoTag = styled.span`
  margin: 16px 0;
  font-size: 18px;
  display: block;
`;

const FailedTag = styled.span`
  background-color: #d64b3f;
  border-radius: 3px;
  color: #fff;
  display: block;
  font-size: 16px;
  margin: 16px 0;
  padding: 3px 6px;
`;

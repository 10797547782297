import gql from 'graphql-tag';

const COURSE_FRAGMENT = gql`
  fragment CourseFragment on CourseOverview {
    id
    title
    url
    shortSummary
    computedDuration
    skillLevels
    numberOfLessons
    vendors {
      name
    }
    backgroundPosterUrl
    artworkUrl
  }
`;

const SECTION_FRAGMENT = gql`
  fragment SectionFragment on Section {
    id
    title
    sequence
    url
  }
`;

const COMPONENT_FRAGMENT = gql`
  fragment ComponentFragment on Component {
    id
    title
    url
    content {
      id
      type
      ... on VideoCourseComponentContent {
        duration
      }
      ... on QuizCourseComponentContent {
        name
        quizType
        duration
      }
      ... on HandsOnLabComponentContent {
        labId
        duration
      }
    }
  }
`;

const fragments = {
  course: COURSE_FRAGMENT,
  section: SECTION_FRAGMENT,
  component: COMPONENT_FRAGMENT
};

const COURSE_OVERVIEW_QUERY = gql`
  query Course_courseOverview($courseId: String!) {
    courseOverview(courseId: $courseId) {
      ...CourseFragment
      sections {
        ...SectionFragment

        components {
          ...ComponentFragment
        }
      }
    }
  }
  ${fragments.course}
  ${fragments.section}
  ${fragments.component}
`;

export { COURSE_OVERVIEW_QUERY };

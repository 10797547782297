import round from 'lodash/round';

const secondsToHours = (seconds = 0, precision = 1): string => {
  const hours = round(seconds / 3600, precision);
  return `${hours} hour${hours === 1 ? '' : 's'}`;
};

const secondsToMinutes = (seconds = 0, precision = 1): string => {
  const minutes = round(seconds / 60, precision);
  return `${minutes} min${minutes === 1 ? '' : 's'}`;
};

const getDurationDisplay = (seconds: number): string => {
  if (seconds >= 3597) {
    return secondsToHours(seconds);
  }
  return secondsToMinutes(seconds);
};

export { secondsToHours, secondsToMinutes, getDurationDisplay };

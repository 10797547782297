import { cloneElement } from 'react';
import styled from 'styled-components';

const StyledSVG = styled(({ svgComponent, ...props }) => cloneElement(svgComponent, props))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  ${(props) => props.styles};
`;

export { StyledSVG };

import { useState } from 'react';
import { ImageOptimizer } from './ImageOptimizer';

interface ImageProps {
  src: string;
  width?: string;
  height?: string;
  alt?: string;
}
export const Image: React.FC<ImageProps> = ({ src, width, height, alt, ...rest }) => {
  const [useOriginal, setUseOriginal] = useState(false);

  const onImageError = () => {
    setUseOriginal(true);
  };

  const makeImageUrl = (originalUrl: string, optimisedUrl?: string) => {
    if (useOriginal || !optimisedUrl) {
      return originalUrl;
    }
    return optimisedUrl;
  };

  return (
    <ImageOptimizer width={width} height={height}>
      {({ buildOptimizedSrc }) => (
        <img src={makeImageUrl(src, buildOptimizedSrc(src))} alt={alt || ''} onError={onImageError} {...rest} />
      )}
    </ImageOptimizer>
  );
};

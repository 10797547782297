import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { getConfig } from 'config';
import { authService } from '../auth';

const { GRAPHQL_API_ENDPOINT, ACG_ENV, VERSION } = getConfig();

const makeHeaders = (token: string) => ({
  headers: {
    authorization: `Bearer ${token}`
  }
});

const authLink = setContext(async () => {
  const token = await authService.getToken();
  return makeHeaders(token);
});

interface ICreateApolloClientService {
  get: () => ApolloClient<NormalizedCacheObject> | undefined;
  init: () => ApolloClient<NormalizedCacheObject>;
}

export const createApolloClientService = (): ICreateApolloClientService => {
  let client: ApolloClient<NormalizedCacheObject> | undefined;

  return {
    get: () => client,
    init: () => {
      client = new ApolloClient({
        link: ApolloLink.from([
          authLink,
          new HttpLink({
            credentials: 'same-origin',
            uri: GRAPHQL_API_ENDPOINT
          })
        ]),
        cache: new InMemoryCache(),
        name: `path-builder-${ACG_ENV}`,
        version: VERSION,
        connectToDevTools: process.env.NODE_ENV !== 'production'
      });
      return client;
    }
  };
};

export default createApolloClientService();

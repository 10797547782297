import styled from 'styled-components';
import { Tooltip } from 'antd';
import { Column } from '@a-cloud-guru/react-algolia-table';
import find from 'lodash/find';

import { Image } from 'components/common';
import { AlgoliaUser, Team } from 'types';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { getUserRoleDescription } from './userDataHelpers';

interface GenerateColumnsProps {
  teams: Array<Team>;
  isSelected: (user: AlgoliaUser) => boolean;
}

const generateColumns = ({ teams, isSelected }: GenerateColumnsProps): Array<Column<AlgoliaUser>> => {
  const NameColumn: Column<AlgoliaUser, 'name'> = {
    algoliaAttributeName: 'name',
    displayName: 'User',
    columnWidth: '33%',
    render: (name, user) => (
      <User>
        <Content>
          <ImageContainer>
            <StyledImage width="34" height="34" src={user.picture} />
          </ImageContainer>
          <Tooltip mouseEnterDelay={0.25} title={user.email}>
            <span>{name}</span>
          </Tooltip>
        </Content>
      </User>
    )
  };

  const TeamColumn: Column<AlgoliaUser, 'team'> = {
    algoliaAttributeName: 'team',
    displayName: 'Team',
    columnWidth: '33%',
    render: (teamId) => {
      const team = find(teams, ({ id }) => id === teamId);
      return <span>{team ? team.name : ''}</span>;
    }
  };

  const RoleColumn: Column<AlgoliaUser, 'role'> = {
    algoliaAttributeName: 'role',
    displayName: 'Role',
    columnWidth: '33%',
    render: (_, user) => {
      return (
        <RoleContainer>
          <span>{getUserRoleDescription(user)}</span> {isSelected(user) ? <Tag>IN PATH</Tag> : null}
        </RoleContainer>
      );
    }
  };

  return [NameColumn, TeamColumn, RoleColumn];
};

const User = styled.div`
  font-weight: 600;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const RoleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ImageContainer = styled.div`
  width: 34px;
  height: 34px;
  margin-right: 10px;
`;

const StyledImage = styled(Image)`
  border-radius: 50%;
`;

const Tag = styled.div`
  display: flex;
  padding: 0px 4px;
  height: 1em;
  padding: 10px 5px;
  margin-top: 5px;
  margin-right: 5px;
  color: ${colours.blue600};
  font-weight: bold;
  font-size: 11px;
  align-items: center;
  white-space: nowrap;
  border: 1px solid ${colours.blue600};
  background: white;
  box-sizing: border-box;
  border-radius: 6px;
  opacity: 1;
`;

export { generateColumns };

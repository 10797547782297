import { CourseCLPComponent, Section, SectionLite } from 'types';

const stripSectionsForUpdate = (sections: Array<Section>): Array<SectionLite> => {
  return sections.length !== 0
    ? sections.map((section) => {
        const courseComponents = (section.components as unknown) as CourseCLPComponent[];
        return {
          components: courseComponents.map((component) => ({
            id: component.id,
            type: component.type,
            ...(component?.courseId && { courseId: component.courseId }),
            ...(component?.extractComponentIds && { extractComponentIds: component.extractComponentIds })
          }))
        };
      })
    : [{ components: [] }];
};
export { stripSectionsForUpdate };

import { useEffect, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Box, Flexbox, colours, message as messageWarning } from '@a-cloud-guru/rainbow-ui';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import {
  DELETE_ITEM_FROM_PATH,
  POST_ADD_ITEM_TO_PATH,
  SET_PATH_IS_SAVED,
  REORDER_ITEMS,
  usePathContext,
  SET_SELECTED_ITEM
} from 'context/PathContext';
import { ScrollableArea } from 'components/common';
import { makeBuildOptimizedSrc } from 'components/common/image';
import { CustomLearningPath, CLPComponent, ComponentType } from 'types';
import EmptyComponentTransparent from 'static/images/path-content-empty-component-transparent.png';
import { CoursePathComponent } from './CoursePathComponent';
import { LabPathComponent } from './LabPathComponent';
import { ExamPathComponent } from './ExamPathComponent';
import { usePalette } from 'hooks';
import { ARTWORK_SOURCE_WIDTH } from 'constant';
import { get } from 'lodash';
import { trace } from 'services/analytics';

const useSections = () => {
  const {
    state: { path, errorPath, loadingPath, isReadOnly }
  } = usePathContext();
  const { sections } = path || {};

  const messageError = errorPath ? 'Error loading path. Please try again' : undefined;

  useEffect(() => {
    if (messageError) {
      messageWarning.error(messageError);
    }
  }, [messageError]);

  return { message: messageError, sections, loadingPath, isReadOnly };
};

const EmptyState = ({ message = 'Add courses to get started' }) => (
  <EmptyStateMessage justifyContent="center" alignItems="center">
    {message}
  </EmptyStateMessage>
);

const LoadingState = () => (
  <Flexbox alignItems="flex-start" flexDirection="column">
    {[...new Array(2)].map((_, index) => (
      <EmptyComponentContainer key={index} width={1} bg={'#FEFEFE'} mb="s4">
        <img src={EmptyComponentTransparent} alt="Course Placeholder" />
      </EmptyComponentContainer>
    ))}
  </Flexbox>
);

interface DraggablePathComponentProps {
  component: CLPComponent;
  index: number;
  deleteItemFromPath: (componentId: string) => void;
}

const DraggablePathComponent: React.FC<DraggablePathComponentProps> = ({ component, index, deleteItemFromPath }) => {
  const {
    state: { selectedItem },
    dispatch
  } = usePathContext();

  const componentId = component.type === 'PRACTICE_EXAM' ? 'EXAM_' + component.id : component.id;
  const isItemSelected = selectedItem?.itemId === componentId;
  const artworkUrl = component.type === 'course' || component.type === 'clp_course_extract' ? component.artworkUrl : '';
  const optimisedArtworkUrl = makeBuildOptimizedSrc({ width: ARTWORK_SOURCE_WIDTH, format: 'auto' })(artworkUrl);
  const { palette } = usePalette(optimisedArtworkUrl);
  const bgColor = get(palette, 'lightVibrant', colours.white);
  return (
    <Draggable draggableId={componentId} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          {(component.type == 'course' || component.type == 'clp_course_extract') && (
            <CoursePathComponent
              index={index}
              courseComponent={component}
              artworkUrl={optimisedArtworkUrl}
              bgColor={bgColor}
              deleteItemFromPath={() => deleteItemFromPath(componentId)}
              isDragging={snapshot.isDragging}
              isItemSelected={isItemSelected}
              onClick={() =>
                dispatch({
                  type: SET_SELECTED_ITEM,
                  newItem: {
                    itemId: componentId,
                    courseId:
                      component.courseId && component.type === 'clp_course_extract' ? component.courseId : componentId,
                    title: component.title,
                    itemType: component.type,
                    isInPath: true
                  }
                })
              }
            />
          )}
          {component.type === 'ACG_HANDS_ON_LAB' && component.lab && (
            <LabPathComponent
              index={index}
              labComponent={component}
              deleteItemFromPath={() => deleteItemFromPath(componentId)}
              isDragging={snapshot.isDragging}
              isItemSelected={isItemSelected}
              onClick={() =>
                dispatch({
                  type: SET_SELECTED_ITEM,
                  newItem: {
                    itemId: componentId,
                    title: component.lab.name,
                    itemType: component.type,
                    isInPath: true
                  }
                })
              }
            />
          )}
          {component.type === 'PRACTICE_EXAM' && component.exam && (
            <ExamPathComponent
              index={index}
              examComponent={component}
              deleteItemFromPath={() => deleteItemFromPath(componentId)}
              isDragging={snapshot.isDragging}
              isItemSelected={isItemSelected}
              onClick={() =>
                dispatch({
                  type: SET_SELECTED_ITEM,
                  newItem: {
                    itemId: componentId,
                    title: component.exam.title,
                    itemType: component.type,
                    cloudProviders: component.exam.cloudProviders,
                    isInPath: true
                  }
                })
              }
            />
          )}
        </div>
      )}
    </Draggable>
  );
};
type Sections = CustomLearningPath['sections'];
interface PathComponentsListProps {
  sections: Sections;
}
const PathComponentsList: React.FC<PathComponentsListProps> = ({ sections }) => {
  const {
    state: {
      path: { id: pathId }
    },
    dispatch
  } = usePathContext();
  const deleteItemFromPath = (componentId: string, componentType: ComponentType) => {
    trace.track('Item removed from Path', { pathId, componentId, componentType });

    dispatch({
      type: DELETE_ITEM_FROM_PATH,
      componentId,
      componentType,
      isSaved: false
    });
  };
  return (
    <>
      {sections.map(({ components }) =>
        components.map((component, index) => {
          const componentId = component.type === 'PRACTICE_EXAM' ? 'EXAM_' + component.id : component.id;
          return (
            <DraggablePathComponent
              data-cy={`pb-path-component-${index}`}
              key={`${componentId}-${index}`}
              component={component}
              index={index}
              deleteItemFromPath={() => deleteItemFromPath(componentId, component.type)}
            />
          );
        })
      )}
    </>
  );
};

interface RenderPathBodyProps {
  loadingPath: boolean;
  components: Array<CLPComponent>;
  message?: string;
  sections: Sections;
}
const renderPathBody: React.FC<RenderPathBodyProps> = ({ loadingPath, components, message, sections }) => {
  if (loadingPath) return <LoadingState />;
  if (isEmpty(components)) return <EmptyState message={message} />;
  return <PathComponentsList sections={sections} />;
};

const PathContent: React.FC = () => {
  const {
    state: { isScrollingPathContentToEnd }
  } = usePathContext();

  const { sections, message, loadingPath, isReadOnly } = useSections();
  const components = sections?.[0]?.components;
  const { dispatch } = usePathContext();
  const scrollableAreaEnd = useRef<typeof Box>();

  useEffect(() => {
    if (isScrollingPathContentToEnd) {
      setTimeout(() => {
        scrollableAreaEnd?.current?.scrollIntoView();
        dispatch({
          type: POST_ADD_ITEM_TO_PATH
        });
      });
    }
  }, [isScrollingPathContentToEnd, dispatch]);

  const handleDragging = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination || destination.index === source.index) return;

    dispatch({
      type: SET_PATH_IS_SAVED,
      isSaved: false
    });

    dispatch({
      type: REORDER_ITEMS,
      startIndex: source.index,
      endIndex: destination.index
    });
  };

  if (isReadOnly) {
    return null;
  }

  return (
    <SmoothScrollableArea data-cy="pb-path-panel-scrollable-area">
      <DragDropContext onDragEnd={handleDragging}>
        <Droppable droppableId="path-content">
          {(provided, snapshot) => (
            <DroppableArea
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {renderPathBody({ loadingPath, components, message, sections })}
              {provided.placeholder}
            </DroppableArea>
          )}
        </Droppable>
      </DragDropContext>
      <Box ref={scrollableAreaEnd} />
    </SmoothScrollableArea>
  );
};

const SmoothScrollableArea = styled(ScrollableArea)`
  scroll-behavior: smooth;
`;

interface DroppableAreaProps {
  isDraggingOver: boolean;
}
const DroppableArea = styled.div<DroppableAreaProps>`
  background: ${({ isDraggingOver }) => (isDraggingOver ? '#E8EFFF' : 'none')};
  border-radius: 4px;
`;

const EmptyStateMessage = styled(Flexbox)`
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.space.s2};
`;

const EmptyComponentContainer = styled(Box)`
  margin-bottom: ${({ theme }) => theme.space.s2};
  width: 100%;
  border: 1px solid #d7e0f1;
  border-radius: 4px;
`;

export { PathContent };

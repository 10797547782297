import isFunction from 'lodash/isFunction';
import styled from 'styled-components';
import { Button } from '@a-cloud-guru/rainbow-ui';
import { BaseButtonProps } from 'antd/lib/button/button';

type HeaderActionProps = BaseButtonProps & {
  disabled?: boolean;
  type?: string;
  onClick?: () => void;
};

const HeaderAction: React.FC<HeaderActionProps> = ({ children, disabled = false, ...props }) => {
  return (
    <HeaderActionButton {...props} disabled={disabled}>
      {isFunction(children) ? children(disabled) : children}
    </HeaderActionButton>
  );
};

const HeaderActionButton = styled(Button)`
  display: flex;
  margin: auto 8px;
  font-weight: 500;
  min-width: 120px;
  align-items: center;
  justify-content: center;
`;

export { HeaderAction };

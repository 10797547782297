import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

const getAccessPermissionsGetUserPermissionsQuery = (feature: string): DocumentNode => gql`
  query PathBuilder_AccessPermissionsGetUserPermissions {
    AccessPermissions_getUserPermissions {
      cloud {
        ${feature}
      }
    }
  }
`;

export { getAccessPermissionsGetUserPermissionsQuery };

import { colours } from '@a-cloud-guru/rainbow-ui';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: ${colours.lightGrey300};
  }
`;

export { GlobalStyles };

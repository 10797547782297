const getCourseExtractLessonDisplay = (
  numberOfLessons: number | null,
  numberOfSelectedLessons: number | undefined
): string => {
  const noLessonsFoundText = '-';
  const selectedLessonsText = `${numberOfSelectedLessons ?? 0} of ${numberOfLessons}`;
  return `${numberOfLessons ? selectedLessonsText : noLessonsFoundText} lessons`;
};

const getCourseLessonDisplay = (numberOfLessons: number | null): string => {
  const noLessonsFoundText = '-';
  const selectedLessonsText = `${numberOfLessons} lessons`;
  return `${numberOfLessons ? selectedLessonsText : noLessonsFoundText}`;
};

export { getCourseExtractLessonDisplay, getCourseLessonDisplay };

import styled from 'styled-components';
import get from 'lodash/get';
import { Box } from '@a-cloud-guru/rainbow-ui';

import { SelectableCourseTypes } from 'types';
import { CourseSections } from './CourseSections';
import { CourseSectionSkeletons } from './CourseSectionSkeletons';

import { CourseOverview } from 'types';

interface CourseBreakdownProps {
  loading: boolean;
  courseOverview: CourseOverview;
  courseType: SelectableCourseTypes;
}
const CourseBreakdown: React.FC<CourseBreakdownProps> = ({ loading, courseOverview, courseType }) => {
  if (loading) {
    return <CourseSectionSkeletons />;
  }

  const sections = get(courseOverview, 'sections', []);
  return (
    <CourseBreakdownContainer>
      <CourseSections courseOverview={courseOverview} courseType={courseType} sections={sections} />
    </CourseBreakdownContainer>
  );
};

const CourseBreakdownContainer = styled(Box)`
  padding: ${({ theme }) => `${theme.space.s3} 0`};
`;

export { CourseBreakdown };

import { ApolloError, useQuery } from '@apollo/client';
import get from 'lodash/get';

import { getAccessPermissionsGetUserPermissionsQuery } from 'data';

interface FeatureAccess {
  hasAccess: boolean;
  loading: boolean;
  error?: ApolloError;
}

const useFeatureAccess = (feature: string): FeatureAccess => {
  const { data, loading, error } = useQuery(getAccessPermissionsGetUserPermissionsQuery(feature));

  const hasAccess = get(data, `AccessPermissions_getUserPermissions.cloud.${feature}`, false);

  return {
    hasAccess,
    loading,
    error
  };
};

export { useFeatureAccess };

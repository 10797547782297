import algoliasearch from 'algoliasearch/lite';
import styled from 'styled-components';
import {
  Configure,
  InstantSearch,
  connectInfiniteHits,
  connectSearchBox,
  connectStateResults,
  connectRefinementList
} from 'react-instantsearch-dom';
import { Flexbox, Box } from '@a-cloud-guru/rainbow-ui';

import { getConfig } from 'config';
import { ScrollableArea } from 'components/common/';
import { SearchResults } from './SearchResults';
import { SearchHeader } from './SearchHeader';
import { QuickSearch } from './QuickSearch';
import { RefinementList } from './RefinementList';
import { TotalSearchResults } from './TotalSearchResults';
import { useAlgoliaSecuredApiKey } from 'hooks';

const { ALGOLIA_APP_ID, PRIVATE_GLOBAL_SEARCH_ALGOLIA_INDEX } = getConfig();

const Results = connectStateResults(SearchResults);
const CustomSearchHeader = connectSearchBox(connectStateResults(SearchHeader));
const CustomTotalSearchResults = connectSearchBox(connectStateResults(TotalSearchResults));
const CustomQuickSearch = connectSearchBox(QuickSearch);
const CustomRefinementList = connectRefinementList(RefinementList);

const SearchPanel: React.FC = () => {
  const securedApiKey = useAlgoliaSecuredApiKey();
  if (!securedApiKey) return null;

  const searchClient = algoliasearch(ALGOLIA_APP_ID, securedApiKey);

  const filters = 'learningTypes:COURSE OR learningTypes:ACG_HANDS_ON_LAB OR learningTypes:PRACTICE_EXAM';
  return (
    <InstantSearch indexName={PRIVATE_GLOBAL_SEARCH_ALGOLIA_INDEX} searchClient={searchClient}>
      <Configure
        filters={filters}
        hitsPerPage={30}
        clickAnalytics={true}
        analyticsTags={['platform:web', 'pathbuilder:search']}
      />
      <PanelContainer>
        <SearchPanelInterface />
      </PanelContainer>
    </InstantSearch>
  );
};

const SearchPanelInterface = connectInfiniteHits(({ hits = [], hasMore, refineNext }) => {
  return (
    <>
      <CustomSearchHeader />
      <CustomRefinementList attribute="learningTypes" searchable={true} />
      <CustomTotalSearchResults hits={hits} />
      <CustomQuickSearch hits={hits} />
      <ScrollableArea>
        <ResultsContainer>
          <Results hits={hits} hasMore={hasMore} refineNext={refineNext} />
        </ResultsContainer>
      </ScrollableArea>
    </>
  );
});

const ResultsContainer = styled(Box)`
  margin-right: ${({ theme }) => theme.space.s3};
`;

const PanelContainer = styled(Flexbox)`
  flex: 1;
  flex-direction: column;
`;

export { SearchPanel };

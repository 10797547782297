import { Filter } from '@a-cloud-guru/react-algolia-table';
import { Team } from 'types';

const generateFilters = (teams: Array<Team>): Array<Filter> => {
  return [
    {
      label: 'Role',
      controlledAttributes: [
        { attributeName: 'organisation_admin', defaultValue: '' },
        { attributeName: 'organisation_team_coordinator', defaultValue: '' },
        { attributeName: 'organisation_student', defaultValue: '' }
      ],
      onChange: (updateFilters, roleId) => {
        updateFilters({
          organisation_admin: 'false',
          organisation_team_coordinator: 'false',
          organisation_student: 'false',
          [roleId]: 'true'
        });
      },
      options: [
        {
          label: 'Admin',
          value: 'organisation_admin'
        },
        {
          label: 'Team Coordinator',
          value: 'organisation_team_coordinator'
        },
        {
          label: 'Student',
          value: 'organisation_student'
        }
      ]
    },
    {
      label: 'Team',
      controlledAttributes: [{ attributeName: 'team', defaultValue: '' }],
      onChange: (updateFilters, teamId) => updateFilters({ team: teamId }),
      options: teams
        .map((team) => ({
          label: team.name,
          value: team.id
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    }
  ];
};

export { generateFilters };

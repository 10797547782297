import styled from 'styled-components';
import { Box, colours, Button, breakpoints } from '@a-cloud-guru/rainbow-ui';

import { ReactComponent as RightArrowSvg } from 'static/images/svg-icons/arrow-right.svg';
import { makeBuildOptimizedSrc } from 'components/common/image';
import { Heading2 } from 'components/typography';
import { CourseOverview } from 'types';
import { SET_SELECTED_ITEM, usePathContext } from 'context/PathContext';
const gradients: Record<string, string> = {
  AWS: 'linear-gradient(94.75deg, #F1385D -1.98%, #F38E38 52.58%, #FAC75C 104.65%)',
  AZURE: 'linear-gradient(94.75deg, #01084F -1.98%, #002BC8 52.58%, #57E6F5 104.65%)',
  GCP: 'linear-gradient(94.75deg, #002BC8 -1.98%, #49BA58 52.58%, #FEF08E 104.65%)',
  DEFAULT: 'linear-gradient(94.75deg, #002BC8 -1.98%, #9035EB 52.58%, #DFC1FA 104.65%)'
};

interface CourseMastHeadProps {
  courseOverview: CourseOverview;
}
const CourseMastHead: React.FC<CourseMastHeadProps> = ({ courseOverview }) => {
  const desktopSrc = makeBuildOptimizedSrc({ width: '1000', format: 'auto' })(courseOverview?.backgroundPosterUrl);
  const mobileSrc = makeBuildOptimizedSrc({ width: '800', crop: 'fill', format: 'auto' })(
    courseOverview?.backgroundPosterUrl
  );
  const {
    state: { selectedItem },
    dispatch
  } = usePathContext();

  const cloudProvider = courseOverview?.vendors?.[0]?.name ?? '';

  return (
    <CourseMastHeadContainer
      desktopSrc={desktopSrc}
      mobileSrc={mobileSrc}
      gradient={gradients[cloudProvider.toUpperCase()] || gradients.DEFAULT}
      onClick={() => dispatch({ type: SET_SELECTED_ITEM, newItem: null })}
    >
      <Box opacity="1">
        <CloseButton type="link">
          <RightArrowSvg title="Close preview" />
        </CloseButton>
        <Title>{selectedItem?.title}</Title>
      </Box>
    </CourseMastHeadContainer>
  );
};

const CloseButton = styled(Button)`
  &&& {
    padding: 0;
    border: none;
    display: flex;
  }
`;

const CourseMastHeadContainer = styled(Box)`
  padding: ${({ theme }) => theme.space.s8};
  background: ${({ desktopSrc, gradient }) => `url(${desktopSrc}) center no-repeat, ${gradient}`};
  @media (max-width: ${breakpoints.md}) {
    background: ${({ mobileSrc, gradient }) => `url(${mobileSrc}) center no-repeat, ${gradient}`};
  }
  transition: all 0.1s ease-in-out;

  :hover {
    cursor: pointer;

    ${CloseButton} {
      transform: translateX(10px);
    }
  }
`;

const Title = styled(Heading2)`
  font-family: 'GT Walsheim';
  color: ${colours.white};
  width: 70%;
  margin: ${({ theme }) => theme.space.s5} 0;
`;

export { CourseMastHead };

import gql from 'graphql-tag';

const PRACTICE_EXAM_QUERY = gql`
  query PathBuilder_practiceExam($practiceExamId: String!) {
    Assessments_assessment(id: $practiceExamId) {
      id
      title
      description
      numberOfQuestions
      totalQuestionsCount
      timeLimitRaw
      skillLevel
      questionPool {
        id
        weight
        domainName
      }
    }
  }
`;

export { PRACTICE_EXAM_QUERY };

import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Card, Flexbox, colours, Button } from '@a-cloud-guru/rainbow-ui';
import { MetadataTile, SKILL_LEVEL_ICONS } from 'components/common';
import { Heading3 } from 'components/typography';
import { ReactComponent as ProviderSvg } from 'static/images/svg-icons/icon-provider.svg';
import { ReactComponent as DurationIcon } from 'static/images/svg-icons/icon-duration.svg';
import { ReactComponent as BinSvg } from 'static/images/svg-icons/bin.svg';
import { ReactComponent as DragHandle } from 'static/images/svg-icons/drag-handle-icon.svg';
import { StyledSVG } from 'components';
import { rgba } from 'polished';
import { PROVIDER_LOOKUP } from 'constant';

interface StandalonePathComponentProps {
  index: number;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  id: string;
  name: string;
  provider?: string;
  difficulty: string;
  duration: string;
  deleteItemFromPath: (componentId: string) => void;
  isDragging: boolean;
  isItemSelected: boolean;
  onClick: () => void;
  type: string;
}

const StandalonePathComponent: React.FC<StandalonePathComponentProps> = ({
  index,
  Icon,
  id,
  name,
  provider = '',
  difficulty = '',
  duration = '',
  deleteItemFromPath,
  isDragging,
  isItemSelected,
  onClick,
  type = ''
}) => {
  const displayProvider = provider ? PROVIDER_LOOKUP[provider.toLowerCase()] : null;
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const iconTestId = `path-component-icon-${type}-${id}`;

  const handleMouseOver = () => setIsHovering(!isHovering);
  const handleMouseOut = () => setIsHovering(false);
  return (
    <ComponentBox
      data-cy={`pb-path-component-${index}`}
      onClick={onClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <ComponentCard
        bodyStyle={{
          padding: '18px',
          background: `${isDragging || isItemSelected || isHovering ? colours.blue100 : ''}`,
          border: `${isDragging || isItemSelected || isHovering ? `1px solid ${colours.blue700}` : ''}`,
          borderRadius: '4px'
        }}
        hoverable
        $isDragging={isDragging}
      >
        <Flexbox mb="14px">
          <DragHandleBox
            data-testid={`drag-handle-${type}-${id}`}
            isDragging={isDragging}
            isHovering={isHovering}
            isItemSelected={isItemSelected}
          >
            <DragHandleWrapper>
              <DragHandle />
            </DragHandleWrapper>
          </DragHandleBox>
          <Box position="relative">
            <ArtworkBackground isSolid={true} bgColor={colours.lightGrey300} />
            <StyledIcon svgComponent={<Icon data-testid={iconTestId} />} />
          </Box>
          <Flexbox ml="10px" justifyContent="space-between" width="80%">
            <Box>
              <PathComponentTitle>{name}</PathComponentTitle>
            </Box>
            <DeleteButton
              data-testid={`delete-component-${type}-${id}-button`}
              data-cy={`pb-path-component-${index}-cta-delete`}
              type="link"
              onClick={(event) => {
                event.stopPropagation();
                deleteItemFromPath(id);
              }}
            >
              <BinSvg role="img" title="Remove component from path" />
            </DeleteButton>
          </Flexbox>
        </Flexbox>
        <Flexbox alignItems="center">
          <Flexbox marginRight="auto">
            {displayProvider && (
              <MetadataTile iconComponent={<ProviderSvg />} description="Provider">
                {displayProvider}
              </MetadataTile>
            )}
            {difficulty && (
              <MetadataTile iconComponent={SKILL_LEVEL_ICONS[difficulty.toLowerCase()]} description="Skill level">
                <StyledSkillLevel>{difficulty}</StyledSkillLevel>
              </MetadataTile>
            )}
          </Flexbox>
          <Flexbox marginLeft="auto">
            {duration && (
              <MetadataTile iconComponent={<DurationIcon />} description="Total duration">
                {duration}
              </MetadataTile>
            )}
          </Flexbox>
        </Flexbox>
      </ComponentCard>
    </ComponentBox>
  );
};

type ComponentCardProps = {
  $isDragging: boolean;
};

const ComponentCard = styled(Card)<ComponentCardProps>`
  border-radius: 4px;
  box-shadow: ${($isDragging) => ($isDragging ? '0px 7px 15px rgba(7, 1, 82, 0.09)' : 'none')};
`;

const PathComponentTitle = styled(Heading3)`
  color: ${colours.navy900};
  font-weight: 700;
  line-height: 1.2em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ComponentBox = styled(Box)`
  margin-bottom: 10px;
`;

const DeleteButton = styled(Button)`
  &&& {
    padding: 0;
    border: none;
    display: flex;
    transition: none;
  }
  :hover {
    * > path {
      fill: ${colours.red700};
    }
  }
`;

const StyledSkillLevel = styled.span`
  text-transform: capitalize;
`;
interface artworkBackgroundProps {
  bgColor: string;
  isSolid: boolean;
}
const ArtworkBackground = styled.div<artworkBackgroundProps>`
  width: 89px;
  height: 50px;
  border-radius: 2px;
  background-color: ${({ bgColor, isSolid }) => (bgColor && isSolid ? bgColor : rgba(bgColor, 0.15))};
`;

const StyledIcon = styled(StyledSVG)`
  position: absolute;
  width: 37px;
  height: 37px;
  left: 28px;
  top: 6px;
`;

const DragHandleBox = styled(Box)`
  display: ${(props) => (props.isDragging || props.isHovering || props.isItemSelected ? 'block' : 'none')};
  z-index: 1;
  position: absolute;
  width: 30px;
  top: 0;
  bottom: 0;
  left: 0;
  background: rgba(208, 223, 255, 0.5);
  border-left: 1px solid ${colours.blue700};
  border-top: 1px solid ${colours.blue700};
  border-bottom: 1px solid ${colours.blue700};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;
const DragHandleWrapper = styled(Box)`
  margin-left: 10px;
  margin-top: 50px;
`;
export { StandalonePathComponent };

import gql from 'graphql-tag';

const USER_PROFILE_QUERY = gql`
  query PathBuilder_userProfileQuery {
    userIdentity {
      id
      name
      email
      roles {
        organisationAdmin
      }
    }
    organisation {
      id
      name
      betaFeatureRequests {
        feature
        status
      }
    }
  }
`;

export { USER_PROFILE_QUERY };

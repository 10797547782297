import React from 'react';
import { StandalonePathComponent } from './StandalonePathComponent';
import { ReactComponent as SearchExamsIcon } from 'static/images/svg-icons/search-exams.svg';
import { PROVIDER_LOOKUP } from '../../../constant';
import { ExamComponent } from 'types';

interface ExamPathComponentProps {
  index: number;
  examComponent: ExamComponent;
  deleteItemFromPath: (componentId: string) => void;
  isDragging: boolean;
  isItemSelected: boolean;
  onClick: () => void;
}

const ExamPathComponent: React.FC<ExamPathComponentProps> = ({
  index,
  examComponent,
  deleteItemFromPath,
  isDragging,
  isItemSelected,
  onClick
}) => {
  const {
    exam: { url, title, skillLevels, cloudProviders, duration }
  } = examComponent;
  const cloudProvidersLookups =
    cloudProviders?.map((cloudProvider) => PROVIDER_LOOKUP[cloudProvider?.toLowerCase()]) || [];
  const provider = cloudProvidersLookups.find((cloudProvider) => cloudProvider);
  const difficulty = (skillLevels.find((skillLevel) => skillLevel) || '').toLowerCase();
  return (
    <StandalonePathComponent
      index={index}
      Icon={SearchExamsIcon}
      id={url}
      name={title}
      provider={provider}
      difficulty={difficulty}
      duration={duration}
      deleteItemFromPath={deleteItemFromPath}
      isDragging={isDragging}
      isItemSelected={isItemSelected}
      onClick={onClick}
      type="exam"
    />
  );
};

export { ExamPathComponent };

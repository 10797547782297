import styled from 'styled-components';
import { colours, Box, Flexbox } from '@a-cloud-guru/rainbow-ui';

import { Skeleton } from 'components/common';

const CourseSectionSkeleton: React.FC = () => (
  <CourseBreakdownContainer>
    <Skeleton width="100px" mb="s4" />
    <Flexbox justifyContent="space-between" mb="s6">
      <Skeleton width="40%" height="24px" bgColor={colours.darkGrey400} />
      <Skeleton width="15%" height="24px" bgColor={colours.darkGrey400} />
    </Flexbox>
    <Flexbox justifyContent="space-between" mb="s6">
      <Skeleton width="25%" />
      <Skeleton width="10%" />
    </Flexbox>
    <Flexbox justifyContent="space-between" mb="s6">
      <Skeleton width="35%" />
      <Skeleton width="10%" />
    </Flexbox>
    <Flexbox justifyContent="space-between" mb="s6">
      <Skeleton width="30%" />
      <Skeleton width="10%" />
    </Flexbox>
  </CourseBreakdownContainer>
);

const CourseSectionSkeletons: React.FC = () => (
  <>
    {[...new Array(3)].map((_, index) => (
      <CourseSectionSkeleton key={index} />
    ))}
  </>
);

export { CourseSectionSkeletons };

const CourseBreakdownContainer = styled(Box)`
  padding: ${({ theme }) => `${theme.space.s7} ${theme.space.s2} 0`};
`;

import styled from 'styled-components';
import { colours, Flexbox } from '@a-cloud-guru/rainbow-ui';

interface MainLayoutProps {
  header: JSX.Element;
  column1: JSX.Element;
  column2: JSX.Element;
  column3: JSX.Element | null;
}
const MainLayout: React.FC<MainLayoutProps> = ({ header, column1, column2, column3 }) => (
  <AppContainer>
    <Header>{header}</Header>

    <ColumnsContainer flexDirection={{ _: 'column', md: 'row' }}>
      <Column1 width={{ _: 1, md: '420px' }}>{column1}</Column1>
      <Column2 minWidth={{ _: 1, md: '450px', xl: '480px' }}>{column2}</Column2>
      <Column3 className={column3 ? 'open' : null}>{column3}</Column3>
    </ColumnsContainer>
  </AppContainer>
);

export { MainLayout };

const AppContainer = styled(Flexbox)`
  height: 100%;
  flex-direction: column;
  overflow: hidden;
`;

const Header = styled(Flexbox)`
  justify-content: space-between;
  height: ${({ theme }) => theme.space.s18};

  background: ${colours.navy700};
`;

const ColumnsContainer = styled(Flexbox)`
  flex: 1;
  height: calc(100% - ${({ theme }) => theme.space.s18});
  position: relative;
`;

const Column = styled(Flexbox)`
  padding: ${({ theme }) => theme.space.s5};
  padding-bottom: 0px;
`;

const Column1 = styled(Column)`
  background: ${colours.lightGrey300};
`;

const Column2 = styled(Column)`
  flex: 1;
  background: ${colours.white};
`;

const Column3 = styled(Flexbox)`
  height: 100%;
  flex: 0;
  background: ${colours.lightGrey200};
  position: absolute;
  z-index: 3; // it has to be higher than 2 in order to hide the AntD close 'x' icon from SearchHeader.
  width: 0%;
  right: 0;
  &.open {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    &.open {
      max-width: calc(100% - 420px);
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    flex: 1;
    &.open {
      position: relative;
      max-width: 1000px;
    }
  } ;
`;

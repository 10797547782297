import { Flexbox, Box, breakpoints, colours } from '@a-cloud-guru/rainbow-ui';
import styled from 'styled-components';

import { StyledSVG } from 'components/common';

interface MetadataTileProps {
  iconComponent?: React.ReactNode;
  description?: string;
  loading?: boolean;
  dataCy?: string;
}

const MetadataTile: React.FC<MetadataTileProps> = ({
  iconComponent,
  children,
  description,
  loading = false,
  dataCy = null
}) => {
  if (loading) {
    return null;
  }

  return (
    <SummaryContainer data-cy={dataCy}>
      {iconComponent && (
        <Box>
          <StyledSVG svgComponent={iconComponent} size="12px" styles={'margin-right: 5px;'} title={description} />
        </Box>
      )}
      <Content>{children}</Content>
    </SummaryContainer>
  );
};

const Content = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: ${colours.navy900};
  white-space: nowrap;

  @media (max-width: ${breakpoints.xl}) {
    font-size: 12px;
    line-height: 14px;
  }
`;

const SummaryContainer = styled(Flexbox)`
  align-items: center;
  padding-right: ${({ theme }) => theme.space.s2};
  display: flex;
  &:last-child {
    padding-right: 0;
  }
`;

export { MetadataTile };

import { ApolloError, useQuery } from '@apollo/client';

import { LAB_QUERY } from 'data';
import { LabKeep_Lab } from 'types';

interface UseGetLab {
  lab: LabKeep_Lab;
  loading: boolean;
  error?: ApolloError;
}

// TODO: Refactor with GQL CodeGen
const useGetLab = ({ labId }: { labId: string | undefined }): UseGetLab => {
  const { data, loading, error } = useQuery(LAB_QUERY, {
    variables: {
      labId
    },
    skip: !labId
  });

  return {
    lab: data?.LabKeep_getLab || {},
    loading,
    error
  };
};

export { useGetLab };

import qs from 'query-string';
import * as snippet from '@segment/snippet';
import gql from 'graphql-tag';
import { Helmet } from 'react-helmet';

import { getConfig } from 'config';
import apolloService from 'services/apollo';

const { SEGMENT_API_KEY, RELEASE_STAGE } = getConfig();

interface SegmentPayload {
  [key: string]: string | number | boolean;
}

export const shouldLoadSegment = (): boolean => {
  const shouldTrackEnv = RELEASE_STAGE !== 'dev';
  const hasSegmentKey = !!SEGMENT_API_KEY;
  const isClientRender = typeof window !== 'undefined';

  return shouldTrackEnv && hasSegmentKey && isClientRender;
};

const segmentEnabled = (): boolean => {
  return window !== undefined && window.analytics !== undefined;
};

export const sendCurrentPage = (name: string, properties: SegmentPayload): void => {
  if (!segmentEnabled()) return;
  const query = qs.parse(window.location.search);
  const route = window.location.pathname;
  window.analytics.page(name || route, properties || query);
};

export const sendTrack = (event: string, properties: SegmentPayload): void => {
  if (RELEASE_STAGE === 'dev') return;

  const TRACK_EVENT = gql`
    mutation($event: String!, $properties: String!) {
      customLearningPathTrackEvent(event: $event, properties: $properties)
    }
  `;

  const client = apolloService.get();

  if (client) {
    try {
      client.mutate({
        mutation: TRACK_EVENT,
        variables: {
          event,
          properties: JSON.stringify(properties)
        }
      });
    } catch {
      // Silence tracking failures.
    }
  }
};

export const sendIdentify = (userId: string, traits: SegmentPayload): void => {
  if (segmentEnabled()) window.analytics.identify(userId, traits);
};

export const SegmentSnippet: React.FC = () => {
  if (!shouldLoadSegment()) {
    return null;
  }

  const renderSnippet = () => {
    const opts = {
      apiKey: SEGMENT_API_KEY,
      page: false
    };

    return RELEASE_STAGE === 'production' ? snippet.min(opts) : snippet.max(opts);
  };

  return (
    <Helmet>
      <script>{renderSnippet()}</script>;
    </Helmet>
  );
};

import isEmpty from 'lodash/isEmpty';
import { Flexbox } from '@a-cloud-guru/rainbow-ui';
import styled from 'styled-components';

import { Hit } from 'react-instantsearch-core';
import { AlgoliaSearchHit } from 'types/algolia-search-hit';
import { MetadataTile, SKILL_LEVEL_ICONS } from 'components/common';

import { ReactComponent as ProviderIcon } from 'static/images/svg-icons/icon-provider.svg';
import { ReactComponent as DurationIcon } from 'static/images/svg-icons/icon-duration.svg';

type MetadataHit = Hit &
  Pick<AlgoliaSearchHit, 'description' | 'duration' | 'imageUrl' | 'skillLevels' | 'cloudProviders' | 'type'>;
interface MetadataProps {
  hit: MetadataHit;
}

const Metadata: React.FC<MetadataProps> = ({ hit }) => {
  if (isEmpty(hit)) {
    return null;
  }

  const { duration, skillLevels = [], cloudProviders = [] } = hit;
  const skillLevel = skillLevels[0] ?? '';
  const cloudProvider = cloudProviders[0] ?? '';
  return (
    <SummaryContainer>
      {!isEmpty(cloudProvider) && (
        <MetadataTile iconComponent={<ProviderIcon />} description={cloudProvider}>
          {cloudProvider}
        </MetadataTile>
      )}
      {!isEmpty(skillLevel) && (
        <MetadataTile iconComponent={SKILL_LEVEL_ICONS[skillLevel.toLowerCase()]} description={skillLevel}>
          {skillLevel}
        </MetadataTile>
      )}
      {!isEmpty(duration) && (
        <MetadataTile iconComponent={<DurationIcon />} description={duration}>
          {duration}
        </MetadataTile>
      )}
    </SummaryContainer>
  );
};

const SummaryContainer = styled(Flexbox)`
  align-items: center;
  height: 15px;
  min-width: 150px;
  padding-right: 10px;
`;

export type { MetadataHit };
export { Metadata };

import styled from 'styled-components';
import { Box, Spin } from '@a-cloud-guru/rainbow-ui';

const Loading: React.FC = () => (
  <SpinContainer>
    <Spin size={'large'} />
  </SpinContainer>
);

export { Loading };

const SpinContainer = styled(Box)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import styled from 'styled-components';
import { Box, breakpoints } from '@a-cloud-guru/rainbow-ui';
import { ReactComponent as RightArrowSvg } from 'static/images/svg-icons/arrow-right.svg';
import { ReactComponent as LabWithGradientIcon } from 'static/images/svg-icons/lab-with-gradient.svg';
import { SET_SELECTED_ITEM, usePathContext } from 'context/PathContext';
import { mastHeadWallpaperDesktopSrc, mastHeadWallpaperMobileSrc, mastHeadBackgroundColour } from './constants';
import { MastHeadContainer as LabMastHeadContainer, CloseButton, TitleContainer, Title } from './DetailsContainer';

const LabMastHead: React.FC = () => {
  const {
    state: { selectedItem },
    dispatch
  } = usePathContext();

  return (
    <LabMastHeadContainer
      desktopSrc={mastHeadWallpaperDesktopSrc}
      mobileSrc={mastHeadWallpaperMobileSrc}
      backgroundColour={mastHeadBackgroundColour}
      onClick={() => dispatch({ type: SET_SELECTED_ITEM, newItem: null })}
    >
      <Box opacity="1">
        <CloseButton type="link">
          <RightArrowSvg title="Close preview" />
        </CloseButton>
        <TitleContainer>
          <LabIcon title="Hands-on Lab" />
          <Title>{selectedItem?.title}</Title>
        </TitleContainer>
      </Box>
    </LabMastHeadContainer>
  );
};

const LabIcon = styled(LabWithGradientIcon)`
  width: 64px;
  height: 64px;
  margin-right: 20px;
  @media (max-width: ${breakpoints.sm}) {
    display: none;
  }
`;

export { LabMastHead };

import { ApolloError, useQuery } from '@apollo/client';

import { NUMBER_OF_ORGANISATION_LEARNING_PATH_USERS } from 'data';

interface UseGetNumberOfOrganisationLearningPathUsers {
  data: any;
  loading: boolean;
  error?: ApolloError;
}

// TODO: Refactor with GQL CodeGen
const useGetNumberOfOrganisationLearningPathUsers = ({
  learningPathId
}: {
  learningPathId: string;
}): UseGetNumberOfOrganisationLearningPathUsers => {
  const { data, loading, error } = useQuery(NUMBER_OF_ORGANISATION_LEARNING_PATH_USERS, {
    variables: {
      learningPathId
    }
  });

  return {
    data: data?.numberOfOrganisationLearningPathUsers,
    loading,
    error
  };
};

export { useGetNumberOfOrganisationLearningPathUsers };

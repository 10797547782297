import React from 'react';
import styled from 'styled-components';
import { Steps, colours } from '@a-cloud-guru/rainbow-ui';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { ReactComponent as CircleTickIcon } from 'static/images/svg-icons/circle-tick.svg';
import { Skeleton } from 'components/common';
import { LabKeep_LabTask } from 'types';
import { HeadingTitle } from './DetailsContainer';

const { Step } = Steps;

interface LabMetaDataProps {
  loading: boolean;
  tasks: Array<LabKeep_LabTask>;
}

const LabObjectives: React.FC<LabMetaDataProps> = ({ loading, tasks }) => {
  const labTasks = tasks?.map((task) => {
    if (task?.disabled) {
      return null;
    }
    const explanation = <ReactMarkdown plugins={[remarkGfm]}>{task?.explanation}</ReactMarkdown>;
    return <Task key={task?.id} icon={<CircleTickIcon />} title={task?.name} description={explanation} />;
  });
  const hasTask = tasks?.some((task) => !task?.disabled);
  return (
    <>
      {loading ? (
        <>
          <Skeleton width="30%" bgColor={colours.darkGrey400} my="s5" />
          <Skeleton width="60%" bgColor={colours.darkGrey400} my="s2" />
          <Skeleton width="100%" bgColor={colours.darkGrey400} my="s2" />
          <Skeleton width="100%" bgColor={colours.darkGrey400} my="s2" />
        </>
      ) : hasTask ? (
        <>
          <HeadingTitle>Objectives</HeadingTitle>
          <Steps direction="vertical" current={tasks?.length} status="finish">
            {labTasks}
            {/* <Task> component at end to get tail for last lab task */}
            <Task icon={<></>} />
          </Steps>
        </>
      ) : null}
    </>
  );
};

const Task = styled(Step)`
  .ant-steps-item-icon {
    width: 20px;
    height: 20px;
    margin-left: 2px;
    margin-right: ${({ theme }) => theme.space.s3};
  }
  .ant-steps-item-icon > .ant-steps-icon {
    font-size: 0;
  }
  .ant-steps-item-container > .ant-steps-item-tail::after {
    background: ${colours.indigo100};
    width: ${({ theme }) => theme.space.s1} !important;
    border-radius: ${({ theme }) => theme.space.s5};
    position: relative;
    right: 6px;
    bottom: 10px;
  }
  .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: ${colours.navy900};
  }
  .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: ${colours.navy900};
    padding-bottom: 0;
  }
  code {
    font-family: 'Monaco';
    color: ${colours.navy900};
    padding: 0 2px;
    background-color: rgba(125, 120, 177, 0.2);
    border-radius: 2px;
    display: inline-block;
    white-space: pre-wrap;
  }
`;

export { LabObjectives };

import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import styled from 'styled-components';
import { Modal, Button } from '@a-cloud-guru/rainbow-ui';
import algoliasearch from 'algoliasearch';
import { BatchAlgoliaPicker, ViewMode } from '@a-cloud-guru/react-algolia-table';

import { getConfig } from 'config/get-config';
import { useTeamsQuery, useAlgoliaApiKey } from 'hooks';
import { AlgoliaUser, LearningPathType } from 'types';
import { generateColumns } from './generateColumns';
import { generateFilters } from './generateFilters';
import { BatchAddUsersToPath } from './BatchAddUsersToPath';

const { ALGOLIA_APP_ID, ORG_USERS_ALGOLIA_INDEX, ACG_BUSINESS_URL } = getConfig();

interface AssignUsersActionProps {
  pathId: string;
  pathType: LearningPathType;
  pathTitle: string;
  isAddUserModalVisible: boolean;
  showAddUserModal: () => void;
  hideAddUserModal: () => void;
}

export const AssignUsersAction: React.FC<AssignUsersActionProps> = ({
  pathId,
  pathType,
  pathTitle,
  isAddUserModalVisible,
  showAddUserModal,
  hideAddUserModal
}) => {
  const [initialViewMode, setInitialViewMode] = useState<ViewMode>(ViewMode.SELECT_RECORDS);
  const [showUploadingUsersModal, setShowUploadingUsersModal] = useState(false);
  const [preselectedUsers, setPreselectedUsers] = useState<Array<AlgoliaUser>>([]);
  const [usersToAdd, setUsersToAdd] = useState<Array<AlgoliaUser>>([]);
  const [usersInPath, setUsersInPath] = useState<Array<AlgoliaUser> | undefined>();
  const { scopedKey } = useAlgoliaApiKey();
  const teamsQuery = useTeamsQuery();

  useEffect(() => {
    const fetchUsers = async () => {
      if (!scopedKey) return; // Scoped key has not been generated yet.
      if (usersInPath) return; // Users already fetched.

      let currentUsersInPath: Array<AlgoliaUser> = [];
      const searchClient = algoliasearch(ALGOLIA_APP_ID, scopedKey);

      await searchClient.initIndex(ORG_USERS_ALGOLIA_INDEX).browseObjects({
        query: '*',
        filters: `learning_path:${pathId}`,
        batch: (batch) => (currentUsersInPath = currentUsersInPath.concat(batch as Array<AlgoliaUser>))
      });

      setUsersInPath(currentUsersInPath);
    };

    fetchUsers();
  }, [pathId, scopedKey, usersInPath]);

  const onUsersAdd = useCallback(
    (addedUsers: Array<AlgoliaUser>) => setUsersInPath((currentUsers = []) => [...currentUsers, ...addedUsers]),
    []
  );

  const isSelected = (usersInPath: Array<AlgoliaUser>) => (user: AlgoliaUser) =>
    !!usersInPath.find((u) => user.objectID === u.objectID);

  const isSelectable = (usersInPath: Array<AlgoliaUser>) => (user: AlgoliaUser) =>
    !user.organisation_admin_only && !usersInPath.find((u) => user.objectID === u.objectID);

  const isPreSelected = (user: AlgoliaUser): boolean => {
    return !!preselectedUsers.find((u) => u.objectID === user.objectID);
  };

  // TODO: Temporary for Add Users Modal work - Will replace main action eventually
  if (!scopedKey) return null;
  if (!teamsQuery.data) return null;
  if (!usersInPath) return null;

  return (
    <>
      <StyledModal
        title=""
        visible={isAddUserModalVisible}
        destroyOnClose={true}
        footer={null}
        closable={false}
        width="90vw"
        style={{ top: 60 }}
        maskClosable={false}
        onCancel={hideAddUserModal}
      >
        <BatchAlgoliaPicker<AlgoliaUser>
          algoliaAppId={ALGOLIA_APP_ID}
          algoliaIndexName={ORG_USERS_ALGOLIA_INDEX}
          algoliaAPIKey={scopedKey}
          columns={generateColumns({ teams: teamsQuery.data, isSelected: isSelected(usersInPath) })}
          entitySingularName={'user'}
          entityPluralName={'users'}
          filters={generateFilters(teamsQuery.data)}
          preselectedRows={preselectedUsers}
          persistentFilters={{
            organisation_license_consumed: 'true',
            active: 'true'
          }}
          onSubmitText={'Add to Path'}
          initialViewMode={initialViewMode}
          isSelectable={(user) => isSelectable(usersInPath)(user)}
          isSelected={(user) => isSelected(usersInPath)(user) || isPreSelected(user)}
          tagline={
            <Tagline>
              Add to path: <strong>{pathTitle}</strong>
            </Tagline>
          }
          onCancel={() => {
            hideAddUserModal();
            setInitialViewMode(ViewMode.SELECT_RECORDS);
          }}
          onSubmit={(newUsers) => {
            if (newUsers.length === 0) {
              message.config({ top: 50 });
              message.error('You must select at least one user to add to path');
              return;
            }

            setUsersToAdd(newUsers);
            setPreselectedUsers([]);
            setInitialViewMode(ViewMode.SELECT_RECORDS);
            setShowUploadingUsersModal(true);
            hideAddUserModal();
          }}
        />
      </StyledModal>

      <Modal
        title="Adding users to path"
        onCancel={() => setShowUploadingUsersModal(false)}
        visible={showUploadingUsersModal}
        destroyOnClose={true}
        footer={null}
        style={{ top: 60 }}
      >
        <BatchAddUsersToPath usersToAdd={usersToAdd} pathId={pathId} pathType={pathType} onUsersAdd={onUsersAdd}>
          {({ inProgress, addedUsers }) => {
            const addedUsersCount = addedUsers.length;
            const usersToAddCount = usersToAdd.length;
            const failedUsersCount = usersToAddCount - addedUsersCount;

            const showViewUsers = !inProgress && addedUsersCount > 0;
            const showRetryUsers = !inProgress && failedUsersCount > 0;

            return (
              <ProgressSummaryFooter>
                {inProgress && <Button onClick={() => setShowUploadingUsersModal(false)}>Cancel</Button>}
                {showViewUsers && (
                  <Button
                    type={showRetryUsers ? 'ghost' : 'primary'}
                    onClick={() => {
                      setShowUploadingUsersModal(false);
                      window.location.assign(`${ACG_BUSINESS_URL}/learning-path?id=${pathId}`);
                    }}
                  >
                    View users on path
                  </Button>
                )}
                {showRetryUsers && (
                  <Button
                    type="primary"
                    onClick={() => {
                      const addedUserIds = addedUsers.map((user) => user.objectID);
                      const failedUsers = usersToAdd.filter((user) => !addedUserIds.includes(user.objectID));

                      setUsersToAdd([]);
                      setInitialViewMode(ViewMode.REVIEW_SELECTED_RECORDS);
                      setPreselectedUsers(failedUsers);
                      showAddUserModal();
                      setShowUploadingUsersModal(false);
                    }}
                  >
                    Retry adding
                  </Button>
                )}
              </ProgressSummaryFooter>
            );
          }}
        </BatchAddUsersToPath>
      </Modal>
    </>
  );
};

const Tagline = styled.span`
  color: #427eff;
  font-size: 16px;
`;

const StyledModal = styled(Modal)`
  &&& {
    .ant-modal-content {
      width: 90vw;
      padding: 0;
      margin: 0;
    }

    .ant-modal-body {
      padding: 0;
      margin: 0;
    }
  }
`;

const ProgressSummaryFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > button {
    margin: 0 4px;
  }
`;

import { createContext, useContext, useState } from 'react';

interface BannerProps {
  bannerToggle: boolean;
  setBannerToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialState = {} as BannerProps;
const TempBannerContext = createContext<BannerProps>(initialState);

const useTempBannerContext = (): BannerProps => useContext(TempBannerContext);

const TempBannerProvider: React.FC = ({ children }) => {
  const [bannerToggle, setBannerToggle] = useState(true);
  return <TempBannerContext.Provider value={{ bannerToggle, setBannerToggle }}>{children}</TempBannerContext.Provider>;
};

export { TempBannerProvider, useTempBannerContext };

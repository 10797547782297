import { PracticeExamMastHead } from './PracticeExamMastHead';
import { useGetPracticeExam } from 'hooks';
import { useEffect } from 'react';
import { message } from '@a-cloud-guru/rainbow-ui';
import { PracticeExamMetaData } from './PracticeExamMetaData';
import { PracticeExamCallOut } from './PracticeExamCallOut';
import { PracticeExamDomainBreakdown } from './PracticeExamDomainBreakdown';
import { getConfig } from 'config';
import { DetailsContainer } from './DetailsContainer';
import { ExternalLinkComponent } from './ExternalLinkComponent';
interface PracticeExamDetailsProps {
  practiceExamId?: string;
  cloudProviders: string[];
}
const { ACG_PRACTICE_EXAM_DOMAIN } = getConfig();
const errorMessage = 'Failed to fetch practice exam details. Check your connection and try again.';

const PracticeExamDetails: React.FC<PracticeExamDetailsProps> = ({ practiceExamId, cloudProviders }) => {
  // Edge case: bad Algolia staging objectID (no "EXAM_" prefix)
  const isBadExamId = !practiceExamId?.startsWith('EXAM_');
  const badExamMessage = 'Cannot add exam. Please contact support';
  const practiceExamIdWithoutPrefix = practiceExamId?.replace('EXAM_', '');
  const { practiceExam, loading, error } = useGetPracticeExam({ practiceExamId: practiceExamIdWithoutPrefix });
  const hasError = !loading && (error || !practiceExam || !practiceExamId || Object.keys(practiceExam).length === 0);
  const practiceExamLandingUrl = `${ACG_PRACTICE_EXAM_DOMAIN}/${practiceExamIdWithoutPrefix}`;

  useEffect(() => {
    if (hasError) {
      message.error(errorMessage);
    }
  }, [hasError]);
  return (
    <>
      <PracticeExamMastHead />
      <DetailsContainer>
        {hasError ? (
          <div>{errorMessage}</div>
        ) : (
          <>
            <PracticeExamMetaData
              loading={loading}
              isBadExamId={isBadExamId}
              badExamMessage={badExamMessage}
              practiceExam={practiceExam}
              cloudProviders={cloudProviders}
            />
            <PracticeExamCallOut loading={loading} practiceExam={practiceExam} />
            <ExternalLinkComponent
              linkText="View Practice Exam"
              landingUrl={practiceExamLandingUrl}
              loading={loading}
            />
            <PracticeExamDomainBreakdown loading={loading} questionPool={practiceExam.questionPool} />
          </>
        )}
      </DetailsContainer>
    </>
  );
};

export { PracticeExamDetails };

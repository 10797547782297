import React, { createContext, useContext, Dispatch } from 'react';
import { ApolloError } from '@apollo/client';
import { CourseOverview, CustomLearningPath } from 'types';
import { NEW_PATH_ID } from 'constant';
import { CourseCLPComponent, ComponentType, CLPComponent } from '../types/learning-path/Component';
import { Section } from '../types/learning-path/Section';
import { SelectedItem } from '../types/selected-item';
import { UpdatedComponent } from 'types/common';
import { isComponentIdInPath } from 'utils/isComponentIdInPath';
import { useImmerReducer } from 'use-immer';
import cloneDeep from 'lodash/cloneDeep';
export const UPDATE_PATH = 'UPDATE_PATH';
export const SET_LOADING_PATH = 'SET_LOADING_PATH';
export const SET_ERROR_PATH = 'SET_ERROR_PATH';
export const SET_PATH_ID = 'SET_PATH_ID';
export const ADD_ITEM_TO_PATH = 'ADD_ITEM_TO_PATH';
export const POST_ADD_ITEM_TO_PATH = 'POST_ADD_ITEM_TO_PATH';
export const UPDATE_COURSE_META_DATA = 'UPDATE_COURSE_META_DATA';
export const DELETE_ITEM_FROM_PATH = 'DELETE_ITEM_FROM_PATH';
export const SET_PATH_IS_SAVED = 'SET_PATH_IS_SAVED';
export const UPDATE_PATH_TITLE = 'UPDATE_PATH_TITLE';
export const UPDATE_PATH_DESCRIPTION = 'UPDATE_PATH_DESCRIPTION';
export const UPDATE_METADATA = 'UPDATE_METADATA';
export const UPDATE_IS_NEW_PATH = 'UPDATE_IS_NEW_PATH';
export const UPDATE_PUBLISHED_AT = 'UPDATE_PUBLISHED_AT';
export const REORDER_ITEMS = 'REORDER_ITEMS';
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';
export const REMOVE_ALL_COURSE_COMPONENTS = 'REMOVE_ALL_COURSE_COMPONENTS';
export const ADD_ALL_COURSE_COMPONENTS = 'SELECT_ALL_COURSE_COMPONENTS';
export const CONVERT_TO_COURSE_EXTRACT = 'CONVERT_TO_COURSE_EXTRACT';
export const CONVERT_TO_COURSE = 'CONVERT_TO_COURSE';

// course extract actions
export const ADD_COMPONENT = 'ADD_COMPONENT';
export const REMOVE_COMPONENT = 'REMOVE_COMPONENT';
export const ADD_CHAPTER_COMPONENTS = 'ADD_CHAPTER_COMPONENTS';
export const REMOVE_CHAPTER_COMPONENTS = 'REMOVE_CHAPTER_COMPONENTS';

type PathReducerAction =
  | {
      type: typeof UPDATE_PATH;
      path: CustomLearningPath;
      isNewPath: boolean;
    }
  | {
      type: typeof SET_LOADING_PATH;
    }
  | {
      type: typeof SET_ERROR_PATH;
      error: ApolloError;
    }
  | {
      type: typeof SET_PATH_ID;
      pathId: string;
    }
  | {
      type: typeof ADD_ITEM_TO_PATH;
      newItem: CLPComponent;
      isSaved: boolean;
    }
  | {
      type: typeof POST_ADD_ITEM_TO_PATH;
    }
  | {
      type: typeof UPDATE_COURSE_META_DATA;
      updatedComponent: UpdatedComponent;
    }
  | {
      type: typeof DELETE_ITEM_FROM_PATH;
      componentId: string;
      componentType: ComponentType;
      isSaved: boolean;
    }
  | {
      type: typeof SET_PATH_IS_SAVED;
      isSaved: boolean;
    }
  | {
      type: typeof UPDATE_PATH_TITLE;
      title: string;
      isSaved: boolean;
    }
  | {
      type: typeof UPDATE_PATH_DESCRIPTION;
      description?: string;
      isSaved: boolean;
    }
  | {
      type: typeof UPDATE_METADATA;
      title: string;
      description?: string;

      isSaved: boolean;
    }
  | {
      type: typeof UPDATE_IS_NEW_PATH;
      isNewPath: boolean;
    }
  | {
      type: typeof UPDATE_PUBLISHED_AT;
      publishedAt: string;
    }
  | {
      type: typeof REORDER_ITEMS;
      startIndex: number;
      endIndex: number;
    }
  | {
      type: typeof SET_SELECTED_ITEM;
      newItem: SelectedItem | null;
    }
  | {
      type: typeof ADD_COMPONENT;
      courseExtractId: string;
      componentId: string;
      duration: number;
    }
  | {
      type: typeof REMOVE_COMPONENT;
      courseExtractId: string;
      componentId: string;
      duration: number;
    }
  | {
      type: typeof REMOVE_ALL_COURSE_COMPONENTS;
      courseId: string;
    }
  | {
      type: typeof ADD_ALL_COURSE_COMPONENTS;
      courseId: string;
      totalCourseDuration: number | null;
    }
  | {
      type: typeof ADD_CHAPTER_COMPONENTS;
      courseExtractId: string;
      extractComponentIds: Array<string>;
      componentsIdAndDuration: Array<{ id: string; duration: number }>;
    }
  | {
      type: typeof REMOVE_CHAPTER_COMPONENTS;
      courseExtractId: string;
      extractComponentIds: Array<string>;
      componentsIdAndDuration: Array<{ id: string; duration: number }>;
    }
  | {
      type: typeof CONVERT_TO_COURSE_EXTRACT;
      courseExtractId: string;
      courseOverview: CourseOverview;
    }
  | {
      type: typeof CONVERT_TO_COURSE;
      totalCourseDuration: number | null;
    };

export interface PathStateType {
  path: CustomLearningPath;
  loadingPath: boolean;
  errorPath?: ApolloError;
  isSaved: boolean;
  isNewPath: boolean;
  isReadOnly: boolean;
  isScrollingPathContentToEnd: boolean;
  selectedItem: SelectedItem | null;
}

const newPath: CustomLearningPath = {
  id: NEW_PATH_ID,
  title: 'Untitled Path',
  sections: [],
  updatedAt: '',
  publishedAt: null
};

const defaultState: PathStateType = {
  path: newPath,
  loadingPath: false,
  errorPath: undefined,
  isSaved: true,
  isNewPath: false,
  isReadOnly: false,
  isScrollingPathContentToEnd: false,
  selectedItem: null
};

export const PathContext = createContext<{
  state: PathStateType;
  dispatch: Dispatch<PathReducerAction>;
}>({
  state: defaultState,
  dispatch: () => null
});

//eslint-disable-next-line
export const usePathContext = () => useContext(PathContext);
export interface PathStateProps {
  initialState?: PathStateType;
}

type courseTypes = CourseCLPComponent['type'];

const getTitle = (newItem: CLPComponent): string => {
  switch (newItem.type) {
    case 'course':
    case 'clp_course_extract':
      return newItem.title;
    case 'ACG_HANDS_ON_LAB':
      return newItem.lab.name;
    case 'PRACTICE_EXAM':
      return newItem.exam.title;
    default:
      return '';
  }
};

const pathReducer = (state: PathStateType, action: PathReducerAction): PathStateType | undefined => {
  const oldPath = state.path;
  const oldSection = oldPath.sections[0] ?? oldPath.sections;
  const oldComponents = oldSection?.components || [];

  // helper functions
  const getCourseIndex = (courseId: string, courseType: courseTypes) =>
    state.path.sections[0].components.findIndex(
      (component) => courseId === component.id && component.type === courseType
    );

  const calculateComputedDuration = (duration: number, courseIndex: number) => {
    const component = (state.path.sections[0].components[courseIndex] as unknown) as CourseCLPComponent;
    const computedDuration = component.computedDuration;
    return computedDuration ? computedDuration + duration : duration;
  };

  const removeComponentFromPath = (id: string, type: ComponentType) => {
    const idParsed = id.replace('EXAM_', '');
    state.path.sections[0].components = state.path.sections[0].components.filter(
      (component) => !(component.id === idParsed && component.type === type)
    );
  };
  switch (action.type) {
    case UPDATE_PATH: {
      state.loadingPath = false;
      state.path = action.path;
      state.isNewPath = action.isNewPath;
      return state;
    }
    case SET_LOADING_PATH: {
      state.loadingPath = true;
      return state;
    }
    case ADD_ITEM_TO_PATH: {
      const { newItem } = action;
      const { id, type } = newItem;
      const title = getTitle(newItem);

      if (state.path.sections.length > 0) {
        state.path.sections[0].components.push(newItem);
      } else {
        const newSection: Section = { components: [newItem] };
        state.path.sections.push(newSection);
      }
      if (type === 'clp_course_extract' || type === 'course') {
        const extractCourseId = 'courseId' in newItem ? newItem.courseId : '';

        const courseId = type === 'clp_course_extract' ? extractCourseId : id;
        const selectedItem = {
          itemId: id,
          courseId,
          title,
          itemType: type,
          isInPath: true
        };
        state.selectedItem = selectedItem;
      } else {
        const idParsed = type === 'PRACTICE_EXAM' ? 'EXAM_' + id : id;
        const selectedItem = {
          itemId: idParsed,
          title,
          itemType: type,
          isInPath: true
        };
        state.selectedItem = selectedItem;
      }
      state.isSaved = action.isSaved;
      state.isScrollingPathContentToEnd = true;
      return state;
    }
    case POST_ADD_ITEM_TO_PATH: {
      state.isScrollingPathContentToEnd = false;
      return state;
    }
    case UPDATE_COURSE_META_DATA: {
      const { id, type } = action.updatedComponent;
      const courseToUpdate = oldSection.components.findIndex(
        (component) => component.id === id && component.type === type
      );
      const updatedComponents = oldComponents.map((component, index) => {
        if (index === courseToUpdate) {
          return { ...component, ...action.updatedComponent };
        }
        return component;
      });
      state.path.sections[0].components = updatedComponents;
      return state;
    }
    case DELETE_ITEM_FROM_PATH: {
      removeComponentFromPath(action.componentId, action.componentType);
      state.isSaved = action.isSaved;
      return state;
    }
    case SET_PATH_ID: {
      state.path.id = action.pathId;
      if (state.path.id === NEW_PATH_ID) {
        state.isNewPath = true;
      }
      return state;
    }
    case SET_PATH_IS_SAVED: {
      state.isSaved = action.isSaved;
      return state;
    }
    case UPDATE_PATH_TITLE: {
      state.path.title = action.title;
      state.isSaved = action.isSaved;
      return state;
    }
    case UPDATE_PATH_DESCRIPTION: {
      state.path.description = action.description;
      state.isSaved = action.isSaved;
      return state;
    }
    case UPDATE_METADATA: {
      state.path.title = action.title;
      state.path.description = action.description;
      state.isSaved = action.isSaved;
      return state;
    }
    case UPDATE_IS_NEW_PATH: {
      state.isNewPath = action.isNewPath;
      return state;
    }
    case UPDATE_PUBLISHED_AT: {
      state.path.publishedAt = action.publishedAt;
      return state;
    }
    case REORDER_ITEMS: {
      if (state.path.sections.length > 0) {
        const editableSections = cloneDeep(oldPath.sections);
        const [rearrangedItem] = editableSections[0].components.splice(action.startIndex, 1);
        editableSections[0].components.splice(action.endIndex, 0, rearrangedItem);
        state.path.sections = editableSections;
        state.isSaved = false;
      }
      return state;
    }
    case SET_SELECTED_ITEM: {
      if (!action.newItem || action.newItem?.itemId === state.selectedItem?.itemId) {
        state.selectedItem = null;
      } else {
        state.selectedItem = action.newItem;
        const { itemType, courseId } = action.newItem;
        // Algolia returns "COURSE". Use this to indicate selected item is from search panel for now.
        // We also can't change this to lower case as it will cause a bug if the course is already in the
        // path - it could present a course extract as a course in the course overview panel
        if ((itemType as string) === 'COURSE') {
          const isInPath = !!courseId && isComponentIdInPath(state.path, courseId);
          if (isInPath) {
            const components = state.path.sections[0].components;
            const pathItem = components.find((component) => {
              if (component?.type === 'course') {
                return component?.id === courseId;
              }
              if (component?.type === 'clp_course_extract') {
                return component?.courseId === courseId;
              }
              return false;
            });
            // TODO: Need a better fallback
            state.selectedItem.itemType = pathItem?.type ?? itemType;
            state.selectedItem.itemId = pathItem?.id ?? '';
          }
        }
      }
      return state;
    }
    case ADD_COMPONENT: {
      const courseIndex = getCourseIndex(action.courseExtractId, 'clp_course_extract');

      if (courseIndex >= 0) {
        const courseComponent = state.path.sections[0].components[courseIndex] as CourseCLPComponent;
        courseComponent.extractComponentIds?.push(action.componentId);
        courseComponent.computedDuration = calculateComputedDuration(action.duration, courseIndex);
        state.isSaved = false;
      }
      return state;
    }
    case REMOVE_COMPONENT: {
      const courseIndex = getCourseIndex(action.courseExtractId, 'clp_course_extract');
      if (courseIndex >= 0) {
        state.isSaved = false;
        const component = state.path.sections[0].components[courseIndex];
        if (component.type === 'clp_course_extract') {
          const componentIds = component.extractComponentIds;
          if (componentIds?.length === 1 && state.selectedItem) {
            removeComponentFromPath(state.selectedItem?.itemId, 'clp_course_extract');
            state.selectedItem.isInPath = false;
            return state;
          }
          const componentIndex =
            componentIds?.findIndex((componentId: string) => componentId === action.componentId) ?? -1;

          if (componentIndex >= 0 && componentIds) {
            componentIds.splice(componentIndex, 1);
          }
          component.computedDuration = calculateComputedDuration(-action.duration, courseIndex);
        }
      }
      return state;
    }
    case REMOVE_ALL_COURSE_COMPONENTS: {
      const { courseId } = action;
      const courseToUpdateIndex = state.path.sections[0].components.findIndex((component) => {
        if (component.type === 'course') {
          return component?.id === courseId;
        }
        if (component.type === 'clp_course_extract') {
          return component?.courseId === courseId;
        }
        return false;
      });

      const courseToUpdate = state.path.sections[0].components[courseToUpdateIndex] as CourseCLPComponent;

      state.path.sections[0].components[courseToUpdateIndex] = {
        ...courseToUpdate,
        computedDuration: 0,
        extractComponentIds: []
      };

      if (state.selectedItem) state.selectedItem.isInPath = false;
      state.isSaved = false;
      if (courseToUpdate.type === 'course') {
        removeComponentFromPath(courseId, 'course');
      }
      if (courseToUpdate.type === 'clp_course_extract') {
        removeComponentFromPath(courseId, 'clp_course_extract');
      }
      return state;
    }
    case ADD_ALL_COURSE_COMPONENTS: {
      const { courseId } = action;
      const courseToUpdateIndex = state.path.sections[0].components.findIndex((component) => {
        if (component.type === 'course') {
          return component?.id === courseId;
        }
        if (component.type === 'clp_course_extract') {
          return component?.courseId === courseId;
        }
        return false;
      });
      const courseToUpdate = state.path.sections[0].components[courseToUpdateIndex] as CourseCLPComponent;
      const {
        extractComponentIds: _extractComponentIds,
        courseId: _courseId,
        ...restOfCourseToUpdate
      } = courseToUpdate;
      const title = courseToUpdate.title ?? '';
      state.path.sections[0].components[courseToUpdateIndex] = {
        ...restOfCourseToUpdate,
        __typename: 'CustomLearningPathACGCourseComponent',
        id: courseId,
        computedDuration: action.totalCourseDuration ?? 0,
        type: 'course'
      };

      const selectedItem = {
        itemId: courseId,
        courseId,
        title,
        itemType: 'course' as ComponentType,
        isInPath: true
      };
      state.selectedItem = selectedItem;
      state.isSaved = false;
      return state;
    }
    case ADD_CHAPTER_COMPONENTS: {
      const { courseExtractId, extractComponentIds, componentsIdAndDuration } = action;
      const courseComponents = (state.path.sections[0].components as unknown) as CourseCLPComponent[];
      const pathItem = courseComponents.find((component) => component?.id === courseExtractId);
      if (pathItem) {
        const currentExtractComponentIds = pathItem.extractComponentIds ?? [];
        const nextExtractComponentIds = [...currentExtractComponentIds, ...extractComponentIds];
        // Unique to account for when an admin selects a chapter and the chapter has 1 or more lessons already selected
        const nextUniqueExtractComponentIds = Array.from(new Set(nextExtractComponentIds));
        pathItem.extractComponentIds = nextUniqueExtractComponentIds;

        const currentComputedDuration = pathItem.computedDuration ?? 0;
        const currentExtractComponentIdsSet = new Set(currentExtractComponentIds);
        const addedComponentsDuration = componentsIdAndDuration.reduce((totalDuration, component) => {
          const { id, duration } = component;
          if (!currentExtractComponentIdsSet.has(id)) {
            return totalDuration + duration;
          }
          return totalDuration;
        }, 0);
        pathItem.computedDuration = currentComputedDuration + addedComponentsDuration;
      }
      state.isSaved = false;
      return state;
    }
    case REMOVE_CHAPTER_COMPONENTS: {
      const { courseExtractId, extractComponentIds, componentsIdAndDuration } = action;
      const courseComponents = (state.path.sections[0].components as unknown) as CourseCLPComponent[];

      const pathItem = courseComponents.find((component) => component?.id === courseExtractId);
      if (pathItem) {
        state.isSaved = false;

        const currentExtractComponentIds = pathItem.extractComponentIds ?? [];
        const extractComponentIdsSet = new Set(extractComponentIds);
        const nextExtractComponentIds = currentExtractComponentIds.filter(
          (componentId: string) => !extractComponentIdsSet.has(componentId)
        );
        // Unique to remove duplicates (unlikely)
        const nextUniqueExtractComponentIds = Array.from(new Set(nextExtractComponentIds));
        pathItem.extractComponentIds = nextUniqueExtractComponentIds;
        if (nextUniqueExtractComponentIds.length === 0 && state.selectedItem) {
          removeComponentFromPath(state.selectedItem.itemId, 'clp_course_extract');
          state.selectedItem.isInPath = false;
          return state;
        }

        const currentComputedDuration = pathItem.computedDuration ?? 0;
        const currentExtractComponentIdsSet = new Set(currentExtractComponentIds);
        const removedComponentsDuration = componentsIdAndDuration.reduce((totalDuration, component) => {
          const { id, duration } = component;
          if (currentExtractComponentIdsSet.has(id)) {
            return totalDuration + duration;
          }
          return totalDuration;
        }, 0);
        pathItem.computedDuration = currentComputedDuration - removedComponentsDuration;
      }
      return state;
    }
    case CONVERT_TO_COURSE_EXTRACT: {
      if (state.selectedItem) {
        const courseId = state.selectedItem.itemId;
        const courseIndex = getCourseIndex(courseId, 'course');

        const extractComponentIds = action.courseOverview.sections.reduce((allExtractedIds: string[], section) => {
          const sectionIds = section.components.reduce((componentIds: string[], component) => {
            componentIds.push(component.id);
            return componentIds;
          }, []);
          return allExtractedIds.concat(sectionIds);
        }, []);

        if (state.selectedItem?.itemType === 'course') {
          const courseExtract = state.path.sections[0].components[courseIndex] as CourseCLPComponent;
          state.path.sections[0].components[courseIndex] = {
            ...courseExtract,
            __typename: 'CustomLearningPathCourseExtractComponent',
            id: action.courseExtractId,
            courseId,
            type: 'clp_course_extract',
            extractComponentIds: extractComponentIds
          };
          state.selectedItem.itemId = action.courseExtractId;
          state.selectedItem.itemType = 'clp_course_extract';
        }
      }
      state.isSaved = false;
      return state;
    }

    case CONVERT_TO_COURSE: {
      if (state.selectedItem && state.selectedItem.courseId) {
        const courseId = state.selectedItem.courseId;
        const courseExtractId = state.selectedItem.itemId;

        const courseIndex = getCourseIndex(courseExtractId, 'clp_course_extract');

        const courseToUpdate = state.path.sections[0].components[courseIndex] as CourseCLPComponent;
        const {
          extractComponentIds: _extractComponentIds,
          courseId: _courseId,
          ...restOfCourseToUpdate
        } = courseToUpdate;
        state.path.sections[0].components[courseIndex] = {
          ...restOfCourseToUpdate,
          __typename: 'CustomLearningPathACGCourseComponent',
          id: courseId,
          type: 'course',
          computedDuration: action.totalCourseDuration ?? 0
        };
        state.selectedItem.itemType = 'course';
        state.selectedItem.itemId = courseId ?? '';
      }
      state.isSaved = false;

      return state;
    }
    default:
      return state;
  }
};

const PathProvider: React.FC<PathStateProps> = ({ children, initialState }) => {
  const initState = initialState ?? defaultState;

  /* useImmerReducer is a custom hook that allows us to update our state with ease + maintaining immutability.
   see docs for more info: https://immerjs.github.io/immer/example-setstate#useimmerreducer */
  const [state, dispatch] = useImmerReducer(pathReducer, initState);

  return <PathContext.Provider value={{ state: { ...state }, dispatch }}>{children}</PathContext.Provider>;
};

export { PathProvider, pathReducer, defaultState };

const quickSearchItems = [
  {
    title: 'AWS',
    primaryImageUrl:
      'https://res.cloudinary.com/acloud-guru/image/fetch/q_auto/https%3A%2F%2Facloudguru-content-assets-production.s3-accelerate.amazonaws.com%2F1573101332463-icon-aws.png',
    secondaryImageUrl:
      'https://res.cloudinary.com/acloud-guru/image/fetch/h_115,q_auto/https%3A%2F%2Facloudguru-content-assets-production.s3-accelerate.amazonaws.com%2F1593571698131-instructors-aws-lossless.png',
    queryText: 'AWS'
  },
  {
    title: 'Microsoft Azure',
    primaryImageUrl:
      'https://res.cloudinary.com/acloud-guru/image/fetch/q_auto/https%3A%2F%2Facloudguru-content-assets-production.s3-accelerate.amazonaws.com%2F1573101349734-icon-azure.png',
    secondaryImageUrl:
      'https://res.cloudinary.com/acloud-guru/image/fetch/h_115,q_auto/https%3A%2F%2Facloudguru-content-assets-production.s3-accelerate.amazonaws.com%2F1593571720937-instructors-azure-lossless.png',
    queryText: 'Azure'
  },
  {
    title: 'Google Cloud',
    primaryImageUrl:
      'https://res.cloudinary.com/acloud-guru/image/fetch/q_auto/https%3A%2F%2Facloudguru-content-assets-production.s3-accelerate.amazonaws.com%2F1573101362034-icon-gcp.png',
    secondaryImageUrl:
      'https://res.cloudinary.com/acloud-guru/image/fetch/h_115,q_auto/https%3A%2F%2Facloudguru-content-assets-production.s3-accelerate.amazonaws.com%2F1593571738393-instructors-gcp-lossless.png',
    queryText: 'GCP'
  },
  {
    title: 'Linux',
    primaryImageUrl:
      'https://res.cloudinary.com/acloud-guru/image/fetch/q_auto/https%3A%2F%2Facloudguru-content-assets-production.s3-accelerate.amazonaws.com%2F1573101413311-icon-linux.png',
    secondaryImageUrl:
      'https://res.cloudinary.com/acloud-guru/image/fetch/h_115,q_auto/https%3A%2F%2Facloudguru-content-assets-production.s3-accelerate.amazonaws.com%2F1593571756017-instructors-linux-lossless.png',
    queryText: 'Linux'
  },
  {
    title: 'Containers',
    primaryImageUrl:
      'https://res.cloudinary.com/acloud-guru/image/fetch/q_auto/https%3A%2F%2Facloudguru-content-assets-production.s3-accelerate.amazonaws.com%2F1592974125578-icon-containers.png',
    secondaryImageUrl:
      'https://res.cloudinary.com/acloud-guru/image/fetch/h_115,q_auto/https%3A%2F%2Facloudguru-content-assets-production.s3-accelerate.amazonaws.com%2F1593571779236-instructors-containers-lossless.png',
    queryText: 'Containers'
  }
];

export { quickSearchItems };

import { useEffect } from 'react';
import { message } from '@a-cloud-guru/rainbow-ui';
import { getConfig } from 'config';
import { useGetLab } from 'hooks';

import { DetailsContainer } from './DetailsContainer';
import { LabMastHead } from './LabMastHead';
import { LabMetaData } from './LabMetaData';
import { ExternalLinkComponent } from './ExternalLinkComponent';
import { LabObjectives } from './LabObjectives';
const { ACG_LEARN_URL } = getConfig();
const errorMessage = 'Failed to fetch hands-on lab details. Please try again.';

interface LabDetailsProps {
  labId?: string;
}
const LabDetails: React.FC<LabDetailsProps> = ({ labId }) => {
  const { lab, error, loading } = useGetLab({ labId });
  const hasError = !loading && (!lab || Object.keys(lab).length === 0 || !labId || error);
  useEffect(() => {
    if (hasError) {
      message.error(errorMessage);
    }
  }, [error, hasError]);
  const labLandingUrl = `${ACG_LEARN_URL}/handson/${labId}`;

  return (
    <>
      <LabMastHead />
      <DetailsContainer>
        {hasError ? (
          <div>{errorMessage}</div>
        ) : (
          <>
            <LabMetaData loading={loading} lab={lab} />
            <ExternalLinkComponent linkText="View Lab" landingUrl={labLandingUrl} loading={loading} />
            <LabObjectives loading={loading} tasks={lab.tasks} />
          </>
        )}
      </DetailsContainer>
    </>
  );
};

export { LabDetails };

import { makeBuildOptimizedSrc } from 'components/common/image';
import { colours } from '@a-cloud-guru/rainbow-ui';

const contentTypes = {
  HANDS_ON_LAB: 'hands-on-lab',
  QUIZ: 'quiz',
  COURSE_VIDEO: 'video',
  PRACTICE_EXAM: 'practiceExam',
  EPISODE: 'episode'
};

const tagDisplayMap = {
  [contentTypes.HANDS_ON_LAB]: 'Hands-on Lab',
  [contentTypes.PRACTICE_EXAM]: 'Practice Exam',
  [contentTypes.QUIZ]: 'Quiz'
};

// constants for lab and practice exam masthead components
const mastHeadWallpaperUrl =
  'https://acloudguru-content-assets-production.s3-accelerate.amazonaws.com/1604294943760-wallpaper-17-srgb.jpg';
const mastHeadWallpaperDesktopSrc = makeBuildOptimizedSrc({ width: '1000', format: 'auto' })(mastHeadWallpaperUrl);
const mastHeadWallpaperMobileSrc = makeBuildOptimizedSrc({ width: '800', crop: 'fill', format: 'auto' })(
  mastHeadWallpaperUrl
);
const mastHeadBackgroundColour = colours.navy800;

export {
  contentTypes,
  tagDisplayMap,
  mastHeadWallpaperDesktopSrc,
  mastHeadWallpaperMobileSrc,
  mastHeadBackgroundColour
};

import { isBefore, addSeconds } from 'date-fns';
import { LocalStorageConfig } from 'types/common';
// this helper function is copied from frontends/learn/src/lib/local-storage/index.js

interface LocalStorage {
  get: (key: string) => any;
  set: (key: string, data: any) => void;
}
const makeLocalStorage = (configs: LocalStorageConfig): LocalStorage => {
  const { ttl } = configs;
  const now = new Date();
  return {
    get: (key: string) => {
      try {
        const itemStr = localStorage.getItem(key);
        if (!itemStr) {
          return undefined;
        }
        const item = JSON.parse(itemStr);
        if (item.expireAt && isBefore(new Date(item.expireAt), now)) {
          localStorage.removeItem(key);
          return undefined;
        }
        return item.data;
      } catch {
        return undefined;
      }
    },
    set: (key: string, data: any) => {
      try {
        localStorage.setItem(
          key,
          JSON.stringify({
            data,
            expireAt: ttl ? addSeconds(now, ttl) : undefined
          })
        );
      } catch {
        return undefined;
      }
    }
  };
};

export { makeLocalStorage };

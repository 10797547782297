import { ApolloError, useMutation } from '@apollo/client';
import cloneDeep from 'lodash/cloneDeep';
import { useHistory } from 'react-router-dom';

import { SET_PATH_IS_SAVED, UPDATE_IS_NEW_PATH, SET_PATH_ID, usePathContext } from '../context/PathContext';
import { CUSTOM_LEARNING_PATH_SAVE } from '../data/learningPathMutations';
import { stripSectionsForUpdate } from 'utils';
import { useCreatePath } from './use-create-path';
import { CustomLearningPath } from 'types';
import { trace } from 'services/analytics';

interface UseSavePath {
  // Optional title and description to force saving of title and description.
  // Fixes bug where updating the cache (e.g., using useUpdateTitle hook) does not
  // update the title from PathContext
  savePath: (title?: string, description?: string) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

const useSavePath = (): UseSavePath => {
  const {
    state: { path },
    dispatch
  } = usePathContext();
  const [updatePathMutation, { loading, error }] = useMutation(CUSTOM_LEARNING_PATH_SAVE);

  const savePath = async (title?: string, description?: string) => {
    const { sections } = path;

    await updatePathMutation({
      variables: {
        id: path.id,
        input: {
          sections: stripSectionsForUpdate(cloneDeep(sections)),
          description: description ?? path.description,
          title: title ?? path.title
        }
      },
      update: () => {
        dispatch({
          type: SET_PATH_IS_SAVED,
          isSaved: true
        });
      }
    });
  };

  return { savePath, loading, error };
};

interface UseSaveNewPath {
  saveNewPath: (path: CustomLearningPath) => Promise<void>;
  loading: boolean;
  error: ApolloError | undefined;
}

const useSaveNewPath = (): UseSaveNewPath => {
  const {
    createPath,
    results: { loading, error }
  } = useCreatePath();
  const { dispatch } = usePathContext();
  const history = useHistory();

  const saveNewPath = async (path: CustomLearningPath) => {
    const { title, description, sections } = path;

    const newPath = await createPath({
      title,
      description,
      sections: stripSectionsForUpdate(cloneDeep(sections))
    });
    dispatch({
      type: SET_PATH_IS_SAVED,
      isSaved: true
    });

    dispatch({
      type: UPDATE_IS_NEW_PATH,
      isNewPath: false
    });

    if (newPath) {
      const pathId = newPath.data?.customLearningPathCreate.id;
      dispatch({ type: SET_PATH_ID, pathId: pathId });

      trace.track('Create Path', { pathId: pathId });
      history.push(`/path-builder/${pathId}`);
    }
  };

  return { saveNewPath, loading, error };
};
export { useSavePath, useSaveNewPath };

import React, { useEffect } from 'react';
import qs from 'qs';

import { trace } from 'services/analytics';
import { authService } from 'services/auth';

const LoginPage: React.FC = () => {
  const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  useEffect(() => trace.view('Login'), []);

  useEffect(() => {
    authService.login({
      state: authService.encodeState(queryString)
    });
  }, [queryString]);

  return null;
};

export { LoginPage };

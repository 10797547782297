export default {
  AUTH0_API_AUDIENCE: 'https://staging-acloudguru.auth0.com/api/v2/',
  AUTH0_CLIENT_ID: 'fljNY6H0JVMd41gR0o7ATdSiETUD78rh',
  AUTH0_CUSTOM_DOMAIN: 'staging-auth.acloud.guru',
  AUTH0_DOMAIN: 'staging-acloudguru.auth0.com',

  ACG_DOMAIN: 'https://mario-learn.acloud.guru',
  ACG_LEARN_URL: 'https://mario-learn.acloud.guru',
  ACG_BUSINESS_URL: 'https://mario-business.acloud.guru',
  ACG_PRACTICE_EXAM_DOMAIN: 'https://staging-practice-exam.acloud.guru',

  PRIVATE_GLOBAL_SEARCH_ALGOLIA_INDEX: 'private_test_global_search',
  ORG_USERS_ALGOLIA_INDEX: 'private_mario_all_organisation_users',

  GRAPHQL_API_ENDPOINT: 'https://mario-api.acloud.guru/bff/graphql',
  RELEASE_STAGE: 'mario',
  PRINT_TRACE_LOGS: true,
  SEGMENT_API_KEY: 'fake-key-no-mario-segment'
};

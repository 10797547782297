import styled from 'styled-components';
import { Box, colours } from '@a-cloud-guru/rainbow-ui';

interface CharacterLimitTextProps {
  readonly textEntityType?: string;
  readonly maxLength: number;
  readonly length: number;
  readonly warningLimit: number;
  readonly isOverLimit: boolean;
}

type GetRemainingCharactersAndCaptionProps = Pick<
  CharacterLimitTextProps,
  'maxLength' | 'length' | 'isOverLimit' | 'textEntityType'
>;

const getRemainingCharactersAndCaption = (props: GetRemainingCharactersAndCaptionProps): [number, string] => {
  const { maxLength, length, isOverLimit, textEntityType } = props;
  const remainingCharacters = maxLength - length;
  const caption = isOverLimit
    ? `${textEntityType} too long, remove ${-remainingCharacters} character${
        remainingCharacters === -1 ? '' : 's'
      } to save`
    : `${remainingCharacters} character${remainingCharacters === 1 ? '' : 's'} remaining`;
  return [remainingCharacters, caption];
};

const CharacterLimitText: React.FC<CharacterLimitTextProps> = ({
  textEntityType = 'Input',
  maxLength,
  length,
  warningLimit,
  isOverLimit
}) => {
  const [remainingCharacters, caption] = getRemainingCharactersAndCaption({
    maxLength,
    length,
    isOverLimit,
    textEntityType
  });
  const warning = remainingCharacters <= warningLimit;

  const captionColor = (isOverLimit && colours.red900) || (warning && colours.orange900) || colours.darkGrey800;
  return <LimitText color={captionColor}>{caption}</LimitText>;
};

const LimitText = styled(Box)`
  font-weight: 600;
  font-size: 12px;
  color: ${({ color }) => color};
`;

export { CharacterLimitText, getRemainingCharactersAndCaption };

import React from 'react';
import { ExternalLinkContainer, ExternalLink, ExternalLinkText, ExternalLinkIcon } from './DetailsContainer';
import { Skeleton } from 'components/common';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { RulerLine } from 'components/common';

interface ExternalLinkProps {
  landingUrl: string;
  linkText: string;
  loading: boolean;
}
const ExternalLinkComponent: React.FC<ExternalLinkProps> = ({ landingUrl, linkText, loading }) => {
  return (
    <>
      {loading ? (
        <Skeleton width="20%" bgColor={colours.darkGrey400} my="s2" />
      ) : (
        <>
          <RulerLine colour={colours.navy200} />
          <ExternalLinkContainer>
            <ExternalLink href={landingUrl} target="_blank" rel="noreferrer">
              <ExternalLinkText>{linkText}</ExternalLinkText>
              <ExternalLinkIcon role="img" />
            </ExternalLink>
          </ExternalLinkContainer>
        </>
      )}
    </>
  );
};

export { ExternalLinkComponent };

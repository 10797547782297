import { FetchResult, useMutation } from '@apollo/client';

import { DELETE_CUSTOM_LEARNING_PATH_MUTATION } from 'data';

// TODO: Refactor with GQL CodeGen
const useDeletePath = (): ((
  learningPathId: string
) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>) => {
  const [deletePath] = useMutation(DELETE_CUSTOM_LEARNING_PATH_MUTATION);

  return (learningPathId: string) =>
    deletePath({
      variables: {
        id: learningPathId
      }
    });
};

export { useDeletePath };

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, colours, message } from '@a-cloud-guru/rainbow-ui';
import { trace } from 'services/analytics';
import { Skeleton, SKILL_LEVEL_ICONS, SKILL_LEVEL_MAP } from 'components/common';
import { useIsComponentIdInPath, useTotalDuration } from 'hooks';
import { formatVendorList, getCourseLessonDisplay, getCourseExtractLessonDisplay, getDurationDisplay } from 'utils';
import { PROVIDER_LOOKUP } from '../../constant';
import { Section } from 'types/learning-path/Section';
import { CourseCLPComponent, CourseOverview } from 'types';
import { MetaDataContainer, StyledDescription, MetaDataList, MetaDataListItem, SummaryText } from './DetailsContainer';

import {
  UPDATE_COURSE_META_DATA,
  ADD_ITEM_TO_PATH,
  REMOVE_ALL_COURSE_COMPONENTS,
  ADD_ALL_COURSE_COMPONENTS,
  usePathContext
} from 'context/PathContext';
import { UpdatedComponent } from 'types/common';

import { ReactComponent as ProviderSvg } from 'static/images/svg-icons/icon-provider.svg';
import { ReactComponent as LessonSvg } from 'static/images/svg-icons/lesson.svg';
import { ReactComponent as FilterSvg } from 'static/images/svg-icons/filter.svg';
import { ReactComponent as DurationIcon } from 'static/images/svg-icons/icon-duration.svg';

const MetadataSkeletons = () => (
  <MetaDataList>
    <Skeleton mr="s3" />
    <Skeleton width="100px" mr="s3" />
    <Skeleton width="80px" mr="s3" />
    <Skeleton width="80px" mr="s3" />
  </MetaDataList>
);

// TODO: This limit is a temporary workaround to prevent the timeout on course service.
// After the issue has been resolved by student team we should change it to some higher value
const COURSE_LIMIT = 15;
const LIMIT_MESSAGE_TIMEOUT = 10; // seconds

interface CourseMetaDataProps {
  loading?: boolean;
  courseOverview: CourseOverview;
}

const CourseMetaData: React.FC<CourseMetaDataProps> = ({ loading, courseOverview }) => {
  const [selectAllButtonType, setSelectAllButtonType] = useState<'default' | 'primary'>('default');
  const isIdComponentIdInPath = useIsComponentIdInPath();
  const {
    state: {
      path: { id: pathId, sections },
      selectedItem
    },
    dispatch
  } = usePathContext();
  const itemType = selectedItem?.itemType ?? 'course';

  const courseId = courseOverview?.id;
  const isInPath = !!courseId && isIdComponentIdInPath(courseId);

  const courseComponents =
    sections.length > 0
      ? ((sections[0].components.filter(
          (component) => component.type === 'clp_course_extract' || component.type === 'course'
        ) as unknown) as CourseCLPComponent[])
      : [];

  const courseCount = useMemo(
    () =>
      sections.reduce((totalCount: number, section: Section) => {
        const courseComponents = section.components.filter(
          (component) => component.type === 'clp_course_extract' || component.type === 'course'
        );
        return totalCount + courseComponents.length;
      }, 0),
    [sections]
  );
  const skillLevel =
    courseOverview?.skillLevels.length > 0 ? SKILL_LEVEL_MAP[courseOverview.skillLevels[0].toLowerCase()] : '';
  const vendor = useMemo(() => courseOverview?.vendors[0]?.name || '', [courseOverview]);
  const numberOfLessons = courseOverview?.numberOfLessons;
  const shortSummary = courseOverview?.shortSummary;
  const duration = useTotalDuration();
  // calculate lesson count and duration
  const findComponentIndex =
    sections.length > 0
      ? courseComponents.findIndex((component) => {
          if (component.type === 'course' && component.id === courseId) {
            return component;
          } else if (component.type === 'clp_course_extract' && component.courseId === courseId) {
            return component;
          }
        })
      : -1;
  const numExtractComponentIds =
    findComponentIndex >= 0 ? courseComponents[findComponentIndex].extractComponentIds?.length : -1;

  const numberOfSelectedLessons = findComponentIndex >= 0 ? numExtractComponentIds : 0;
  const isCourseExtract = selectedItem?.itemType === 'clp_course_extract';
  const lessonsDisplay = !isCourseExtract
    ? getCourseLessonDisplay(numberOfLessons)
    : getCourseExtractLessonDisplay(numberOfLessons, numberOfSelectedLessons);

  const getDuration =
    findComponentIndex >= 0
      ? courseComponents[findComponentIndex].computedDuration
      : courseOverview && courseOverview.computedDuration;
  const computedDuration = getDuration !== null ? getDurationDisplay(getDuration) : null;

  useEffect(() => {
    if (courseOverview && isInPath) {
      const type = itemType === 'course' || itemType === 'clp_course_extract' ? itemType : 'course';
      const updatedComponent: UpdatedComponent = {
        id: courseOverview.id,
        type,
        title: courseOverview.title,
        numberOfLessons: courseOverview.numberOfLessons,
        computedDuration: courseOverview.computedDuration,
        artworkUrl: courseOverview.artworkUrl,
        skillLevels: courseOverview.skillLevels ?? [],
        vendors: [formatVendorList(courseOverview.vendors)]
      };
      dispatch({
        type: UPDATE_COURSE_META_DATA,
        updatedComponent: updatedComponent
      });
    }
  }, [isInPath, courseOverview, itemType, dispatch]);

  useEffect(() => {
    if (itemType === 'clp_course_extract' && numExtractComponentIds === 0) {
      setSelectAllButtonType('primary');
    } else if (itemType === 'clp_course_extract' && numExtractComponentIds && numExtractComponentIds >= 0) {
      setSelectAllButtonType('default');
    }
  }, [numExtractComponentIds, itemType]);

  const addToPathHandler = useCallback(() => {
    if (!loading && courseOverview) {
      if (courseCount >= COURSE_LIMIT) {
        const getHours = (seconds: number) => Math.floor(seconds / 3600);
        message.warning(
          `Path duration is over ${getHours(duration)} hours and contains the maximum ${COURSE_LIMIT} courses.`,
          LIMIT_MESSAGE_TIMEOUT
        );
        return;
      }

      const newItem: CourseCLPComponent = {
        id: courseOverview.id,
        courseId: courseOverview.id,
        type: 'course',
        title: courseOverview.title,
        numberOfLessons: courseOverview.numberOfLessons,
        computedDuration: courseOverview.computedDuration,
        artworkUrl: courseOverview.artworkUrl,
        skillLevels: courseOverview.skillLevels ?? [],
        vendors: [formatVendorList(courseOverview.vendors)]
      };
      const courseId = newItem.id;
      const courseType = newItem.type;

      trace.track('Item added to Path', { pathId, courseId, courseType });

      dispatch({
        type: ADD_ITEM_TO_PATH,
        newItem: newItem,
        isSaved: false
      });
    } else {
      message.error('Unable to add item to path');
    }
  }, [loading, pathId, courseCount, courseOverview, duration, dispatch]);

  type SelectUnselectAllCourseComponentsButtonProps = {
    isInPath: boolean;
  };

  const SelectUnselectAllCourseComponentsButton: React.FC<SelectUnselectAllCourseComponentsButtonProps> = ({
    isInPath
  }) => {
    const unselectAllCourseComponents = useCallback(() => {
      trace.track('Unselected all course components', { pathId, courseId });
      dispatch({ type: REMOVE_ALL_COURSE_COMPONENTS, courseId });
    }, []);
    const selectAllCourseComponents = useCallback(() => {
      trace.track('Selected all course components', { pathId, courseId });
      dispatch({ type: ADD_ALL_COURSE_COMPONENTS, courseId, totalCourseDuration: courseOverview.computedDuration });
    }, []);
    if (!isInPath) {
      return (
        <StyledButton onClick={addToPathHandler} type="primary" data-cy="pb-add-course-button">
          Select All
        </StyledButton>
      );
    }
    if (itemType === 'course') {
      return (
        <StyledButton onClick={unselectAllCourseComponents} type="default">
          Unselect All
        </StyledButton>
      );
    }
    if (itemType === 'clp_course_extract') {
      return (
        <StyledButton onClick={selectAllCourseComponents} type={selectAllButtonType}>
          Select All
        </StyledButton>
      );
    }
    return null;
  };

  const provider = PROVIDER_LOOKUP[vendor];
  const MetadataTiles = () => (
    <MetaDataList>
      {provider && (
        <MetaDataListItem iconComponent={<ProviderSvg />} description="Provider">
          {provider}
        </MetaDataListItem>
      )}
      {skillLevel && (
        <MetaDataListItem iconComponent={SKILL_LEVEL_ICONS[skillLevel.toLowerCase()]} description="Skill level">
          <StyledDescription>{skillLevel}</StyledDescription>
        </MetaDataListItem>
      )}
      <MetaDataListItem iconComponent={isCourseExtract ? <FilterSvg /> : <LessonSvg />} description="Number of lessons">
        {lessonsDisplay}
      </MetaDataListItem>
      {computedDuration && (
        <MetaDataListItem iconComponent={<DurationIcon />} description="Duration">
          {computedDuration}
        </MetaDataListItem>
      )}
    </MetaDataList>
  );

  return (
    <MetaDataContainer>
      {loading ? (
        <Skeleton height="36px" width="140px" bgColor={colours.darkGrey400} />
      ) : courseOverview ? (
        <SelectUnselectAllCourseComponentsButton isInPath={isInPath} />
      ) : null}
      {loading ? <MetadataSkeletons /> : <MetadataTiles />}
      {loading ? (
        <>
          <Skeleton width="100%" bgColor={colours.darkGrey400} mb="s3" />
          <Skeleton width="30%" bgColor={colours.darkGrey400} mb="s4" />
        </>
      ) : (
        <SummaryText>{shortSummary}</SummaryText>
      )}
    </MetaDataContainer>
  );
};

const StyledButton = styled(Button)`
  display: flex;
  font-weight: 600;
  align-items: center;
`;

export { CourseMetaData, formatVendorList };

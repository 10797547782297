import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { AuthProvider } from '@a-cloud-guru/react-auth';
import { ConfigProvider } from '@a-cloud-guru/rainbow-ui';

import { authService, withAuthorization } from 'services/auth';
import { LoginCallbackPage, LoginPage } from 'pages';
import { PathProvider } from 'context/PathContext';
import { Root } from 'components';
import { SearchQueryProvider } from 'context/SearchQueryContext';
import apolloService from 'services/apollo';
import { SplitIOProvider } from 'services/splitio';
import { SegmentSnippet } from 'services/analytics';
import { GlobalStyles } from 'styles/global';
import { NEW_PATH_ID } from 'constant';
import { TempBannerProvider } from 'components/search-panel/TemporaryBanner/TemporaryBannerContext';

const App: React.FC = () => {
  const apolloClient = apolloService.init();

  /**
   * We have to cast authService as any because there is a mismatch of types between the two libraries:
   *
   * @a-cloud-guru/react-auth -> viewer.roles = {
   *  admin: boolean
   *  organisationAdmin: boolean
   *  editor: boolean
   *  instructor: boolean
   * }
   *
   * @a-cloud-guru/auth0-wrapper -> viewer.roles = {
   *  admin?: boolean;
   *  organisationAdmin?: boolean;
   *  editor?: boolean;
   * }
   */

  const authClient = authService as any;

  return (
    <AuthProvider client={authClient}>
      <SegmentSnippet />
      <ConfigProvider>
        <ApolloProvider client={apolloClient}>
          <SplitIOProvider>
            <Router>
              <PathProvider>
                <TempBannerProvider>
                  <SearchQueryProvider>
                    <Switch>
                      <Route exact path="/" render={() => <Redirect to={`/path-builder/${NEW_PATH_ID}`} />} />
                      <Route
                        exact
                        path="/path-builder"
                        render={() => <Redirect to={`/path-builder/${NEW_PATH_ID}`} />}
                      />
                      <Route exact path="/path-builder/:pathId" component={withAuthorization(Root)} />
                      <Route exact path="/login" component={LoginPage} />
                      <Route exact path="/login/callback" component={LoginCallbackPage} />
                    </Switch>
                  </SearchQueryProvider>
                </TempBannerProvider>
              </PathProvider>
            </Router>
          </SplitIOProvider>
        </ApolloProvider>
      </ConfigProvider>
      <GlobalStyles />
    </AuthProvider>
  );
};

export default App;

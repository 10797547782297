import React from 'react';
import { StandalonePathComponent } from './StandalonePathComponent';
import { ReactComponent as SearchLabsIcon } from 'static/images/svg-icons/search-labs.svg';
import { LabComponent } from 'types';

interface LabPathComponentProps {
  index: number;
  labComponent: LabComponent;
  deleteItemFromPath: (componentId: string) => void;
  isDragging: boolean;
  isItemSelected: boolean;
  onClick: () => void;
}

const LabPathComponent: React.FC<LabPathComponentProps> = ({
  index,
  labComponent,
  deleteItemFromPath,
  isDragging,
  isItemSelected,
  onClick
}) => {
  const {
    lab: { id, name, difficulty = '', averageCompletionTime, contentTags }
  } = labComponent;
  const { tags = [] } = contentTags || {};
  const tag = tags.find((tag) => tag.assignedTags);
  const provider = tag ? tag.assignedTags.find((tag) => tag.categoryName.toLowerCase() === 'vendor')?.tagNames[0] : '';
  return (
    <StandalonePathComponent
      index={index}
      Icon={SearchLabsIcon}
      id={id}
      name={name}
      difficulty={difficulty}
      provider={provider}
      duration={averageCompletionTime}
      deleteItemFromPath={deleteItemFromPath}
      isDragging={isDragging}
      isItemSelected={isItemSelected}
      onClick={onClick}
      type="lab"
    />
  );
};

export { LabPathComponent };

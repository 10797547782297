import { useEffect } from 'react';
import { useAuth } from '@a-cloud-guru/react-auth';
import urlJoin from 'url-join';

import { Loading } from 'components/common';
import { getConfig } from 'config';
import { trace } from '../analytics';
import { isOrgAdmin } from 'utils';
import { Viewer } from 'types';
import { useFeatureAccess } from 'hooks';

const { ACG_BUSINESS_URL } = getConfig();

const oopsUrl = urlJoin(ACG_BUSINESS_URL, 'oops');

const withAuthorization = (ComposedAuthComponent: React.FC): (() => JSX.Element) => {
  return () => {
    const { loading: authLoading, viewer, error } = useAuth();
    const customLearningPathAccess = useFeatureAccess('customLearningPaths');

    useEffect(() => {
      const userId = viewer?.id;
      const organisationId = viewer?.organisationId;
      if (userId) trace.identify({ userId, organisationId });
    }, [viewer]);

    if (authLoading) {
      return <Loading />;
    }

    if (error) {
      const currentUrl = window.location.href;
      const loginUrl = urlJoin(ACG_BUSINESS_URL, 'login', `?redirect_to=${encodeURIComponent(currentUrl)}`);

      window.location.replace(loginUrl);

      return <Loading />;
    }

    if (!viewer) {
      return <Loading />;
    }

    if (customLearningPathAccess.loading) {
      return <Loading />;
    }

    if (!customLearningPathAccess.hasAccess) {
      window.location.replace(oopsUrl);
      return <Loading />;
    }

    /**
     * The type hinting in react-auth is inaccurate and they are not exported for us to extend.
     * Casting to unknown and then to Viewer tells the compiler we know about this issue until it's resolved within @a-cloud-guru/react-auth
     */
    if (!isOrgAdmin((viewer as unknown) as Viewer)) {
      window.location.replace(oopsUrl);
      return <Loading />;
    }

    return <ComposedAuthComponent />;
  };
};

export { withAuthorization };

import { getConfig } from 'config';
import { bugsnagService } from './bugsnag';
import * as segmentService from './segment';

const { PRINT_TRACE_LOGS } = getConfig();
const site = 'Path Builder';

interface UserTrace {
  userId: string;
}
type DynamicTrace = Record<string, string | number | boolean>;

/**
 * Track is any custom event we want to track.
 * The `data` attribute takes in a free-form JSON payload that gets piped to various downstream services.
 * Track can be used to track any user action. For example: button clicks, drag & drops, user idle threshold is crossed etc.
 * @param {String} [content] Type, message or ID of event
 * @param {Object} [data] Key-value object that contains event meta that we want to track.
 * Sample usage:
 * import trace from 'services/analytics';
 * trace.track('CLP created', { name: 'Awesome AWS Course' })
 * trace.track('Course added', { path: 'Awesome AWS Course', course: 'AWS Lambda'  })
 * trace.track('Course removed', { path: 'Awesome AWS Course', course: 'AWS Lambda'  })
 * trace.track('Idle user', { message: 'User has been idle for 30mins' })
 */
const track = (content: string, data: DynamicTrace): void => {
  segmentService.sendTrack(`${site} | ${content} [track]`, { site, ...data });
  bugsnagService.leaveBreadcrumb(content, data);

  if (PRINT_TRACE_LOGS) console.log(`${content} [track]`, data);
};

/**
 * Identify is used when a new user is identified in a session.
 * @param {Object} [data] Key-value object that contains event meta that we want to track.
 * Sample usage:
 * import trace from 'services/analytics';
 * trace.identify({ userId: 'auth0-12345' })
 */
const identify = (data: UserTrace & DynamicTrace): void => {
  const { userId, ...restOfData } = data;
  segmentService.sendIdentify(userId, { site, ...restOfData });
  bugsnagService.identifyUser(userId);

  if (PRINT_TRACE_LOGS) console.log('Auth0 [identify]', data);
};

/**
 * View is used to capture a pageview since single page applications do not have route changes.
 * This can (and should) be used in a useEffect(() => trace.view('..'), []) at the top of a page component.
 * @param {String} [content] Type, message or ID of event
 * Sample usage:
 * import trace from 'services/analytics';
 * trace.view('Home')
 */
const view = (content: string): void => {
  segmentService.sendCurrentPage(`${site} | ${content}`, { site });

  if (PRINT_TRACE_LOGS) console.log(`${content} [view]`);
};

export const trace = { identify, track, view };

import React from 'react';
import styled from 'styled-components';
import { colours, Box } from '@a-cloud-guru/rainbow-ui';
import { Skeleton } from 'components/common';
import { AssessmentsQuestionPool } from 'types';

import { HeadingTitle } from './DetailsContainer';

interface PracticeExamDomainBreakdownProps {
  questionPool: Array<AssessmentsQuestionPool>;
  loading: boolean;
}
const PracticeExamDomainBreakdown: React.FC<PracticeExamDomainBreakdownProps> = ({ questionPool, loading }) => {
  return (
    <>
      {loading ? (
        <>
          <Skeleton width="100%" bgColor={colours.darkGrey400} my="s2" />
          <Skeleton width="100%" bgColor={colours.darkGrey400} my="s2" />
          <Skeleton width="100%" bgColor={colours.darkGrey400} my="s2" />
        </>
      ) : (
        <DomainContainer>
          <HeadingTitle>Domain Breakdown</HeadingTitle>
          {questionPool.map((questionPool) => (
            <DomainRow key={questionPool.id}>
              <Box>{questionPool.domainName}</Box>
              <Box>{questionPool.weight}%</Box>
            </DomainRow>
          ))}
        </DomainContainer>
      )}
    </>
  );
};

const DomainContainer = styled.section`
  margin-bottom: 16px;
`;

const DomainRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  border-bottom: 1px solid ${colours.lightGrey500};
  padding-bottom: 8px;
  margin: 8px 0;
`;

export { PracticeExamDomainBreakdown };

import { useEffect, useRef, useState, FocusEvent, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { Box, Button, colours, Flexbox } from '@a-cloud-guru/rainbow-ui';
import TextArea from 'antd/lib/input/TextArea';

import { ReactComponent as PencilAltSvg } from 'static/images/svg-icons/pencil-alt.svg';
import { MAX_PATH_DESCRIPTION_LENGTH, PATH_DESCRIPTION_WARNING_LIMIT } from 'constant';
import { CharacterLimitText } from 'components/common/CharacterLimitText';
import { CustomLearningPath } from 'types';
import { UPDATE_PATH_DESCRIPTION, usePathContext } from 'context/PathContext';

const INITIAL_VALUE = 'Add path description...';

const focusTextInput = (event: TextArea | null) => event?.focus();

const Description: React.FC = () => {
  const prevDescriptionRef = useRef<string | undefined>();
  const {
    state: { path, loadingPath },
    dispatch
  } = usePathContext();
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(path?.description);

  const descriptionLength = value?.length || 0;

  useEffect(() => {
    const description = path?.description;
    if (description !== INITIAL_VALUE) {
      setValue(description);
    }
    if (prevDescriptionRef.current !== description) {
      prevDescriptionRef.current = description;
    }
  }, [loadingPath, setValue, path]);

  const isOverLimit = descriptionLength > MAX_PATH_DESCRIPTION_LENGTH;

  const onBlurHandler = (e: FocusEvent<HTMLTextAreaElement> & KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.shiftKey || isOverLimit) return;
    const description = e.target.value;
    setIsEditing(false);

    const hasChanged = prevDescriptionRef.current !== description;
    const isUserInput = description !== INITIAL_VALUE;

    if (path && isUserInput && hasChanged) {
      prevDescriptionRef.current = description;
      dispatch({
        type: UPDATE_PATH_DESCRIPTION,
        description: description,
        isSaved: false
      });
    }
  };

  return (
    <DescriptionBox>
      {isEditing ? (
        <>
          <PathDescriptionText>Path Description</PathDescriptionText>
          <DescriptionTextArea
            rows={10}
            placeholder={loadingPath ? 'Loading...' : INITIAL_VALUE}
            value={value}
            onChange={(e) => {
              const input = e.target.value;
              setValue(input);
            }}
            onBlur={onBlurHandler}
            onPressEnter={onBlurHandler}
            ref={focusTextInput}
          />

          <DescriptionBottomText>
            <Box>Help learners understand who this path is for and what the learning outcomes are.</Box>
          </DescriptionBottomText>
          <CharacterLimitText
            textEntityType="Description"
            maxLength={MAX_PATH_DESCRIPTION_LENGTH}
            length={descriptionLength}
            warningLimit={PATH_DESCRIPTION_WARNING_LIMIT}
            isOverLimit={isOverLimit}
          />
        </>
      ) : (
        <DescriptionButton type="link" block onClick={() => setIsEditing(true)}>
          <DescriptionStaticContent path={path} loadingPath={loadingPath} />
        </DescriptionButton>
      )}
    </DescriptionBox>
  );
};

interface DescriptionStaticContentProps {
  path: CustomLearningPath;
  loadingPath: boolean;
}
const DescriptionStaticContent: React.FC<DescriptionStaticContentProps> = ({ path, loadingPath }) => {
  return (
    <DescriptionStatic>
      {<TruncatedDescription description={path?.description} loadingPath={loadingPath} />}

      <PencilAltSvg role="img" height="1rem" title="Edit path description" />
    </DescriptionStatic>
  );
};

interface TruncatedDescriptionProps {
  description?: string;
  loadingPath: boolean;
}
const TruncatedDescription: React.FC<TruncatedDescriptionProps> = ({ description, loadingPath }) =>
  loadingPath ? (
    <>{'Loading...'}</>
  ) : description ? (
    <DescriptionEllipsis>{description}</DescriptionEllipsis>
  ) : (
    <>{INITIAL_VALUE}</>
  );

const DescriptionEllipsis = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PathDescriptionText = styled.div`
  color: ${colours.darkGrey800};
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 6px;
`;

const DescriptionBox = styled(Box)`
  margin-top: 10px;
`;

const DescriptionTextArea = styled(TextArea)`
  resize: none;
`;

const DescriptionBottomText = styled(Box)`
  margin-top: 10px;
  font-size: 12px;
  color: ${colours.darkGrey800};
`;

const DescriptionStatic = styled(Flexbox)`
  justify-content: flex-start;
  text-align: left;
  color: ${colours.darkGrey800};
`;

const DescriptionButton = styled(Button)`
  color: ${colours.navy700};

  &&& {
    transition: none;
    border: 0;
    padding: 0;
    color: unset;
    :hover {
      color: unset;
    }
  }
  svg {
    margin-top: 2px;
    flex-shrink: 0;
    margin-left: 5px;
  }
  :hover svg {
    filter: invert(37%) sepia(95%) saturate(602%) hue-rotate(188deg) brightness(106%) contrast(100%);
  }
`;

export { Description };
